import { Button } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import * as yup from 'yup';
import { PASSWORD } from '../../constant/regex';
import { useFormik } from 'formik';
import { toast } from '../../lib/toast';
import { updatePassword } from '../../api/user';
import { useDispatch } from 'react-redux';

const validationSchema = yup.object({
  oldPassword: yup
    .string()
    // .min(8, 'Password should have minimum 8 characters')
    // .max(32, 'Password can have maximum of 32 characters')
    // .matches(PASSWORD, 'Password should contain 1 uppercase, 1 lowercase, 1 number & 1 symbol')
    .required('Old password is required'),
  newPassword: yup
    .string()
    .min(8, 'Password should have minimum 8 characters')
    .max(32, 'Password can have maximum of 32 characters')
    .matches(PASSWORD, 'Password should contain 1 uppercase, 1 lowercase, 1 number & 1 symbol')
    // .oneOf([yup.ref('confirmPassword'), null], 'Passwords must match')
    .required('New password is required'),
  confirmPassword: yup
    .string()
    .min(8, 'Password should have minimum 8 characters')
    .max(32, 'Password can have maximum of 32 characters')
    .matches(PASSWORD, 'Password should contain 1 uppercase, 1 lowercase, 1 number & 1 symbol')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required')
});

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const ChangePassword = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState({ password: false, newPassword: false, conPassword: false });
  const [showChangePassword, setShowChangePassword] = useState(false);
  const handleCloseChangePassword = () => setShowChangePassword(false);
  const handleShowChangePassword = () => setShowChangePassword(true);

  const { values, errors, touched, status, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: handleChangePassword,
    validationSchema
  });

  async function handleChangePassword(values, { setErrors, setStatus, resetForm }) {
    try {
      setStatus('loading');
      const { success, message, errors } = await updatePassword(values);
      if (success) {
        setStatus('');
        toast({ message, type: 'success' }, dispatch);
        resetForm();
        handleCloseChangePassword();
      } else {
        setStatus('');
        errors && setErrors(errors);
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      setStatus('');
      toast({ message: 'Something went wrong', type: 'error' });
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      showChangePassword,
      handleCloseChangePassword,
      handleShowChangePassword
    }),
    [showChangePassword]
  );

  return (
    <Modal show={showChangePassword} size="md" onHide={handleCloseChangePassword} centered backdrop="static" className="primary_modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col lg={12}>
              <Form.Group controlId="formChangePassword" className="mb-3 pass-container">
                <Form.Control
                  type={show.password ? 'text' : 'password'}
                  placeholder="Enter Old Password"
                  className="primary_input"
                  name="oldPassword"
                  value={values.oldPassword}
                  onChange={handleChange}
                />
                <i
                  className={`bi ${show.password ? 'bi-eye-slash' : 'bi-eye'} change-pass-visibler blue_text`}
                  onClick={() => setShow((prev) => ({ ...prev, password: !prev.password }))}
                />
              </Form.Group>
                <p className="text-danger">{(touched.oldPassword && errors?.oldPassword) || ''}</p>
            </Col>
            <Col lg={12}>
              <Form.Group controlId="formChangePassword" className="mb-3 pass-container">
                <Form.Control
                  type={show.newPassword ? 'text' : 'password'}
                  placeholder="Enter New Password"
                  className="primary_input"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                />
                <i
                  className={`bi ${show.newPassword ? 'bi-eye-slash' : 'bi-eye'} change-pass-visibler blue_text`}
                  onClick={() => setShow((prev) => ({ ...prev, newPassword: !prev.newPassword }))}
                />
              </Form.Group>
                <p className="text-danger">{(touched.newPassword && errors?.newPassword) || ''}</p>
            </Col>
            <Col lg={12}>
              <Form.Group controlId="formChangePassword" className="mb-3 pass-container">
                <Form.Control
                  type={show.conPassword ? 'text' : 'password'}
                  placeholder="Re-enter New Password"
                  className="primary_input"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                />
                <i
                  className={`bi ${show.conPassword ? 'bi-eye-slash' : 'bi-eye'} change-pass-visibler blue_text`}
                  onClick={() => setShow((prev) => ({ ...prev, conPassword: !prev.conPassword }))}
                />
              </Form.Group>
                <p className="text-danger">{(touched.confirmPassword && errors?.confirmPassword) || ''}</p>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="primary_btn" variant="contained" onClick={handleSubmit} disabled={status === 'loading'}>
          {status === 'loading' ? <Spinner size="sm" /> : 'Submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default ChangePassword;
