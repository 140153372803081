function findPercent(value, total) {
  if (!value || !total) return 0;
  value = parseFloat(value);
  total = parseFloat(total);
  if (isNaN(value) || isNaN(total)) return 0;
  let percent = (value / total) * 100
  if (percent < 0.5 && percent > 0) return 0.5;
  else return percent
}

export default findPercent;
