import { Button } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from '../../lib/toast';
import { putDisable2FA } from '../../api/user';
import { getUserProfile } from '../../redux/slices/account';
import { NUMBER } from '../../constant/regex';

const Disable2Fa = forwardRef((props, ref) => {
  const [otpCode, setOtpCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ otpCode: '' });

  const dispatch = useDispatch();

  const [showDisableTFA, setShowDisableTFA] = useState(false);
  const handleCloseDisableTFA = () => setShowDisableTFA(false);
  const handleShowDisableTFA = () => setShowDisableTFA(true);

  useImperativeHandle(
    ref,
    () => ({
      showDisableTFA,
      handleCloseDisableTFA,
      handleShowDisableTFA
    }),
    [showDisableTFA]
  );

  const handleDisable2FA = async () => {
    try {
      setLoading(true);
      const { success, message, errors } = await putDisable2FA({ otpCode });
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        setOtpCode('');
        setLoading(false);
        dispatch(getUserProfile());
        handleCloseDisableTFA();
      } else {
        setOtpCode('');
        setLoading(false);
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
      }
    } catch (error) {
      setOtpCode('');
      setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  };

  const handleChange = (e) => {
    if (!NUMBER.test(e.target.value) && e.target.value !== '') {
      setErrors({ otpCode: 'Invalid OTP' });
    } else if (e.target.value.length > 6) {
      setErrors({ otpCode: 'Invalid OTP' });
    } else {
      setErrors({});
    }
    setOtpCode(e.target.value);
  };

  return (
    <Modal show={showDisableTFA} size="md" onHide={handleCloseDisableTFA} centered backdrop="static" className="primary_modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col lg={12}>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter OTP from authenticator app"
                  className="primary_input"
                  value={otpCode}
                  onChange={handleChange}
                />
              </InputGroup>
              <p className="text-danger">{errors?.otpCode || ''}</p>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="primary_btn" variant="contained" onClick={handleDisable2FA} disabled={loading}>
          {loading ? <Spinner size="sm" /> : 'Disable 2FA'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default Disable2Fa;
