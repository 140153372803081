import axios, { handleResponse } from './Axios';

export const getAllFaqs = async () => {
  try {
    const response = await axios({
      url: '/v1/user/faq',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getCmsInfo = async (type) => {
  try {
    const response = await axios({
      url: `/v1/user/cms/${type}`,
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
