import React from 'react';
import Card from './Card';
import Bitcoin from './bitcoin';
import Ethereum from './ethereum';
import USDT from './usdt';
import USDC from './usdc';
import { useSelector } from 'react-redux';
import isEmpty from 'is-empty';

const Container = () => {
  const wallet = useSelector((state) => state.wallet);
  const currency = useSelector((state) => state.currency);
  const account = useSelector((state) => state.account);

  if (!wallet || isEmpty(wallet?.assets) || !currency || isEmpty(currency)) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="wallet_panel d-flex justify-content-center align-items-center">Currently there are no assets in your</div>
        </div>
      </div>
    );
  }
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="wallet_panel">
          {wallet?.assets.map((asset) => {
            if (
              asset.currencySymbol === 'BTC' ||
              asset.currencySymbol === 'TRX' ||
              (asset.currencySymbol === 'TRX' && asset.tokenType === 'TRC20')
            ) {
              return null;
            }
            let chartComponent =
              asset.currencySymbol === 'BTC'
                ? Bitcoin
                : asset.currencySymbol === 'ETH'
                  ? Ethereum
                  : asset.currencySymbol === 'USDT'
                    ? USDT
                    : USDC;
            let assetCurrency = currency.find((item) => item.symbol === asset.currencySymbol);
            return (
              <Card
                Component={chartComponent}
                currencyData={currency}
                asset={asset}
                currency={assetCurrency}
                totalBound={wallet.totalInbound}
                account={account}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Container);
