import axios, { handleResponse } from './Axios';

export const refreshToken = async () => {
  try {
    let response = await axios({
      url: '/auth/user/refresh-token',
      method: 'GET',
      withCredentials: true
    });
    return response?.data;
  } catch (error) {
    // Trigger logout
    console.log(error);
    if (error.response.status === 401) {
      return { logout: true };
    }
    return { logout: false };
  }
};

export const postSignup = async (data) => {
  try {
    let response = await axios({
      url: '/auth/user/signup',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getVerified = async (token) => {
  try {
    let response = await axios({
      url: `/auth/user/verify/${token}`,
      method: 'POST',
      data: {
        requestTime: Date.now()
      }
    });
    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const postSignin = async (data) => {
  try {
    let response = await axios({
      url: `/auth/user/signin`,
      method: 'POST',
      data,
      withCredentials: true
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const clearLastSession = async (sessionId) => {
  try {
    await axios({
      url: `/auth/user/clean-last-session`,
      method: 'POST',
      data: {
        sessionId
      }
    });
  } catch (error) {
    console.log(error);
    return handleResponse(error);
  }
};

export const apiLogout = async (sessionId) => {
  try {
    let response = await axios({
      url: `/auth/user/signout/${sessionId}`,
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
