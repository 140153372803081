import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Button, capitalize } from '@mui/material';
import DataTable, { createTheme } from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-bootstrap';
import toast from '../redux/slices/toast.js';
import { useDispatch } from 'react-redux';
import { getPaymentTransactions, getQuoteTransactions, getTransferTransactions, getWithdrawTransactions } from '../api/payment.js';
import moment from 'moment';
import ExpandedComponent from '../components/Transaction/ExpandedComponent.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

// Recently Swap Trans Table
const columns = [
  {
    name: 'Quote ID',
    selector: (row) => <a href={`/quote-status/${row.quotationId}`}>{row.quotationId}</a>,
    sortable: true,
    sortField: 'intentId'
  },
  {
    name: 'Client',
    selector: (row) => row.client,
    sortable: true,
    sortField: 'client'
  },
  {
    name: 'Operator',
    selector: (row) => row.operatorName,
    sortable: true,
    sortField: 'operatorName'
  },
  {
    name: 'Date',
    selector: (row) => (row.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : '-'),
    sortable: true,
    sortField: 'createdAt'
  },
  {
    name: 'Amount',
    selector: (row) => `$${row.amount}`,
    sortable: true,
    sortField: 'amount'
  },
  {
    name: 'Type',
    selector: (row) => row.symbol,
    sortable: true,
    sortField: 'symbol'
  },
  {
    name: 'Status',
    selector: (row) => (row.status ? capitalize(row.status) : '-'),
    sortable: true,
    sortField: 'status'
  }
];

const aircraftcolumns = [
  {
    name: 'Payment ID',
    selector: (row) => <a href={`/payment-status/${row.intentId}`}>{row.intentId}</a>,
    sortable: true,
    sortField: 'intentId'
  },
  {
    name: 'Aircraft',
    selector: (row) => row.aircraftId?.name,
    sortable: false,
    sortField: '_id'
  },
  {
    name: 'ID',
    selector: (row) => row.aircraftId?.id,
    sortable: false,
    sortField: '_id'
  },
  {
    name: 'Payment Date',
    selector: (row) => (row.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : '-'),
    sortable: true,
    sortField: 'createdAt'
  },
  {
    name: 'Amount',
    selector: (row) => `$${row.amount}`,
    sortable: true,
    sortField: 'amount'
  },
  {
    name: 'Type',
    selector: (row) => row.symbol,
    sortable: true,
    sortField: 'symbol'
  },
  {
    name: 'Status',
    selector: (row) => (row.status ? capitalize(row.status) : '-'),
    sortable: true,
    sortField: 'status'
  }
];

const wihdrawcolumns = [
  {
    name: 'Created On',
    selector: (row) => (row.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : '-'),
    sortable: true,
    sortField: 'createdAt'
  },
  {
    name: 'Amount',
    selector: (row) => `${row.amount}`,
    sortable: true,
    sortField: 'amount'
  },
  {
    name: 'Fee (%)',
    selector: (row) => `${row.fee}%`,
    sortable: true,
    sortField: 'fee'
  },
  {
    name: 'Receivable Amount (USD)',
    selector: (row) => `$${row.conversionReceiver}`,
    sortable: true,
    sortField: 'conversionReceiver'
  },
  {
    name: 'Bank Account',
    selector: (row) => `${row.bankDetails?.accountNo}`,
    sortable: true,
    sortField: 'bankDetails.accountNo',
    width: '350px'
  },
  {
    name: 'Symbol',
    selector: (row) => row.currencySymbol,
    sortable: true,
    sortField: 'currencySymbol'
  },
  {
    name: 'Withdrawal Currency',
    selector: (row) => `${row.bankDetails?.currency}`,
    sortable: true,
    sortField: 'symbol'
  },
  {
    name: 'Status',
    selector: (row) => (row.status ? capitalize(row.status) : '-'),
    sortable: true,
    sortField: 'status'
  }
];

const transfercolumns = [
  {
    name: 'Transfer Date',
    selector: (row) => (row.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : '-'),
    sortable: true,
    sortField: 'createdAt'
  },
  {
    name: 'From Currency',
    selector: (row) => row.fromCurrencyId?.name,
    sortable: false,
    sortField: '_id'
  },
  {
    name: 'From Symbol',
    selector: (row) => row.fromCurrencySymbol,
    sortable: true,
    sortField: 'fromCurrencySymbol'
  },
  {
    name: 'From Amount',
    selector: (row) => `${row.fromAmount}`,
    sortable: true,
    sortField: 'fromAmount'
  },
  {
    name: 'To Currency',
    selector: (row) => row.toCurrencyId?.name,
    sortable: false,
    sortField: '_id'
  },
  {
    name: 'To Symbol',
    selector: (row) => row.toCurrencySymbol,
    sortable: true,
    sortField: 'toCurrencySymbol'
  },
  {
    name: 'To Amount',
    selector: (row) => `${row.toAmount}`,
    sortable: true,
    sortField: 'toAmount'
  }
];

export default function Transactions(props) {
  const dispatch = useDispatch();
  const [query, setQuery] = useSearchParams();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({ page: 1, limit: 10, sort: {}, dates: { type: '', date: '' }, search: query.get('search') || '' });
  const [tabid, settabid] = useState('1');

  const monthref = useRef(null);
  const yearref = useRef(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchTransactions = useCallback(
    async (filter, tab) => {
      try {
        setData(() => []);
        setCount(0);

        let getTransactionData =
          tab === '1'
            ? getQuoteTransactions
            : tab === '2'
              ? getPaymentTransactions
              : tab === '3'
                ? getWithdrawTransactions
                : getTransferTransactions;

        const { success, message, result } = await getTransactionData(filter);
        if (success) {
          setData(result.data);
          setCount(result.count);
        } else {
          message && toast({ message, type: 'error' }, dispatch);
        }
      } catch (error) {
        toast({ message: 'Some error occurred', type: 'error' }, dispatch);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (filter.limit && filter.page) {
      fetchTransactions(filter, tabid);
    }
  }, [fetchTransactions, filter, tabid]);

  // For Datatable Starts
  createTheme(
    'dark',
    {
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(0,0,0,.12)'
      },
      background: {
        default: '#1A1E27'
      },
      context: {
        background: '#1A1E27',
        text: '#DA1818'
      },
      divider: {
        default: 'rgb(22 21 21)'
      },
      button: {
        default: '#FFFFFF',
        focus: 'rgba(255, 255, 255, .54)',
        hover: 'rgba(255, 255, 255, .12)',
        disabled: 'rgba(255, 255, 255, .18)'
      },
      selected: {
        default: 'rgba(0, 0, 0, .7)',
        text: '#FFFFFF'
      },
      highlightOnHover: {
        default: 'rgba(0, 0, 0, .7)',
        text: '#FFFFFF'
      },
      striped: {
        default: 'rgba(0, 0, 0, .87)',
        text: '#FFFFFF'
      }
    },
    'dark'
  );
  // For Datatable Ends

  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ page: page, limit: newPerPage } };
    setFilter(filterData);
  };
  const handlePageChange = (page) => {
    let filterData = { ...filter, ...{ page: page } };
    setFilter(filterData);
  };
  const handleSort = (column, direction) => {
    let filterData = { ...filter, sort: { [column.sortField]: direction === 'desc' ? -1 : 1 } };
    setFilter(filterData);
  };
  const handleUpdateDate = (type, date = '') => {
    if (date > new Date()) {
      return toast({ message: 'Cannot filter to future date', type: 'error' }, dispatch);
    }
    if (type === 'month') {
      setFilter((prev) => ({ ...prev, dates: { type, date } }));
      monthref.current.setOpen(false);
    } else if (type === 'year') {
      setFilter((prev) => ({ ...prev, dates: { type, date } }));
      monthref.current.setOpen(false);
    } else {
      setFilter((prev) => ({ ...prev, dates: { type, date } }));
      handleClose();
    }
  };
  const handleSearchChange = (e) => {
    setQuery({ search: e.target.value });
    setFilter((prev) => ({ ...prev, search: e.target.value }));
  };

  const tabclick = (e) => {
    settabid(e);
  };

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page bg_top">
              <div className="container-fluid">
                <div className="row py-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <div className="dashtitle_flex flex-wrap gap-2 mb-4">
                        <div className="d-flex gap-2 flex-wrap tabTitle">
                          <h2 className={`dash_inner_title px-1 tabheads ${tabid === '1' ? 'active' : ''}`} onClick={() => tabclick('1')}>
                            {' '}
                            Quote Transactions
                          </h2>
                          <h2 className={`dash_inner_title px-1 tabheads ${tabid === '2' ? 'active' : ''}`} onClick={() => tabclick('2')}>
                            {' '}
                            Aircraft Sale Transactions
                          </h2>
                          <h2 className={`dash_inner_title px-1 tabheads ${tabid === '3' ? 'active' : ''}`} onClick={() => tabclick('3')}>
                            {' '}
                            Withdrawals
                          </h2>
                          <h2 className={`dash_inner_title px-1 tabheads ${tabid === '4' ? 'active' : ''}`} onClick={() => tabclick('4')}>
                            {' '}
                            Transfers
                          </h2>
                        </div>
                        <div className="table_filter_options">
                          <div className="input-group search_input_div">
                            <span className="input-group-text" id="basic-addon1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                                <path
                                  d="M10.564 15.8877C11.9502 15.8877 13.2285 15.4395 14.2744 14.6924L18.209 18.627C18.3916 18.8096 18.6323 18.9009 18.8896 18.9009C19.4292 18.9009 19.811 18.4858 19.811 17.9546C19.811 17.7056 19.728 17.4648 19.5454 17.2905L15.6357 13.3726C16.4575 12.2935 16.9473 10.957 16.9473 9.50439C16.9473 5.99316 14.0752 3.12109 10.564 3.12109C7.06104 3.12109 4.18066 5.98486 4.18066 9.50439C4.18066 13.0156 7.05273 15.8877 10.564 15.8877ZM10.564 14.5098C7.82471 14.5098 5.55859 12.2437 5.55859 9.50439C5.55859 6.76514 7.82471 4.49902 10.564 4.49902C13.3032 4.49902 15.5693 6.76514 15.5693 9.50439C15.5693 12.2437 13.3032 14.5098 10.564 14.5098Z"
                                  fill="#3C3C43"
                                  fill-opacity="0.6"
                                />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search By All"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              onChange={handleSearchChange}
                              value={filter.search}
                            />
                          </div>
                          <Button className={`filter_btn ${filter.dates.type === 'date' ? 'txn-custom-active' : ''}`} onClick={handleShow}>
                            <img src={require('../assets/images/filter_icon.png')} alt="Filter" />
                          </Button>
                          <div class="btn-group btn-group_custom" role="group" aria-label="Basic example">
                            <button
                              type="button"
                              class={`grp_btn ${filter.dates.type === 'week' ? 'active' : ''}`}
                              onClick={() => handleUpdateDate('week', '')}
                            >
                              Week
                            </button>
                            <button
                              type="button"
                              class={`grp_btn ${filter.dates.type === 'month' ? 'active' : ''}`}
                              onClick={() => monthref.current.toggleCalendar() /*handleUpdateDate('month', '')*/}
                            >
                              Month
                            </button>
                            <button
                              type="button"
                              class={`grp_btn ${filter.dates.type === 'year' ? 'active' : ''}`}
                              onClick={() => yearref.current.toggleCalendar() /*handleUpdateDate('year', '')*/}
                            >
                              Year
                            </button>
                            <DatePicker
                              showMonthYearPicker
                              className="hideit-from"
                              ref={monthref}
                              maxDate={new Date()}
                              maxTime={new Date()}
                              onChange={(d) => handleUpdateDate('month', d)}
                            />
                            <DatePicker
                              showYearPicker
                              className="hideit-from"
                              ref={yearref}
                              maxDate={new Date()}
                              maxTime={new Date()}
                              onChange={(d) => handleUpdateDate('year', d)}
                            />
                          </div>
                        </div>
                      </div>
                      {tabid === '1' && (
                        <div className="mt-3">
                          <DataTable
                            columns={columns}
                            data={data}
                            responsive
                            theme="dark"
                            pagination
                            paginationTotalRows={count}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationServer
                            sortServer
                            onSort={handleSort}
                            expandableRows
                            expandOnRowClicked
                            expandableRowsComponent={ExpandedComponent}
                            expandableRowsHideExpander
                          />
                        </div>
                      )}
                      {tabid === '2' && (
                        <div className="mt-3">
                          <DataTable
                            columns={aircraftcolumns}
                            data={data}
                            responsive
                            theme="dark"
                            pagination
                            paginationTotalRows={count}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationServer
                            sortServer
                            onSort={handleSort}
                          />
                        </div>
                      )}
                      {tabid === '3' && (
                        <div className="mt-3">
                          <DataTable
                            columns={wihdrawcolumns}
                            data={data}
                            responsive
                            theme="dark"
                            pagination
                            paginationTotalRows={count}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationServer
                            sortServer
                            onSort={handleSort}
                          />
                        </div>
                      )}
                      {tabid === '4' && (
                        <div className="mt-3">
                          <DataTable
                            columns={transfercolumns}
                            data={data}
                            responsive
                            theme="dark"
                            pagination
                            paginationTotalRows={count}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationServer
                            sortServer
                            onSort={handleSort}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Withdraw Modal */}
      <Modal show={show} size="sm" onHide={handleClose} centered backdrop="static" className="primary_modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <DatePicker selected={startDate} onChange={(e) => handleUpdateDate('date', e)} inline />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button className="primary_btn" variant="contained" onClick={handleClose}>
            Apply Filter
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
