import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { retrieveUserWallet } from '../../api/user';

const initialState = {
  userId: '',
  userCode: '',
  assets: [],
  totalInbound: 0,
  totalOutbound: 0
};

export const getUserWallet = createAsyncThunk('wallet', async () => {
  const { success, result } = await retrieveUserWallet();
  if (success) return result;
  else return initialState;
});

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, action) => {
      state = action.payload;
      return state;
    },
    resetWallet: (state, action) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: (build) => {
    build.addCase(getUserWallet.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
  }
});

export const { resetWallet, setWallet } = walletSlice.actions;
export default walletSlice.reducer;
