import { Fragment, createContext, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import config from '../config';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const SocketContext = createContext({ socket: null });

const SocketContextWrapper = ({ children }) => {
  const socket = io(config.API_HOST);

  const account = useSelector((state) => state.account);
  const runOnce = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (runOnce.current) {
      runOnce.current = false;
      socket.on('connect', () => {
        socket.emit('CREATEROOM', account._id);
      });
      socket.on('FORCE_SIGNOUT', (data) => {
        if (data.isSignout) {
          navigate('/logout');
        }
      });
    }
  }, [account._id, socket, navigate]);

  if (socket) {
    return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default SocketContextWrapper;
