import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {count:0,NoticeData:[]},
  reducers: {
    setUnreadNotification: (state, action) => {
      state = action.payload;
      return state;
    },
    clearNotification: (state, action) => {
      state = { count: 0, NoticeData: [] };
      return state;
    }
  }
});

export const { setUnreadNotification, clearNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
