import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { getUserProfile } from '../redux/slices/account';
import { fetchUserProfile } from '../api/user';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserWallet } from '../redux/slices/wallet';
import { getCurrencies } from '../redux/slices/currency';
import { FetchunReadNotice, getUnreadNotification } from '../api/notification';
import { SocketContext } from '../context/SocketContext';
import { modifySignal } from '../lib/signal';

const RouteHelper = () => {
  const dispatch = useDispatch();
  const socketContext = useContext(SocketContext);
  const { auth } = useSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const nonAuthPoints = useCallback(
    () => [
      'login',
      'register',
      'verify',
      'forgot-password',
      'otp',
      'reset-password',
      'payment-status',
      'quote-status',
      'faq',
      'legal',
      'terms',
      'privacy',
      '/'
    ],
    []
  )();
  useEffect(() => {
    auth.isAuth && dispatch(getUserWallet());
    auth.isAuth && fetchUserProfile(dispatch);
    dispatch(getCurrencies());
    auth.isAuth && getUnreadNotification(dispatch, false)
  }, [dispatch, auth.isAuth]);
  useEffect(() => {
    if (!auth.isAuth && !nonAuthPoints.find((item) => location.pathname.includes(item))) {
      navigate('/login');
    } else {
      socketContext.socket.on('notice', (result) => {
        FetchunReadNotice(dispatch, result);
        modifySignal(result.count);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuth, location.pathname, navigate, nonAuthPoints]);
  useEffect(() => {
    socketContext.socket.on('REFRESH_WALLET', (data) => {
      if (data.REFRESH) {
        dispatch(getUserWallet())
      }
    });
  }, [dispatch, socketContext.socket]);

  return <React.Fragment />;
};

export default RouteHelper;
