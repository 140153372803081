import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import { Button } from '@mui/material';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AuditReport(props) {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row pt-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <div class="dashtitle_flex mb-3 justify-content-center">
                        <h2 class="dash_inner_title">Audit Report</h2>
                      </div>
                      <ul class="nav nav-pills nav-fill audit_nav">
                        <li class="nav-item">
                          <Link to="div-a" spy={true} smooth={true} offset={-160} duration={250}>
                            A
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-b" spy={true} smooth={true} offset={-160} duration={250}>
                            B
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-c" spy={true} smooth={true} offset={-160} duration={250}>
                            C
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-d" spy={true} smooth={true} offset={-160} duration={250}>
                            D
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-e" spy={true} smooth={true} offset={-160} duration={250}>
                            E
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-f" spy={true} smooth={true} offset={-160} duration={250}>
                            F
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-g" spy={true} smooth={true} offset={-160} duration={250}>
                            G
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-h" spy={true} smooth={true} offset={-160} duration={250}>
                            H
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-i" spy={true} smooth={true} offset={-160} duration={250}>
                            I
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-j" spy={true} smooth={true} offset={-160} duration={250}>
                            J
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-k" spy={true} smooth={true} offset={-160} duration={250}>
                            K
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-l" spy={true} smooth={true} offset={-160} duration={250}>
                            L
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-m" spy={true} smooth={true} offset={-160} duration={250}>
                            M
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-n" spy={true} smooth={true} offset={-160} duration={250}>
                            N
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-o" spy={true} smooth={true} offset={-160} duration={250}>
                            O
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-p" spy={true} smooth={true} offset={-160} duration={250}>
                            P
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-q" spy={true} smooth={true} offset={-160} duration={250}>
                            Q
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-r" spy={true} smooth={true} offset={-160} duration={250}>
                            R
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-s" spy={true} smooth={true} offset={-160} duration={250}>
                            S
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-t" spy={true} smooth={true} offset={-160} duration={250}>
                            T
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-u" spy={true} smooth={true} offset={-160} duration={250}>
                            U
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-v" spy={true} smooth={true} offset={-160} duration={250}>
                            V
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-w" spy={true} smooth={true} offset={-160} duration={250}>
                            W
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-x" spy={true} smooth={true} offset={-160} duration={250}>
                            X
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-y" spy={true} smooth={true} offset={-160} duration={250}>
                            Y
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link to="div-z" spy={true} smooth={true} offset={-160} duration={250}>
                            Z
                          </Link>
                        </li>
                      </ul>

                      <div name="div-a" className="audit_div_wrap">
                        <h2>A</h2>
                        <div className="audit_inner_div">
                          <NavLink to="/audit-report-detail">Aaron Richard</NavLink>
                          <NavLink to="/">Adrien John</NavLink>
                          <NavLink to="/">Aelf Mitchel</NavLink>
                          <NavLink to="/">Agles Masha</NavLink>
                          <NavLink to="/">Alaya Mukuma</NavLink>
                          <NavLink to="/">Ashcherik K</NavLink>
                          <NavLink to="/">Attal Palaya</NavLink>
                          <NavLink to="/">Avlon Tech</NavLink>
                          <NavLink to="/">Aziz Abdulla</NavLink>
                        </div>
                      </div>
                      <div name="div-b" className="audit_div_wrap">
                        <h2>B</h2>
                        <div className="audit_inner_div">
                          <NavLink to="/">Balu Mahendra</NavLink>
                          <NavLink to="/">Ben Jario</NavLink>
                          <NavLink to="/">Bishop Beach</NavLink>
                          <NavLink to="/">Agles Masha</NavLink>
                          <NavLink to="/">Alaya Mukuma</NavLink>
                          <NavLink to="/">Ashcherik K</NavLink>
                          <NavLink to="/">Attal Palaya</NavLink>
                          <NavLink to="/">Avlon Tech</NavLink>
                          <NavLink to="/">Aziz Abdulla</NavLink>
                        </div>
                      </div>
                      <div name="div-c" className="audit_div_wrap">
                        <h2>C</h2>
                        <div className="audit_inner_div">
                          <NavLink to="/">Carl Bridghes</NavLink>
                          <NavLink to="/">Calvin James</NavLink>
                          <NavLink to="/">Cavana T</NavLink>
                          <NavLink to="/">Celia Singh</NavLink>
                          <NavLink to="/">Charles Martha</NavLink>
                          <NavLink to="/">Charlie Clark</NavLink>
                          <NavLink to="/">Claire Thomas</NavLink>
                          <NavLink to="/">Clement Rover</NavLink>
                          <NavLink to="/">Colt Xavier</NavLink>
                          <NavLink to="/">Colive P</NavLink>
                        </div>
                      </div>
                      <div name="div-d" className="audit_div_wrap">
                        <h2>D</h2>
                        <div className="audit_inner_div">
                          <NavLink to="/">Dennis Yanke</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                          <NavLink to="/">Dolphy Matthew</NavLink>
                        </div>
                      </div>
                      <div name="div-e" className="audit_div_wrap">
                        <h2>E</h2>
                        <div className="audit_inner_div">
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                        </div>
                      </div>
                      <div name="div-f" className="audit_div_wrap">
                        <h2>F</h2>
                        <div className="audit_inner_div">
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                          <NavLink to="/">Eagle Vaichunitue</NavLink>
                        </div>
                      </div>
                      <div name="div-g" className="audit_div_wrap">
                        <h2>G</h2>
                        <div className="audit_inner_div">
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                          <NavLink to="/">Gunther Vaichunitue</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
