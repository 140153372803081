import React, { useCallback, useEffect, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCmsInfo } from '../api/cms.js';
import { toast } from '../lib/toast.js';
import NavbarAuth from '../components/NavbarAuth.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Legal(props) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [cmsData, setCmsData] = useState('');

  const fetchCmsData = useCallback(async () => {
    try {
      const { success, result, message } = await getCmsInfo('legal');
      if (success) {
        setCmsData(result);
      } else {
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCmsData();
  }, [fetchCmsData]);
  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          {auth.isAuth  ? <Navbar /> : <NavbarAuth />}
          <div className={auth.isAuth ? 'page_wrapper' : 'page_wrapper_nonauth'}>
            {auth.isAuth && <Sidebar />}
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row pt-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <ul class="nav nav-pills nav-fill cms_nav">
                        <li class="nav-item">
                          <Link class="nav-link active" to="/legal">
                            Legal
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link class="nav-link" to="/terms">
                            Terms & Conditions
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link class="nav-link" to="/privacy">
                            Privacy Policy
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link class="nav-link" to="/faq">
                            FAQs
                          </Link>
                        </li>
                      </ul>
                      <div className="cms_content" dangerouslySetInnerHTML={{ __html: cmsData }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
