import React, { Fragment, useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Button } from '@mui/material';
import { toast } from '../lib/toast';

// Chart Components
import BookingChart from '../components/aircraft-sales/BookingChart.js';
import { useDispatch } from 'react-redux';
import { deleteAircraft, getAircrafts } from '../api/aircraft.js';
import isEmpty from 'is-empty';
import moment from 'moment';
import RequestPayment from '../components/aircraft-sales/RequestPayment.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AircraftSales(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [aircrafts, setAircrafts] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ limit: 6, page: 1 });
  const [isContinue, setContinue] = useState(true);
  const [list, setList] = useState([]);

  const navigateToAddAircraft = () => {
    navigate('/add-aircraft');
  };

  const handleParamsChange = () => {
    if (loading) return;
    else {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
      setContinue(() => {
        if (count > (params.page + 1) * params.limit) {
          return true;
        } else {
          return false;
        }
      });
    }
  };

  const fetchAircrafts = useCallback(async (isReplace = false) => {
    try {
      setLoading(true);
      const {
        success,
        message,
        result: { data, count }
      } = await getAircrafts(params);
      if (success) {
        setAircrafts((prev) => isReplace ? [...data]:[...prev, ...data]);
        setCount(count);
        setLoading(false);
      } else {
        toast({ message, type: 'error' }, dispatch);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [dispatch, params]);

  const removeAircraft = async (aircraft) => {
    try {
      setList((prev) => [...prev, aircraft]);
      const { success, message } = await deleteAircraft({ aircraftId: aircraft });
      if (success) await fetchAircrafts(true);
      toast({ message, type: success ? 'success' : 'error' }, dispatch);
      setList((prev) => prev.filter((i) => i !== aircraft));
    } catch (error) {
      setList((prev) => prev.filter((i) => i !== aircraft));
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  };

  useEffect(() => {
    fetchAircrafts();
  }, [fetchAircrafts]);

  // Request Payment Area
  const [show, setShow] = useState(false);
  const [paymentCraft, setPaymentCraft] = useState('');

  const handleRequestPayment = (aircraft) => {
    setPaymentCraft(aircraft);
    setShow(true);
  };

  const handleCloseRequestPayment = () => {
    setPaymentCraft('');
    setShow(false);
  };

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row py-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <div className="dashtitle_flex mb-3 justify-content-center">
                        <h2 className="dash_inner_title">Aircrafts in Sale</h2>
                      </div>
                      <div className="row dashbox_fullheight_row">
                        {loading ? (
                          <div className="col-md-12 d-flex justify-content-center align-items-center py-4">
                            <Spinner />
                          </div>
                        ) : (
                          <Fragment>
                            {isEmpty(aircrafts) ? (
                              <div className="col-md-12">No Aircrafts found, Add an aircraft to continue</div>
                            ) : (
                              <Fragment>
                                <InfiniteScroll
                                  dataLength={count}
                                  loader={<Spinner size="sm" />}
                                  next={handleParamsChange}
                                  endMessage={
                                    <div className="col-md-12 d-flex justify-content-center align-items-center py-4">
                                      No more data to load
                                    </div>
                                  }
                                  className="row dashbox_fullheight_row"
                                  hasMore={isContinue}
                                >
                                  {aircrafts.map((craft, i) => {
                                    return (
                                      <div className="col-lg-6 col-md-12" key={craft._id}>
                                        <div className="aircraft_box">
                                          <div className="aircraft_box_head">
                                            <h4>
                                              Transaction ID :{' '}
                                              <span>{craft.status === 'saved' ? 'Not opened for sale' : craft.transactionId}</span>
                                            </h4>
                                          </div>
                                          <div className="aircraft_box_body">
                                            <div className="aircraft_box_body_left">
                                              <img src={require('../assets/images/aircraft.png')} alt="Aircraft" className="img-fluid" />
                                              <h3>
                                                {craft.status === 'saved' ? (
                                                  <>{craft?.name ? craft.name : 'Not opened for sale'}</>
                                                ) : (
                                                  craft.name
                                                )}
                                              </h3>
                                              <h5>
                                                AIRCRAFT ID: <span>{craft.status === 'saved' ? '----------' : craft.id}</span>
                                              </h5>
                                              <h5>
                                                Buyer : <span>{craft.status === 'saved' ? '----------' : craft.buyer}</span>
                                              </h5>
                                            </div>
                                            <div className="aircraft_box_body_right">
                                              <BookingChart process={craft.process ? parseFloat(craft.process).toFixed(3): 0} />
                                              <h5>
                                                Created on <span>{moment(craft.createdAt).format('DD - MMM - YYYY')}</span>
                                              </h5>
                                            </div>
                                          </div>
                                          {craft.status === 'saved' ? (
                                            <div className="aircraft_box_footer">
                                              <Button className="border_btn" onClick={() => navigate(`/update-aircraft/${craft._id}`)}>
                                                Edit Aircraft
                                              </Button>
                                              <Button className="border_btn border_red red_text" onClick={() => removeAircraft(craft._id)}>
                                                {list && list.find((i) => i === craft._id) ? <Spinner size="sm" /> : 'Remove Aircraft'}
                                              </Button>
                                            </div>
                                          ) : (
                                            <div className="aircraft_box_footer">
                                              {craft.status === 'open' && <Button className="border_btn" onClick={() => handleRequestPayment(craft)} disabled={craft.status === 'closed'}>
                                                Request Payment
                                              </Button>}
                                              <Button className="border_btn" onClick={() => navigate(`/aircraft-details/${craft._id}`)}>
                                                View Details
                                              </Button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </InfiniteScroll>
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </div>
                      <div className="account_details_box text-center cur_pointer" onClick={navigateToAddAircraft}>
                        <span>Add Aircraft</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {paymentCraft && <RequestPayment aircraft={paymentCraft} show={show} onHide={handleCloseRequestPayment} />}
      </div>
    </>
  );
}
