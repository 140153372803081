import isEmpty from 'is-empty';

export default function totalAsset(assets, currency) {
  if (isEmpty(assets) || isEmpty(currency)) {
    return 0;
  }

  let totalValue = 0;
  for (let asset of assets) {
    let coresspondingCurrency = currency.find((item) => item.symbol === asset.currencySymbol);
    totalValue += coresspondingCurrency.price * asset.balance;
  }

  return isNaN(totalValue) ? 0 : totalValue;
}
