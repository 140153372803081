import { Button } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from '../../../lib/toast';
import { useDispatch } from 'react-redux';
import { postWithdrawCrypto } from '../../../api/wallet';
import isEmpty from 'is-empty';

const initialValues = {
  amount: '',
  currencyId: '',
  address: '',
  network: ''
};

const WithdrawCryptoModal = ({ show, onHide, currencyData, currency, account }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { values, touched, errors, handleSubmit, handleChange, setErrors, setValues, resetForm } = useFormik({
    initialValues: { ...initialValues, currencyId: currency._id },
    onSubmit: handleWithdraw
  });

  useEffect(() => {
    let network =
      currency.symbol === 'ETH' || currency.tokenType === 'ERC20'
        ? 'Ethereum'
        : currency.symbol === 'TRX' || currency.tokenType === 'TRC20'
          ? 'Tron'
          : 'Bitcoin';
    setValues((prev) => ({ ...prev, network }));
  }, [setValues, currency, show]);

  async function handleWithdraw(values, { resetForm }) {
    try {
      setLoading(true);
      const { success, message, errors } = await postWithdrawCrypto(values);
      setLoading(false);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        resetForm();
        handleOnClose();
      } else {
        !isEmpty(errors) && setErrors(errors);
        !isEmpty(message) && toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }

  const handleOnClose = () => {
    resetForm();
    onHide();
  };

  return (
    <Modal show={show} onHide={handleOnClose} size="lg" centered backdrop="static" className="primary_modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={6}>
            <Form.Group controlId="formGridName" className="mb-3">
              <Form.Control
                type="text"
                value={currency.symbol}
                placeholder="Currency"
                readOnly
                disabled
                className="primary_input"
                name="currency"
              />
              <p className="text-danger">{(touched.currencyId && errors.currencyId) || ''}</p>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="formGridName" className="mb-3">
              <Form.Control
                type="text"
                value={values.network}
                placeholder="Network"
                readOnly
                disabled
                className="primary_input"
                name="network"
              />
              <p className="text-danger">{(touched.network && errors.network) || ''}</p>
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Group controlId="formGridName" className="mb-3">
              <Form.Control
                type="number"
                value={values.amount}
                placeholder="Amount"
                className="primary_input"
                name="amount"
                onChange={handleChange}
              />
              <p className="text-danger">{(touched.amount && errors.amount) || ''}</p>
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Group controlId="formGridName" className="mb-3">
              <Form.Control
                type="text"
                value={values.address}
                placeholder="Address"
                className="primary_input"
                name="address"
                onChange={handleChange}
              />
              <p className="text-danger">{(touched.address && errors.address) || ''}</p>
            </Form.Group>
          </Col>
          <Col xs={12} className="flex flex-col items-stretch justify-center">
            <Button type="submit" className="primary_btn" variant="contained" disabled={loading} onClick={handleSubmit}>
              {loading ? <Spinner size="sm" /> : 'Withdraw'}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawCryptoModal;
