import { Alert, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnack } from '../redux/slices/toast';

const Toast = () => {
  const snack = useSelector((state) => state.toast);
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      setTimeout(() => {
        dispatch(closeSnack());
      }, 500);
      return;
    }

    dispatch(closeSnack());
  };
  return (
    <Snackbar
      open={snack.isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      action={null}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Alert severity={snack.type || 'info'}> {snack.message} </Alert>
    </Snackbar>
  );
};

export default Toast;
