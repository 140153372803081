import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  message: '',
  isOpen: false
};

const snackSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openSnack: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.isOpen = true;
      return state;
    },
    closeSnack: (state) => {
      state.message = '';
      state.isOpen = false;
      return state;
    }
  }
});

export const { openSnack, closeSnack } = snackSlice.actions;
export default snackSlice.reducer;