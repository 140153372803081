import { modifySignal } from '../lib/signal';
import { setAccount } from '../redux/slices/account';
import { clearNotification, setUnreadNotification } from '../redux/slices/notification';
import axios, { handleResponse } from './Axios';
export const getNotificationHistory = async (params) => {
  try {
    const response = await axios({
      url: '/v1/user/notification',
      method: 'GET',
      params
    });
    return handleResponse(response, 'success');
  } catch (error) {
    console.log(error);
    return handleResponse(error, 'error');
  }
};
export const getUnreadNotification = async (dispatch, isRecentActivity = false) => {
  try {
    const response = await axios({
      url: '/v1/user/get-all-notification',
      method: 'GET',
      params: { isRecentActivity }
    });
    isRecentActivity && dispatch(setUnreadNotification({count:response.data.count,NoticeData:response.data.result}))
    !isRecentActivity && modifySignal(response.data.count)
    return handleResponse(response, 'success');
  } catch (error) {
    console.log(error);
    return handleResponse(error, 'error');
  }
};
export const deleteNotification = async (data) => {
  try {
    const response = await axios({
      url: `/v1/user/notification?id=${data}`,
      method: 'DELETE',
    });

    return handleResponse(response, 'success');
  } catch (error) {
    console.log(error);
    return handleResponse(error, 'error');
  }
};
export const clearNotifications = async (dispatch) => {
  try {
    const response = await axios({
      url: `/v1/user/notification`,
      method: 'PATCH',
    });
    dispatch(clearNotification())
    modifySignal(0)
    return handleResponse(response, 'success');
  } catch (error) {
    console.log(error);
    return handleResponse(error, 'error');
  }
};
export const FetchunReadNotice = async (dispatch, data) => {
  try {
    dispatch(setUnreadNotification(data));
    modifySignal(data.count);
  } catch (err) {
    console.log('FetchunReadNotice',err);
  }
};
