import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';

// Chart Components
import RecentActivity from '../components/dashboard/RecentActivity.js';
import RecentTransactions from '../components/dashboard/RecentTransactions.js';
import Container from '../components/wallet/Container.js';
import { useSelector } from 'react-redux';
import findTotalBalance from '../lib/findTotalBalance.js';
import formatStars from '../lib/formatStars.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Wallet(props) {
  const [show, setShow] = useState({ balance: true, inbound: true, outbound: true });
  const wallet = useSelector((state) => state.wallet);
  const currency = useSelector((state) => state.currency);
  const { totalBalance } = findTotalBalance(wallet, currency);

  const handleShowPortfolio = (id) => {
    setShow((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page bg_top">
              <div className="container-fluid">
                <div className="row py-3">
                  <div className="col-md-12 col-lg-8">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="dashbox">
                          <div className="dashtitle_flex mb-4">
                            <h2 className="dash_inner_title">Wallet</h2>
                          </div>
                          <div className="total_fund_value pt-2">
                            <div>
                              <h5>Total Balance</h5>
                              <div className="d-flex align-items-center">
                                {show.balance ? (
                                  <h3>$ {parseFloat(totalBalance?.toFixed(3)).toLocaleString('en-US') || '000.00'}</h3>
                                ) : (
                                  <h3>${formatStars(parseFloat(totalBalance?.toFixed(3) || 0))}</h3>
                                )}
                                <i
                                  class={`fa-regular ${show.balance ? 'fa-eye-slash' : 'fa-eye'} ms-2 cursor-pointer`}
                                  onClick={() => handleShowPortfolio('balance')}
                                ></i>
                                {/* <i className="fa-regular fa-eye ms-2 cursor-pointer"></i> */}
                              </div>
                            </div>
                            <div>
                              <h5>Total Inbound</h5>
                              <div className="d-flex align-items-center">
                                {show.inbound ? (
                                  <h3>$ {parseFloat(wallet?.totalInbound?.toFixed(3)).toLocaleString('en-US') || '000.00'}</h3>
                                ) : (
                                  <h3>${formatStars(parseFloat(wallet?.totalInbound?.toFixed(3) || 0))}</h3>
                                )}
                                <i
                                  class={`fa-regular ${show.inbound ? 'fa-eye-slash' : 'fa-eye'} ms-2 cursor-pointer`}
                                  onClick={() => handleShowPortfolio('inbound')}
                                ></i>
                                {/* <i className="fa-regular fa-eye ms-2 cursor-pointer"></i> */}
                              </div>
                            </div>
                            <div>
                              <h5>Total Outbound</h5>
                              <div className="d-flex align-items-center">
                                {show.outbound ? (
                                  <h3>$ {parseFloat(wallet?.totalOutbound?.toFixed(3)).toLocaleString('en-US') || '000.00'}</h3>
                                ) : (
                                  <h3>${formatStars(parseFloat(wallet?.totalOutbound?.toFixed(3) || 0))}</h3>
                                )}
                                <i
                                  class={`fa-regular ${show.outbound ? 'fa-eye-slash' : 'fa-eye'} ms-2 cursor-pointer`}
                                  onClick={() => handleShowPortfolio('outbound')}
                                ></i>
                                {/* <i className="fa-regular fa-eye ms-2 cursor-pointer"></i> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Container />
                  </div>

                  <div className="col-md-12 col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <RecentActivity />
                      </div>
                      <div className="col-lg-12">
                        <RecentTransactions />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
