import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import logo from '../assets/images/logo.svg';
import { Button } from '@mui/material';
import NavbarAuth from '../components/NavbarAuth';
import * as yup from 'yup';
import { PASSWORD } from '../constant/regex';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from '../lib/toast';
import { postSignup } from '../api/auth';
import { Spinner } from 'react-bootstrap';
import en from 'react-phone-number-input/locale/en.json';
import PhoneInputWithCountry, { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input/input';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  email: '',
  phoneNumber: '',
  country: '',
  countryCode: '',
  password: '',
  confirmPassword: '',
  isTerms: false
};

const validationSchema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be minimum of 8 characters')
    .max(32, 'Password can have maximum of 32 characters')
    .matches(PASSWORD, { message: 'Password should contain 1 uppercase, 1 lowercase, 1 number & 1 symbol' })
    // .oneOf([yup.ref('confirmPassword'), null], 'Passwords must match')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
  isTerms: yup.boolean().isTrue('Accept to Terms & Policies before signing up')
});

const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <select
    {...rest}
    value={value ? `${value}-${getCountryCallingCode(value)}` : ''}
    onChange={(event) => {
      onChange(event.target.value || undefined);
    }}
  >
    <option value="">Select Country Code</option>
    {getCountries().map((country) => (
      <option key={country} value={`${country}-${getCountryCallingCode(country)}`}>
        {labels[country]} +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);

export default function Register(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState({ password: false, conPassword: false });
  const [country, setCountry] = useState('');

  const { values, errors, handleChange, handleSubmit, setValues, setErrors, setTouched, status, touched } = useFormik({
    initialValues: initialFormValue,
    validationSchema,
    onSubmit: handleRegister
  });

  async function handleRegister(values, { setErrors, resetForm, setStatus }) {
    try {
      setStatus('loading');
      const { success, message, errors: error } = await postSignup(values);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        resetForm();
        navigate('/login');
      } else {
        error && setErrors(error);
        message && toast({ message, type: 'error' }, dispatch);
      }
      setStatus('');
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }

  const handleCountrySelect = (value) => {
    console.log(value);
    setCountry(value ? value.split('-')[0] : undefined);
    setValues((prev) => ({
      ...prev,
      countryCode: value ? value.split('-')[1] : '',
      country: value ? value.split('-')[0] : '',
      phoneNumber: ''
    }));
  };

  const handlePhoneChange = (e) => {
    try {
      setValues({ ...values, phoneNumber: e });
      if (!isValidPhoneNumber(String(e))) {
        setTouched({ ...touched, phoneNumber: true });
        setErrors({ ...errors, phoneNumber: 'Invalid phone number' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      <NavbarAuth />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main">
            <div className="auth_left">
              <form className="auth_form" onSubmit={handleSubmit}>
                <div className="mb-5">
                  <h2>
                    Welcome to <span>AeroVersePay</span>
                  </h2>
                  <h2>Create Your Account</h2>
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Enter Email Address"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{(touched.email && errors.email) || ''}</p>
                </div>
                <div className="form-group">
                  <label>Country Code</label>
                  <CountrySelect
                    className="form-control select-control"
                    labels={en}
                    value={country}
                    onChange={handleCountrySelect}
                    name="countrySelect"
                  />
                  <p className="text-danger">{(touched.country && errors.country) || ''}</p>
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  {/* <input
                    className="form-control"
                    type="number"
                    placeholder="Enter your Phone Number"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handlePhoneChange}
                  /> */}
                  <PhoneInputWithCountry
                    className="form-control"
                    country={country}
                    placeholder="Enter Your Phone Number"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handlePhoneChange}
                    international
                  />
                  <p className="text-danger">{(touched.phoneNumber && errors.phoneNumber) || ''}</p>
                </div>
                <div className="form-group pass-container">
                  <label>Password</label>
                  <input
                    className="form-control"
                    type={show.password ? 'text' : 'password'}
                    placeholder="Enter Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <i
                    className={`blue_text bi ${show.password ? 'bi-eye-slash' : 'bi-eye'} pass-visibler`}
                    onClick={() => setShow((prev) => ({ ...prev, password: !prev.password }))}
                  />
                  <p className="text-danger">{(touched.password && errors.password) || ''}</p>
                </div>
                <div className="form-group pass-container">
                  <label>Confirm Password</label>
                  <input
                    className="form-control"
                    type={show.conPassword ? 'text' : 'password'}
                    placeholder="Enter Password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                  <i
                    className={`blue_text bi ${show.conPassword ? 'bi-eye-slash' : 'bi-eye'} pass-visibler`}
                    onClick={() => setShow((prev) => ({ ...prev, conPassword: !prev.conPassword }))}
                  />
                  <p className="text-danger">{(touched.confirmPassword && errors.confirmPassword) || ''}</p>
                </div>
                <div className="auth_footer">
                  <div className="form-check custom_formcheck">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={values.isTerms}
                      name="isTerms"
                      onChange={handleChange}
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      I Understand <Link to="/terms">Terms of Use</Link> and <Link to="/privacy">Privacy Policy</Link>
                    </label>
                  </div>
                </div>
                <p className="text-danger">{(touched.isTerms && errors.isTerms) || ''}</p>
                <div className="text-center mt-3">
                  <Button variant="contained" type="submit" disabled={status === 'loading'}>
                    {status === 'loading' ? <Spinner size="sm" /> : 'Register'}
                  </Button>
                </div>
                <p className="register_here">
                  Already Registered? <Link to="/login">Sign In</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
