import React from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

const IntentPopup = ({ show, onHide, intentId }) => {
  return (
    <Modal show={show} onHide={onHide} centered backdrop="static" className="primary_modal" size="lg">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="align-items-center">
            <Col xs={12}>
                {intentId}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default IntentPopup;
