import { Button } from '@mui/material';
import isEmpty from 'is-empty';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from '../../lib/toast';
import { get2FA, postEnable2FA } from '../../api/user';
import { getUserProfile } from '../../redux/slices/account';
import { NUMBER } from '../../constant/regex';

const Enable2FA = forwardRef((props, ref) => {
  const [twoFA, setTwoFA] = useState({});
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({ otp: '' });
  const [loading, setLoading] = useState(false);

  const [showEnableTFA, setShowEnableTFA] = useState(false);
  const handleCloseEnableTFA = () => setShowEnableTFA(false);
  const handleShowEnableTFA = () => setShowEnableTFA(true);

  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => ({
      showEnableTFA,
      handleCloseEnableTFA,
      handleShowEnableTFA
    }),
    [showEnableTFA]
  );

  const fetch2FA = useCallback(async () => {
    try {
      const { success, result } = await get2FA();
      if (success) {
        setTwoFA(result);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [dispatch]);

  const handleCopy = (text) => {
    try {
      if (!text) return;
      navigator.clipboard
        .writeText(text)
        .then(() => toast({ message: '2FA secret copied to clipboard', type: 'success' }, dispatch))
        .catch(() => toast({ message: 'Cannot copy the secret', type: 'error' }, dispatch));
    } catch (error) {
      toast({ message: 'Cannot copy the secret', type: 'error' }, dispatch);
    }
  };

  async function handleEnable2FA() {
    try {
      const { success, message, errors } = await postEnable2FA({ otpCode: otp, ...twoFA });
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        dispatch(getUserProfile());
        handleCloseEnableTFA();
      } else {
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
      }
      setOtp('');
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }

  const handleChange = (e) => {
    if (!NUMBER.test(e.target.value) && e.target.value !== '') {
      setErrors({ otpCode: 'Invalid OTP' });
    } else if (e.target.value.length > 6) {
      setErrors({ otpCode: 'Invalid OTP' });
    } else {
      setErrors({});
    }
    setOtp(e.target.value);
  };

  useEffect(() => {
    showEnableTFA && fetch2FA();
  }, [fetch2FA, showEnableTFA]);

  return (
    <Modal show={showEnableTFA} size="md" onHide={handleCloseEnableTFA} centered backdrop="static" className="primary_modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h5 className="text-center mb-3">
          Open Google Authenticator, scan the QR code below or manually enter the key to get a 6-digit verification code
        </h5>
        <h5 class="quote_no text-center">
          Please make sure to keep the Google Authentication Secret Key in a safe place to avoid the inability to change the binding after
          changing or losing your phone.
        </h5>
        <div className="my-3 text-center">
          <img src={isEmpty(twoFA) ? require('../../assets/images/qr_code.png') : twoFA.image} className="img-fluid" alt="QR" />
          <div className="qr_address">
            <p>{twoFA?.secret || ''}</p>
            <img
              src={require('../../assets/images/copy_icon.png')}
              className="img-fluid"
              alt="Copy"
              onClick={() => handleCopy(twoFA?.secret || '')}
            />
          </div>
        </div>
        <hr className="custom_hr" />
        <h5 className="text-center mb-3">Please fill in the verification code you obtained below and complete the verification.</h5>
        <Form>
          <Row>
            <Col lg={12}>
              <Form.Group controlId="formChangePassword" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter OTP from authenticator app"
                  className="primary_input"
                  value={otp}
                  onChange={handleChange}
                />
                <p className="text-danger">{errors?.otpCode || ''}</p>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="primary_btn" variant="contained" onClick={handleEnable2FA} disabled={loading}>
          {loading ? <Spinner size="sm" /> : 'Enable 2FA'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default Enable2FA;
