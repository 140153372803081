import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import { Button } from '@mui/material';
import NavbarAuth from '../components/NavbarAuth';
import { otpVerification } from '../api/user';
import { toast } from '../lib/toast';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function OTP(props) {
  const [otpValues, setOtpValues] = useState(Array(6).fill(''));
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
   const queryParams = new URLSearchParams(location.search);
  const otpAuth = queryParams.get('token');
  const handleChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value.toString().slice(value.length - 1);
    setOtpValues(newOtpValues);
    if (value !== '') {
      focusNextInput(index);
    } else {
      focusPreviousInput(index);
    }
  };
   const handlePaste = (e) => {
     e.preventDefault();
     const pastedData = (e.clipboardData || window.clipboardData).getData('text');
     if (pastedData.length === 6 && /^[0-9]+$/.test(pastedData)) {
       setOtpValues(pastedData.split('').slice(0, 6));
     }
   };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let data = { otp: Number(otpValues.join('')),otpAuth: decodeURIComponent(otpAuth)};
      const { success, message, errors, userToken } = await otpVerification(data);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        setLoading(false);
        navigate(`/reset-password?token=${encodeURIComponent(userToken)}`);
      } else {
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  };

  const focusNextInput = (index) => {
    const nextInput = document.getElementById(`otpInput-${index + 1}`);
    if (nextInput) {
      nextInput.focus();
    }
  };

  const focusPreviousInput = (index) => {
    const previousInput = document.getElementById(`otpInput-${index - 1}`);
    if (previousInput) {
      previousInput.focus();
    }
  };

  useEffect(() => {
    document.getElementById('otpInput-0').focus();
  }, []);


  return (
    <>
      <ScrollToTopOnMount />
      <NavbarAuth />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main">
            <div className="auth_left">
              <form className="auth_form">
                <div className="mb-5">
                  <h2>Password Reset</h2>
                </div>
                <div>
                  <label>Enter OTP</label>
                  <div className="otp_wrap" onPaste={handlePaste}>
                    {otpValues.map((value, index) => (
                      <input
                        key={index}
                        id={`otpInput-${index}`}
                        className="form-control"
                        type="number"
                        maxLength="1"
                        value={value}
                        onChange={(e) => handleChange(index, e.target.value)}
                      />
                    ))}
                  </div>
                  <p className="text-danger">{errors?.otp || ''}</p>
                </div>
                <div className="text-center mt-3">
                  <Button variant="contained" onClick={handleSubmit}>
                    {/* <Link to="/reset-password">Confirm</Link> */}
                    {loading ? <Spinner size="sm" /> : 'Confirm'}
                  </Button>
                </div>
                <p className="register_here">
                  Remember your password? <Link to="/login">Sign In</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
