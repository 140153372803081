import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import useInterval from 'beautiful-react-hooks/useInterval';
import * as yup from 'yup';
import browser from 'browser-detect';
import { Link, useNavigate } from 'react-router-dom';
// import logo from '../assets/images/logo.svg';
import NavbarAuth from '../components/NavbarAuth';
import { EMAIL } from '../constant/regex';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { toast } from '../lib/toast';
import { setupAuthTokens } from '../lib/localStorage';
import { clearLastSession, postSignin } from '../api/auth';
import { setAuth } from '../redux/slices/auth';
// import { fetchUserProfile } from '../api/user';
import isEmpty from 'is-empty';
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  email: '',
  password: '',
  twoFACode: '',
  emailOtp: '',
  otpSessionIdentifier: '',
  isRemember: false
};

const validationSchema = yup.object({
  email: yup
    .string()
    .trim('Email cannot have white spaces')
    .strict(true)
    .matches(EMAIL, { message: 'Invalid email' })
    .required('Email is required')
  // password: yup
  //   .string()
  //   .min(8, 'Password should be minimum of 8 characters')
  //   .max(32, 'Password can have maximum of 32 characters')
  //   .matches(PASSWORD, { message: 'Password should contain 1 uppercase, 1 lowercase, 1 number & 1 symbol' })
  //   .required('Password is required')
});

export default function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deviceInfo, setDeviceInfo] = useState({});
  const [twoFA, set2FA] = useState(false);
  const [emailOtp, setEmailOtp] = useState(false);
  const [show, setShow] = useState({ password: false, conPassword: false });
  const [otpParams, setOtpParams] = useState({ resendTimer: 0, expiryTime: 0, isRunTimer: false });
  const { values, errors, status, touched, handleSubmit, handleChange, setValues } = useFormik({
    initialValues: initialFormValue,
    validationSchema,
    onSubmit: handleLogin
  });

  async function handleLogin(values, { setErrors, resetForm, setStatus }) {
    try {
      setStatus('loading');
      if (isEmpty(deviceInfo)) {
        return toast({ message: 'Something went wrong, please refresh and try again' });
      }
      let request = {
        ...values,
        metaInfo: deviceInfo
      };
      const { success, message, errors, transientToken, sessionId, signal, otpParams } = await postSignin(request);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        setupAuthTokens(transientToken, values.isRemember);
        localStorage.setItem('sessionId', sessionId);
        dispatch(setAuth({ auth: true, userId: '' }));
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      } else {
        if (signal && signal === 'DISPLAY_TWOFA') {
          set2FA(true);
        }
        if (signal && signal === 'DISPLAY_MAILOTP') {
          setEmailOtp(true);
          otpParams && setOtpParams(otpParams);
          message && toast({ message, type: 'success' }, dispatch);
          return setStatus('');
        }
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
      }
      setStatus('');
    } catch (error) {
      toast({ message: 'Something went wrong', type: 'error' }, dispatch);
      setStatus('');
    }
  }

  const getDeviceInfo = async () => {
    try {
      fetch('https://ipapi.co/json/').then((data) => {
        data.json().then((parsed) => {
          const browserResult = browser();
          let loginHistory = {
            countryName: parsed.country_name,
            countryCode: parsed.country_calling_code,
            ipaddress: parsed.ip,
            region: parsed.region,
            broswername: browserResult.name,
            ismobile: browserResult.mobile,
            os: browserResult.os
          };
          setDeviceInfo(loginHistory);
        });
      });
    } catch (err) {
      console.log(err, 'eeee14');
    }
  };

  const updateLastSession = async () => {
    try {
      let lastSesssionId = localStorage.getItem('sessionId');
      if (!lastSesssionId) return;
      await clearLastSession(lastSesssionId);
      localStorage.removeItem('sessionId');
    } catch (error) {
      console.log(error);
    }
  };

  useInterval(
    () => {
      if (otpParams.resendTimer <= 0) {
        setOtpParams((prev) => ({ ...prev, isRunTimer: false, resendTimer: 0 }));
      } else {
        setOtpParams((prev) => ({ ...prev, resendTimer: prev.resendTimer - 1 }));
      }
    },
    otpParams.isRunTimer ? 1000 : null,
    { cancelOnUnmount: true }
  );

  useEffect(() => {
    updateLastSession();
    getDeviceInfo();
  }, []);

  return (
    <>
      <ScrollToTopOnMount />
      <NavbarAuth />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main">
            <div className="auth_left">
              <form className="auth_form" onSubmit={handleSubmit}>
                <div className="mb-5">
                  <h2>
                    Welcome To <span>AeroVersePay</span>
                  </h2>
                  <h2>Sign In To Continue!</h2>
                </div>
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Email Address"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <p className="text-danger">{(touched.email && errors.email) || ''}</p>
                </div>
                <div className="form-group pass-container">
                  <label>Password</label>
                  <input
                    className="form-control"
                    type={show.password ? 'text' : 'password'}
                    placeholder="Enter Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <i
                    className={`bi ${show.password ? 'bi-eye-slash' : 'bi-eye'} pass-visibler blue_text`}
                    onClick={() => setShow((prev) => ({ ...prev, password: !prev.password }))}
                  />
                  <p className="text-danger">{(touched.password && errors.password) || ''}</p>
                </div>
                {emailOtp && (
                  <div className="form-group position-relative">
                    <label>Email One Time Verification (OTP) Code</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Email OTP Code"
                      name="emailOtp"
                      value={values.emailOtp}
                      onChange={handleChange}
                    />
                    <button
                      className="resend_btn"
                      type="button"
                      onClick={
                        otpParams.isRunTimer || status === 'loading'
                          ? null
                          : (e) => {
                              setValues({ ...values, emailOtp: '' });
                              return handleSubmit(e);
                            }
                      }
                    >
                      {status === 'loading' ? (
                        <Spinner size="sm" />
                      ) : (
                        <Fragment>{otpParams.isRunTimer ? `${otpParams.resendTimer}s` : 'Resend'}</Fragment>
                      )}
                    </button>
                    <small>Your OTP Is Sent To Your Registered Email</small>
                    <p className="text-danger">{(touched.emailOtp && errors.emailOtp) || ''}</p>
                  </div>
                )}
                {twoFA && (
                  <div className="form-group">
                    <label>Two Factor Code</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter 2FA Code"
                      name="twoFACode"
                      value={values.twoFACode}
                      onChange={handleChange}
                    />
                    <p className="text-danger">{(touched.twoFACode && errors.twoFACode) || ''}</p>
                  </div>
                )}
                <div className="auth_footer">
                  <div class="form-check custom_formcheck">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={values.isRemember}
                      id="flexCheckDefault"
                      name="isRemember"
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Remember Me
                    </label>
                  </div>
                  <Link to="/forgot-password">Forgot Your Password?</Link>
                </div>
                <div className="text-center mt-3">
                  <Button variant="contained" type="submit">
                    {status === 'loading' ? <Spinner size="sm" /> : 'Login'}
                  </Button>
                </div>
                <p className="register_here">
                  Don't Have An Account? <Link to="/register">Register Here</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
