import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import toast from '../../redux/slices/toast';
import { useDispatch } from 'react-redux';
import { getQuoteRecentTransactions } from '../../api/payment';
import isEmpty from 'is-empty';
import { capitalize } from '@mui/material';
import { format } from 'timeago.js';

export default function RecentTransactions(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const fetchRecentTransactions = useCallback(async () => {
    try {
      const { success, result, message } = await getQuoteRecentTransactions();
      if (success) {
        setData(result);
      } else {
        message && toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchRecentTransactions();
  }, [fetchRecentTransactions]);

  return (
    <div className="dashbox dashbox_small_pad">
      <div className="dashtitle_flex mb-1">
        <h2 className="dash_inner_title dash_inner_title_small">Recent Transactions</h2>
        <Link to="/transactions" className="view_all_link">
          View All
        </Link>
      </div>
      <div className="recent_activity_panel">
        <Scrollbars
          style={{ height: 390 }}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              className="scrollTrackVertical"
              style={{
                ...style,
                backgroundColor: '#1E232E',
                right: '2px',
                bottom: '2px',
                top: '2px',
                borderRadius: '0',
                width: '2px'
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              className="scrollThumbVertical"
              style={{
                ...style,
                right: '1px',
                width: '4px',
                borderRadius: '30px',
                boxShadow: 'none',
                backgroundColor: 'rgba(135, 212, 246, 0.20)'
              }}
            />
          )}
        >
          {isEmpty(data) ? (
            <div className="text-center mt-3 mb-4">
              <small>No Recent Transactions found</small>
            </div>
          ) : (
            <Fragment>
              {data.map((transaction) => {
                let status = transaction.status === 'completed' ? 'trans_success' : transaction.status === 'canceled' ? 'trans_failed' : '';
                return (
                  <div className={`recent_activity_div ${status}`}>
                    <div className="activity_dot"></div>
                    <div className="activity_content">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2>{capitalize(transaction.client)}</h2>
                        <h2>
                          <a className='text-decoration-underline' href={`/quote-status/${transaction.quotationId}`}>{capitalize(transaction.quotationId)}</a>
                        </h2>
                      </div>
                      <h5>Aircraft ID {transaction.aircraftId}</h5>
                      <h5>Amount ${transaction.amount.toFixed(2)}</h5>
                      <div className="trans_status">
                        <p className="blue_text">{capitalize(transaction.status === 'cenceled' ? 'failed' : transaction.status)}</p>
                        <p>{format(transaction.createdAt)}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Fragment>
          )}

          {/* <div className="recent_activity_div trans_success">
            <div className="activity_dot"></div>
            <div className="activity_content">
              <h2>Client Name</h2>
              <h5>Aircraft ID 0000000</h5>
              <h5>Amount $000.000</h5>
              <div className="trans_status">
                <p className="green_text">Successful</p>
                <p>12 minutes ago</p>
              </div>
            </div>
          </div>
          <div className="recent_activity_div trans_failed">
            <div className="activity_dot"></div>
            <div className="activity_content">
              <h2>Client Name</h2>
              <h5>Aircraft ID 0000000</h5>
              <h5>Amount $000.000</h5>
              <div className="trans_status">
                <p className="red_text">Failed</p>
                <p>30 minutes ago</p>
              </div>
            </div>
          </div>
          <div className="recent_activity_div trans_success">
            <div className="activity_dot"></div>
            <div className="activity_content">
              <h2>Client Name</h2>
              <h5>Aircraft ID 0000000</h5>
              <h5>Amount $000.000</h5>
              <div className="trans_status">
                <p className="green_text">Successful</p>
                <p>12 minutes ago</p>
              </div>
            </div>
          </div>
          <div className="recent_activity_div trans_failed">
            <div className="activity_dot"></div>
            <div className="activity_content">
              <h2>Client Name</h2>
              <h5>Aircraft ID 0000000</h5>
              <h5>Amount $000.000</h5>
              <div className="trans_status">
                <p className="red_text">Failed</p>
                <p>30 minutes ago</p>
              </div>
            </div>
          </div> */}
        </Scrollbars>
      </div>
    </div>
  );
}
