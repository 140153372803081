import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const WalletDetails = () => {
  const wallet = useSelector((state) => state.wallet);
  return (
    <Fragment>
      <div className="account_details_box">
        <div className="text-end mb-2">
          {/* <img src={require('../../assets/images/edit_icon.png')} alt="Edit" className="edit_icon" /> */}
        </div>
        <div class="dashtitle_flex mb-3">
          <h2 class="dash_inner_title">Your Aeroverse Wallet</h2>
        </div>
        {/* <div className="profile_inp_row">
          <label>Wallet Number:</label>
          <input className="profile_inp" value="25878895wesww655qae5525ss8s4568" disabled />
        </div> */}
        <div className="profile_inp_row">
          <label>Supported Currencies:</label>
          <input className="profile_inp" value="BTC, ETH, TRX, USDT, USDC" disabled />
        </div>
        <div className="profile_inp_row">
          <label>Wallet Status:</label>
          <input className="profile_inp" value="Active" disabled />
        </div>
        <div className="profile_inp_row">
          <label>ETH Address:</label>          
          <label className="address_val">{wallet?.assets?.find((item) => item.currencySymbol === 'ETH')?.address || ''}</label>
        </div>
        <div className="profile_inp_row">
          <label>BTC Address:</label>
          <label className="address_val">{wallet?.assets?.find((item) => item.currencySymbol === 'BTC')?.address || ''}</label>          
        </div>
        <div className="profile_inp_row">
          <label>TRX Address:</label>
          <label className="address_val">{wallet?.assets?.find((item) => item.currencySymbol === 'TRX')?.address || ''}</label>          
        </div>
      </div>
    </Fragment>
  );
};

export default WalletDetails;
