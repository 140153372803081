import isEmpty from 'is-empty';
import * as yup from 'yup';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile, updateCompanyDetails } from '../../api/user';
import { useFormik } from 'formik';
import { toast } from '../../lib/toast';
import { Button } from '@mui/material';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

let initialFormValue = {
  name: '',
  license: '',
  contact: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    country: ''
  },
  manager: ''
};
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  license: yup.string().required('License is required'),
  contact: yup.string().required('Contact is required'),
  address: yup.object({
    line1: yup.string().required('Line 1 is required'),
    line2: yup.string().required('Line 2 is required'),
    city: yup.string().required('City is required'),
    country: yup.string().required('Country is required')
  }),
  manager: yup.string().required('Manger is required')
});
const CompanyDetails = () => {
  const { account } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  let initialFormValue = {
    name: account?.companyName || '',
    license: account?.businessLicense || '',
    contact: account?.phoneNumber || '',
    address: {
      line1: account?.address?.line1 || '',
      line2: account?.address?.line2 || '',
      city: account?.address?.city || '',
      country: account?.address?.country || ''
    },
    manager: account?.accountsManager || ''
  };

  const { values, errors, status, touched, handleSubmit, handleChange, setValues, setErrors, resetForm, setStatus } = useFormik({
    initialValues: initialFormValue,
    validationSchema,
    onSubmit: updateCompanyDetail
  });

  let { name, license, contact, address, manager } = values;

  async function updateCompanyDetail(values, { setErrors }) {
    try {
      setStatus('loading');
      const { success, message, errors } = await updateCompanyDetails(values);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        await fetchUserProfile(dispatch);
        setStatus('');
        setEdit(false);
      } else {
        errors && setErrors(errors);
        message && toast({ message, type: 'error' }, dispatch);
        setStatus('');
      }
    } catch (error) {
      toast({ message: 'Something went wrong', type: 'error' }, dispatch);
      setStatus('');
      console.log('UPDATE_COMPANY_DETAILS_ERROR', error);
    }
  }
  useEffect(() => {
    if (!isEmpty(account)) {
      setValues({
        name: account?.companyName,
        license: account?.businessLicense,
        contact: account?.phoneNumber,
        address: account?.address,
        manager: account?.accountsManager
      });
    }
  }, []);

  const handleHide = () => {
    setEdit(false);
  };
  return (
    <Fragment>
      <div className="account_details_box">
        <div className="text-end mb-2">
          {/* {edit && <Button onClick={() => handleSubmit()}>Update</Button>} */}
          <img
            src={require('../../assets/images/edit_icon.png')}
            alt="Edit"
            className="edit_icon"
            onClick={() =>
              setEdit((prev) => {
                if (prev) {
                  setErrors({});
                  resetForm();
                }
                return !prev;
              })
            }
          />
        </div>
        <form className="auth_form" onSubmit={handleSubmit}>
          <div className="profile_inp_row">
            <label>Name:</label>
            <input type="text" className="profile_inp" value={name} name="name" disabled={true} />
          </div>
          <p className="text-danger">{(touched.name && errors.name) || ''}</p>
          <div className="profile_inp_row">
            <label>Business License:</label>
            <input type="text" className="profile_inp" value={license} name="license" disabled={true} />
          </div>
          <p className="text-danger">{(touched.license && errors.license) || ''}</p>
          <div className="profile_inp_row">
            <label>Contact Details:</label>
            <input type="text" className="profile_inp" value={contact} name="contact" disabled={true} />
          </div>
          <p className="text-danger">{(touched.contact && errors.contact) || ''}</p>
          <div className="profile_inp_row d-flex align-items-center mb-0">
            <div>
              <label>Address:</label>
              <p></p>
            </div>
            <div>
              <input type="text" className="profile_inp" value={address.line1} name="address.line1" disabled={true} placeholder="Address" />
              <p className="text-danger">{(touched?.address?.line1 && errors?.address?.line1) || ''}</p>
            </div>
            <div>
              <label>Line 1:</label>
              <p></p>
            </div>
            <div>
              <input
                type="text"
                className="profile_inp"
                value={address.line2}
                name="address.line2"
                disabled={true}
                placeholder="Postcode / P.O. Box"
              />
              <p className="text-danger">{(touched?.address?.line2 && errors?.address?.line2) || ''}</p>
            </div>
            <div>
              <label>City:</label>
              <p></p>
            </div>
            <div>
              <input type="text" className="profile_inp" value={address.city} name="address.city" disabled={true} placeholder="City" />
              <p className="text-danger">{(touched?.address?.city && errors?.address?.city) || ''}</p>
            </div>
            <div>
              <label>Country:</label>
              <p></p>
            </div>
            <div>
              <input
                type="text"
                className="profile_inp"
                value={address.country}
                name="address.country"
                disabled={true}
                placeholder="Country"
              />
              <p className="text-danger">{(touched?.address?.country && errors?.address?.country) || ''}</p>
            </div>
          </div>
          <div className="profile_inp_row">
            <label>Accountable Manager:</label>
            <input type="text" className="profile_inp" name="manager" value={manager} disabled={true} />
          </div>
          <p className="text-danger">{(touched.manager && errors.manager) || ''}</p>
          {/* <div className="profile_inp_row" hidden={!edit}>
            <button className="profile_inp">Submit</button>
          </div> */}
        </form>
      </div>
      <Modal show={edit} onHide={handleHide} centered backdrop="static" className="primary_modal" size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="align-items-center">
              <Col xs={12}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    className="primary_input"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <p className="red_text">{(touched.name && errors.name) || ''}</p>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Business License</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Business License"
                    className="primary_input"
                    name="license"
                    value={values.license}
                    onChange={handleChange}
                  />
                  <p className="red_text">{(touched.license && errors.license) || ''}</p>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Contact Details</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Contact Details"
                    className="primary_input"
                    name="contact"
                    value={values.contact}
                    onChange={handleChange}
                  />
                  <p className="red_text">{(touched.contact && errors.contact) || ''}</p>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    className="primary_input"
                    name="address.line1"
                    value={values.address.line1}
                    onChange={handleChange}
                  />
                  <p className="red_text">{(touched?.address?.line1 && errors?.address?.line1) || ''}</p>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Line 1</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Line 1"
                    className="primary_input"
                    name="address.line2"
                    value={values.address.line2}
                    onChange={handleChange}
                  />
                  <p className="red_text">{(touched?.address?.line2 && errors?.address?.line2) || ''}</p>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    className="primary_input"
                    name="address.city"
                    value={values.address.city}
                    onChange={handleChange}
                  />
                  <p className="red_text">{(touched?.address?.city && errors?.address?.city) || ''}</p>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    className="primary_input"
                    name="address.country"
                    value={values.address.country}
                    onChange={handleChange}
                  />
                  <p className="red_text">{(touched?.address?.country && errors?.address?.country) || ''}</p>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Accountable Manager</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Accountable Manager"
                    className="primary_input"
                    name="manager"
                    value={values.manager}
                    onChange={handleChange}
                  />
                  <p className="red_text">{(touched.manager && errors.manager) || ''}</p>
                </Form.Group>
              </Col>
            </Row>
            <Button className="border_btn" type="submit" disabled={status === 'loading'}>
              {status === 'loading' ? <Spinner size="sm" /> : 'Update'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default CompanyDetails;
