import React from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function BookingChart({ process }) {
  const chartData = {
    series: [process || 1],
    labels: ["Revenue"],
  };

  const chartOptions = {
    chart: {
      type: "radialBar",
    },
    labels: chartData.labels,

    stroke: {
      lineCap: "round",
    },
    legend: {
      show: false,
      labels: {
        colors: "#fff",
        useSeriesColors: false,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "radial",
        colorStops: [
          {
            offset: 0,
            color: "#83FFE1",
          },
          {
            offset: [100],
            color: "#80C8F0",
          },
        ],
        shadeIntensity: 1,
        inverseColors: true,
        opacityFrom: 0.2,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "75%",
          background: "transparent",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#2B313D",
          strokeWidth: "100%",
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 15,
            show: true,
            color: "#fff",
            fontFamily: "Outfit",
            fontSize: "12px",
            fontWeight: 300,
          },
          value: {
            formatter: () => `${process || 0}%`,
            fontSize: "16px",
            fontFamily: "Outfit",
            fontWeight: 600,
            color: "#fff",
            show: true,
            offsetY: -15,
          },
        },
      },
    },
    labels: ["completed"],
    responsive: [
      {
        breakpoint: 1366,
        options: {
          chart: {
            width: 180,
          },
        },
      },
      {
        breakpoint: 1279,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  };

  return (
    <>
      <div>
        <ReactApexChart
          options={chartOptions}
          series={chartData.series}
          type="radialBar"
          width={180}
        />
      </div>
    </>
  );
}
