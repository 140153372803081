import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { Button, capitalize } from '@mui/material';

import { toast } from '../lib/toast';
import { getPayment } from '../api/payment';
import { SocketContext } from '../context/SocketContext';
import NavbarAuth from '../components/NavbarAuth';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Paid On',
    selector: (row) => (row.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : '-'),
    sortable: false,
    width: '200px'
  },
  {
    name: 'From Address',
    selector: (row) => row.from,
    sortable: false,
    width: '500px'
  },
  {
    name: 'Transaction Hash',
    selector: (row) => row.transactionHash,
    sortable: false,
    width: '700px'
  },
  {
    name: 'Amount',
    selector: (row) => row.amount,
    sortable: false,
    width: '200px'
  }
];

const PaymentStatus = () => {
  const dispatch = useDispatch();

  const { paymentIntent } = useParams();

  const socket = useContext(SocketContext).socket;

  const [payment, setPayment] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('load');
  const [expiresin, setExpires] = useState('');
  const [expiration, setExpiration] = useState('');

  const fetchPayment = useCallback(async () => {
    try {
      setLoading(true);
      const { success, result, message } = await getPayment(paymentIntent);
      if (success) {
        setPayment(result);
        setLoading(false);
        setStatus(
          result.status === 'open' || result.status === 'pending' ? 'interface' : result.status === 'completed' ? 'success' : 'failed'
        );
        setExpiration(result.expirationTime);
      } else {
        toast({ message, type: 'error' }, dispatch);
        setLoading(false);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
      setLoading(false);
    }
  }, [dispatch, paymentIntent]);

  useEffect(() => {
    fetchPayment();
  }, [fetchPayment]);

  useEffect(() => {
    let timerId;
    if (expiration && payment?.status !== 'completed' && payment?.status !== 'canceled') {
      timerId = setInterval(() => {
        setExpires(() => {
          let timeRemain = new Date(expiration).getTime() - Date.now();
          if (timeRemain <= 0) {
            if (payment?.status !== 'completed') {
              fetchPayment()
                .then(() => {
                  setStatus('failed');
                })
                .catch(() => {
                  setStatus('failed');
                });
            }
            return '00:00:00';
          }
          let seconds = Math.floor(timeRemain / 1000);
          let timer = `00:${String(Math.floor(seconds / 60))}:${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}`;
          return timer;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [expiration, payment?.status, fetchPayment]);

  const handleCopy = (text) => {
    if (!navigator.clipboard) {
      toast({ message: 'Cannot copy to clipboard', type: 'error' }, dispatch);
    } else {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast({ message: 'Copied to clipboard successfully', type: 'success' }, dispatch);
        })
        .catch(() => {
          toast({ message: 'Cannot copy to clipboard', type: 'error' }, dispatch);
        });
    }
  };

  useEffect(() => {
    socket.on(paymentIntent, async (data) => {
      setPayment((prev) => ({ ...prev, receivedAmount: data.receivedAmount }));
      if (data.paymentStatus === 'completed' || data.paymentStatus === 'canceled') {
        await fetchPayment();
      }
      setStatus(data.status);
      toast({ message: data.message, type: data.success ? 'success' : 'error' }, dispatch);
    });

    return () => socket.off(paymentIntent);
  }, [socket, paymentIntent, dispatch, fetchPayment]);

  return (
    <>
      <NavbarAuth />
      <div className="container">
        <div className="ps-contain h-auto mb-5 quote_status_report">
          {loading ? (
            <Spinner />
          ) : (
            <Fragment>
              {status === 'interface' ? (
                <Row>
                  <Col xxl={9} xl={10} className="mx-auto">
                    <Row className="mb-4">
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Payment Intent ID
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {paymentIntent}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Payment Currency
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {payment.symbol}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Payment Network
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {payment.isToken
                              ? payment.tokenType === 'ERC20'
                                ? 'Ethereum'
                                : 'Tron Network'
                              : payment.symbol === 'ETH'
                                ? 'Ethereum'
                                : payment.symbol === 'BTC'
                                  ? 'Bitcoin'
                                  : 'Tron Network'}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Amount (USD)
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {(payment.amount + payment.fee).toFixed(3)} USD
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Payable Amount in ({payment.symbol})
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {(parseFloat(payment.networkAmount) + parseFloat(payment.networkFee)).toFixed(6)} {payment.symbol}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Received Amount ({payment.symbol})
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {parseFloat(payment.receivedAmount).toFixed(6)} {payment.symbol}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Pending Amount ({payment.symbol})
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {(payment.networkAmount + payment.networkFee - payment.receivedAmount < 0.000001
                              ? 0.000001
                              : payment.networkAmount + payment.networkFee - payment.receivedAmount
                            ).toFixed(6)}{' '}
                            {payment.symbol}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Receiving Address
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0 align-items-center">
                            <button className="ps-copy-btn text-start" onClick={() => handleCopy(payment.address)}>
                              {payment.address}
                              <i className={`ms-3 fa fa-copy`} />
                            </button>{' '}
                            {/* <i className={`ms-3 fa fa-copy`} onClick={() => handleCopy(payment.address)} /> */}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Payment Intent Created On
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {moment(payment.createdAt).format('DD MMM YYYY hh:mm:ss A')}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Payment QR Code (Scan this QR in your wallet to pay)
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            <img src={payment.paymentQR} alt="paymentQR" />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Payment portal expires in
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {expiresin && expiresin}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} className="mb-3">
                        <Row className="w-100">
                          <Col xs={12} lg={6}>
                            Payment Status
                          </Col>
                          <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                            {capitalize(payment.status)}
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12}>
                        <Button className="border_btn ps-pad-right" onClick={() => handleCopy(window.location.href)}>
                          Share Payment Page
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : status === 'success' || status === 'failed' ? (
                <div>
                  <Row>
                    <Col xxl={9} xl={10} className="mx-auto">
                      <Row className="mb-4">
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Payment Intent ID
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {paymentIntent}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Payment Currency
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {payment.symbol}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Payment Network
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {payment.isToken
                                ? payment.tokenType === 'ERC20'
                                  ? 'Ethereum'
                                  : 'Tron Network'
                                : payment.symbol === 'ETH'
                                  ? 'Ethereum'
                                  : payment.symbol === 'BTC'
                                    ? 'Bitcoin'
                                    : 'Tron Network'}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Amount (USD)
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {(payment.amount + payment.fee).toFixed(3)} USD
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Payable Amount in ({payment.symbol})
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {(parseFloat(payment.networkAmount) + parseFloat(payment.networkFee)).toFixed(6)} {payment.symbol}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Received Amount ({payment.symbol})
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {parseFloat(payment.receivedAmount).toFixed(6)} {payment.symbol}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Pending Amount ({payment.symbol})
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {(payment.networkAmount + payment.networkFee - payment.receivedAmount < 0.000001
                                ? 0.000001
                                : payment.networkAmount + payment.networkFee - payment.receivedAmount
                              ).toFixed(6)}{' '}
                              {payment.symbol}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Receiving Address
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0 align-items-center">
                              <button className="ps-copy-btn text-start" onClick={() => handleCopy(payment.address)}>
                                {payment.address}
                                <i className={`ms-3 fa fa-copy`} />
                              </button>{' '}
                              {/* <i className={`ms-3 fa fa-copy`} onClick={() => handleCopy(payment.address)} /> */}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Payment Intent Created On
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {moment(payment.createdAt).format('DD MMM YYYY hh:mm:ss A')}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <Row className="w-100">
                            <Col xs={12} lg={6}>
                              Payment Status
                            </Col>
                            <Col xs={12} lg={6} className="d-flex justify-content-lg-end mt-1 mt-lg-0">
                              {capitalize(payment.status)}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} className="mb-3">
                          <DataTable columns={columns} data={payment?.transactions || []} theme="dark" />
                        </Col>
                        <Col xs={12}>
                          <Button className="border_btn ps-pad-right" onClick={() => handleCopy(window.location.href)}>
                            Share Payment Page
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <Spinner />
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentStatus;
