import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { retrieveCurrency } from '../../api/user';

export const getCurrencies = createAsyncThunk('currency', async () => {
  const { success, result } = await retrieveCurrency();
  if (success) return result;
  else return [];
});

const currencySlice = createSlice({
  name: 'currency',
  initialState: [],
  reducers: {
    setupCurrency: (state, action) => {
      state = action.payload;
      return state;
    },
    resetCurrency: (state, action) => {
      state = [];
      return state;
    }
  },
  extraReducers: (build) => {
    build.addCase(getCurrencies.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
  }
});

export const { resetCurrency, setupCurrency } = currencySlice.actions;
export default currencySlice.reducer;
