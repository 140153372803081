import axios, { handleResponse } from './Axios';

export const getCategories = async () => {
  try {
    let response = await axios({
      url: '/v1/user/support-category',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const postSupportTicket = async (data) => {
  try {
    let response = await axios({
      url: '/v1/user/support-ticket',
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getAllTickets = async () => {
  try {
    const response = await axios({
      url: '/v1/user/support-ticket',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const postTicketReply = async (data) => {
  try {
    const response = await axios({
      url: '/v1/user/ticket-reply',
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const closeTicket = async (ticketId) => {
  try {
    const response = await axios({
      url: `/v1/user/close-ticket/${ticketId}`,
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
