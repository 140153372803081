import { Button } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../../lib/toast';
import { createPayment } from '../../api/payment';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const RequestPayment = ({ show, onHide, aircraft }) => {
  const initialValues = {
    transactionId: aircraft.transactionId,
    fee: '',
    buyer: aircraft.buyer,
    aircraftId: aircraft.id,
    amount: '',
    currency: ''
  };

  const currency = useSelector((state) => state.currency);
  const { values, errors, touched, setValues, handleChange, handleSubmit } = useFormik({ initialValues, onSubmit: paymentSubmit });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function paymentSubmit(values, { setErrors, resetForm }) {
    try {
      setLoading(true);
      const { success, message, errors, result } = await createPayment({ ...values, id: aircraft._id });
      if (success) {
        toast({ message, type: 'success' }, dispatch);

        setTimeout(() => {
          setLoading(false);
          window.open(`${config.FRONTEND}/payment-status/${result.paymentIntent}`, '_blank');
          onHide();
        }, 2000);
      } else {
        setLoading(false);
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
      }
    } catch (error) {
      setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }

  useEffect(() => {
    let selectedCurrency = currency.find((item) => item._id === values.currency);
    if (!selectedCurrency) return;
    let feeAmt = (selectedCurrency.txnFee * parseFloat(values.amount)) / 100;
    if (!isNaN(feeAmt)) setValues({ ...values, fee: feeAmt });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amount, values.currency]);

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static" className="primary_modal" size="lg">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="align-items-center">
            <Col xs={12}>
              <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Transaction Number"
                  className="primary_input"
                  value={values.transactionId}
                  disabled
                />
                <p className="red_text">{(touched.transactionId && errors.transactionId) || ''}</p>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group controlId="formGridFee" className="mb-3">
                <Form.Control type="number" placeholder="Fee" className="primary_input" value={values.fee} disabled />
                <p className="red_text">{(touched.fee && errors.fee) || ''}</p>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formGridClientName" className="mb-3">
                <Form.Control type="text" placeholder="Client" className="primary_input" value={values.buyer} disabled />
                <p className="red_text">{(touched.buyer && errors.buyer) || ''}</p>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formGridAircraftId" className="mb-3">
                <Form.Control type="text" placeholder="Aircraft ID" className="primary_input" value={values.aircraftId} disabled />
                <p className="red_text">{(touched.aircraftId && errors.aircraftId) || ''}</p>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="formGridAmount" className="mb-3">
                <Form.Control
                  type="number"
                  placeholder="Amount"
                  className="primary_input"
                  onChange={handleChange}
                  name="amount"
                  value={values.amount}
                />
                <p className="red_text">{(touched.amount && errors.amount) || ''}</p>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="formBank">
                <Form.Select value={values.currency} className="primary_select" onChange={handleChange} name="currency">
                  <option value={''}>Select Currency</option>
                  {currency &&
                    currency.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.type === 'token' ? `${item.symbol} - ${item.tokenType}` : item.symbol}
                      </option>
                    ))}
                </Form.Select>
                <p className="red_text">{(touched.currency && errors.currency) || ''}</p>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Button className="border_btn" type="button" onClick={handleSubmit} disabled={loading}>
                {loading ? <Spinner size="sm" /> : 'Request Payment'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RequestPayment;
