import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import NavbarAuth from '../components/NavbarAuth';
import { toast } from '../lib/toast';
import { postForgetPassword } from '../api/user';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ForgotPassword(props) {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { success, message, errors,userToken } = await postForgetPassword({ email });
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        setLoading(false);
        console.log("USER_TOKEN", userToken)
        navigate(`/otp?token=${encodeURIComponent(userToken)}`);
      } else {
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      <NavbarAuth />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main">
            <div className="auth_left">
              <form className="auth_form">
                <div className="mb-5">
                  <h2>Password Reset</h2>
                </div>
                <div className="form-group">
                  <label>Enter Email to receive OTP</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="text-danger">{errors?.email || ''}</p>
                </div>
                <div className="text-center mt-3">
                  <Button variant="contained" disabled={loading} onClick={handleSubmit}>
                    {loading ? <Spinner size="sm" /> : 'Request OTP'}
                  </Button>
                </div>
                <p className="register_here">
                  Remember your password? <Link to="/login">Sign In</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
