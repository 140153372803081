import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import SendQuote from './Modals/sendQuote';
import Withdraw from './Modals/withdrawModal';
import Transfer from './Modals/Transfer';
import findTotalBalance from '../../lib/findTotalBalance';
import findPercent from '../../lib/findPercent';
import WithdrawCryptoModal from './Modals/WithdrawCryptoModal';

const findBalanceAndScale = (balance, price, wallet, currency) => {
  try {
    let totalBalance = findTotalBalance(wallet, currency).totalBalance;
    let convertedBalance = (balance * price).toPrecision(4);
    let scale = Math.floor(findPercent(convertedBalance, totalBalance));
    return { scale, balance: convertedBalance };
  } catch (error) {
    return { balance: '000.000', scale: 1 };
  }
};

const Card = ({ Component, asset, currencyData, currency, totalBound, account }) => {
  const [showSendQuote, setShowSendQuote] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const handleCloseSendQuote = () => setShowSendQuote(false);
  const handleCloseWithdraw = () => setShowWithdraw(false);
  const handleCloseTransfer = () => setShowTransfer(false);
  const handleOpenTransfer = () => setShowTransfer(true);
  const walletRedux = useSelector((state) => state.wallet);
  const currencyRedux = useSelector((state) => state.currency);
  let { balance, scale } = findBalanceAndScale(asset?.balance, currency?.price, walletRedux, currencyRedux);
  return (
    <div className="wallet_asset_div">
      <h2>
        {currency.name} {asset.balance.toPrecision(4)}
      </h2>
      <h5>{currency.type === 'token' ? `${currency.symbol} ${asset.tokenType}` : currency.symbol}</h5>
      <Component balance={balance} scale={scale} />
      <div className="asset_btn_grp">
        <Button onClick={() => setShowSendQuote(true)} className="border_btn">
          Send Quote
        </Button>
        <Button onClick={() => setShowWithdraw(true)} className="border_btn">
          Withdraw
        </Button>
        <Button className="border_btn" onClick={handleOpenTransfer}>
          Withdraw As Crypto
        </Button>
      </div>
      <SendQuote showSendQuote={showSendQuote} handleCloseSendQuote={handleCloseSendQuote} />
      {/* <Transfer showTransfer={showTransfer} handleClose={handleCloseTransfer} /> */}
      <WithdrawCryptoModal
        show={showTransfer}
        onHide={handleCloseTransfer}
        currencyData={currencyData}
        currency={currency}
        account={account}
      />
      <Withdraw
        currencyData={currencyData}
        currency={currency}
        account={account}
        showWithdraw={showWithdraw}
        handleCloseWithdraw={handleCloseWithdraw}
      />
    </div>
  );
};

export default Card;
