import React, { Fragment, createContext, useState } from 'react';
import Header from '../components/index/layouts/Header';
import Footer from '../components/index/layouts/Footer';
import MainContent from '../components/index/layouts/MainContent';
import Contact from '../components/index/Modal/Contact';

export const IndexContext = createContext({ show: false, handleShow: null });

const Index = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <Fragment>
      <IndexContext.Provider value={{ show, handleShow }}>
        <Header />
        <MainContent />
        <Footer />
      </IndexContext.Provider>
      <Contact show={show} onHide={handleHide} />
    </Fragment>
  );
};

export default Index;
