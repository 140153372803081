import axios, { handleResponse } from './Axios';


export const getFiatCurrency = async () => {
  try {
    let response = await axios({
      url: '/v1/currency/get-fiat-currency',
      method: 'GET',
      
    });
    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
