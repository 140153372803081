import { Button } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { toast } from '../../lib/toast';
import { getLoginHistory } from '../../api/user';
import { momentFormat } from '../../lib/moment';

const LoginHistory = forwardRef((props, ref) => {
  const [showLoginHistory, setShowLoginHistory] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({ page: 1, limit: 10, sort: {} });
  const handleCloseLoginHistory = () => setShowLoginHistory(false);
  const handleShowLoginHistory = () => setShowLoginHistory(true);
  const dispatch = useDispatch();

  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ page: page, limit: newPerPage } };
    setFilter(filterData);
  };
  const handlePageChange = (page) => {
    let filterData = { ...filter, ...{ page: page } };
    setFilter(filterData);
  };
  const handleSort = (column, direction) => {
    let filterData = { ...filter, sort: { [column.sortField]: direction === 'desc' ? -1 : 1 } };
    setFilter(filterData);
  };
  const columns = [
    {
      name: 'Date',
      selector: (row) => momentFormat(row.createdAt, 'DD-MM-YYYY HH:mm'),
      sortable: true,
      sortField: 'createdAt',
      width: '250px'
    },
    {
      name: 'IP Address',
      selector: (row) => row.ipAddress,
      sortable: true,
      sortField: 'ipAddress',
      width: '400px'
    },
    {
      name: 'Device',
      selector: (row) => row.os,
      sortable: true,
      sortField: 'os',
      width: '200px'
    },
    {
      name: 'Location',
      selector: (row) => `${row.regionName}, ${row.countryName}`,
      sortable: true,
      sortField: 'countryName',
      width: '400px'
    }
  ];

  const fetchLoginHistory = useCallback(
    async (filter) => {
      try {
        const { success, result, message } = await getLoginHistory(filter);
        if (success) {
          setData(result.data);
          setCount(result.count);
        } else {
          toast({ message, type: 'error' }, dispatch);
        }
      } catch (error) {
        toast({ message: 'Some error occurred', type: 'error' }, dispatch);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (filter.limit && filter.page && showLoginHistory) {
      fetchLoginHistory(filter);
    }
  }, [filter, fetchLoginHistory, showLoginHistory]);

  useImperativeHandle(
    ref,
    () => ({
      showLoginHistory,
      handleCloseLoginHistory,
      handleShowLoginHistory
    }),
    [showLoginHistory]
  );

  return (
    <Modal show={showLoginHistory} size="xl" onHide={handleCloseLoginHistory} centered backdrop="static" className="primary_modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <DataTable
          columns={columns}
          data={data}
          responsive
          theme="dark"
          pagination
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          paginationTotalRows={count}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationServer
          sortServer
          onSort={handleSort}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="primary_btn" variant="contained" onClick={handleCloseLoginHistory}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default LoginHistory;
