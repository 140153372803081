export const EMAIL =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const EMAIL_CHARS = /^[+\-_\.@a-zA-Z0-9]+$/i;
export const PASSWORD = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*|\\/<>()+=\-_])[A-Za-z\d~!@#$%^&*|\\/<>()+=\-_]{8,32}$/;
export const ISALNUMHYP = /^[A-Z0-9_-]+$/;
export const ISALNUMHYPCASE = /^[a-zA-Z0-9_-\s]+$/;
export const ISALNUM = /^[a-zA-Z0-9\s]+$/;
export const ISALNUMSTRICT = /^[A-Z0-9]+$/;
export const ISALNUMNOSPACE = /^[a-zA-Z0-9]+$/;
export const PLACE = /^[a-zA-Z0-9\s()]+$/;
export const NUMBER = /^-?\d*\.?\d+$/;
export const PHONE_ADVANCED = /^\+?\(?([0-9]{1,3})\)?[-. ]?([0-9]{0,5})[-. ]?([0-9-]{4,11})$/gm;
export const PHONE = /^\+\d{1,18}$/i;
export const PHONE2 = /^\+\d{1,18}$/i;
export const PRICE = /^\d+(,\d+)*(\.\d+)?\d+$/;
