import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { Button, capitalize } from '@mui/material';
import { toast } from '../lib/toast.js';

// Chart Components
import BookingChart from '../components/aircraft-sales/BookingChart.js';
import TotalEscrowChart from '../components/aircraft-sales/TotalEscrowChart.js';
import { useDispatch } from 'react-redux';
import { getAircraftDetail } from '../api/aircraft.js';
import { SocketContext } from '../context/SocketContext.js';
import moment from 'moment';
import isEmpty from 'is-empty';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AircraftDetails(props) {
  const { aircraftId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [aircraft, setAircraft] = useState('');
  const socket = useContext(SocketContext).socket;

  const fetchAircraftDetail = useCallback(async () => {
    try {
      if (!aircraftId) return;
      const { success, message, result } = await getAircraftDetail(aircraftId);
      if (success) {
        setAircraft(result);
      } else {
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [dispatch, aircraftId]);

  useEffect(() => {
    fetchAircraftDetail();
  }, [fetchAircraftDetail]);

  useEffect(() => {
    socket.on(aircraftId, async () => {
      await fetchAircraftDetail();
    });

    return () => socket.off(aircraftId);
  }, [socket, aircraftId, fetchAircraftDetail]);

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row pt-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_nopad">
                      <div className="aircraft_details_top">
                        <div className="aircraft_details_top_left">
                          <img src={require('../assets/images/aircraft.png')} alt="Aircraft" className="img-fluid" />
                          <h3>{aircraft?.name || ''}</h3>
                          <h5>
                            AIRCRAFT ID: <span>{aircraft?.id || ''}</span>
                          </h5>
                        </div>
                        <div className="aircraft_details_top_right">
                          <p>
                            Transaction ID : <span>{aircraft?.transactionId || ''}</span>
                          </p>
                          <p>
                            Buyer : <span>{aircraft?.buyer || ''}</span>
                          </p>
                          <p>
                            Start Date :{' '}
                            <span>{aircraft?.createdAt ? moment(aircraft.createdAt).format('DD MMM YYYY') : '-- --- ----'}</span>
                          </p>
                          <p>
                            Last Payment :{' '}
                            <span>
                              {aircraft
                                ? aircraft?.lastPayment
                                  ? moment(aircraft.lastPayment).format('DD MMM YYYY')
                                  : 'Payment not made'
                                : '-- --- ----'}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row dashbox_fullheight_row">
                  <div className="col-lg-8">
                    <div className="dashbox">{aircraft && <TotalEscrowChart aircraft={aircraft} />}</div>
                    <div className="dashbox mb-0">
                      <div className="dashtitle_flex mb-3 justify-content-center align-items-center">
                        <h2 className="dash_inner_title dash_inner_title_small">Aircraft Details</h2>
                      </div>
                      <div className="aircraft_details_bottom">
                        <div className="aircraft_details_bottom_left">
                          <div className="aircraft_details_row">
                            <label>Aircraft ID</label>
                            <label>{aircraft?.id || ''}</label>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Year of Manufacture</label>
                            <label>{aircraft?.yearOfManufacturing || ''}</label>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Serial Number</label>
                            <label>{aircraft?.serialNumber || ''}</label>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Registration</label>
                            <label>{aircraft?.registration || ''}</label>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Engine 1 S/N</label>
                            <label>{aircraft?.engine1 || ''}</label>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Engine 2 S/N</label>
                            <label>{aircraft?.engine2 || 'N/A'}</label>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Engine 3 S/N</label>
                            <label>{aircraft?.engine3 || 'N/A'}</label>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Engine 4 S/N</label>
                            <label>{aircraft?.engine4 || 'N/A'}</label>
                          </div>
                          <div className="aircraft_details_row">
                            <label>APU S/N</label>
                            <label>{aircraft?.apu || 'N/A'}</label>
                          </div>
                        </div>
                        <div className="aircraft_details_bottom_right">
                          <div className="aircraft_details_row">
                            <label>Point of Contact</label>
                            <label
                              className="accordion-button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {aircraft?.pocName || ''}
                            </label>
                          </div>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse broker_info"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <p>
                              <img src={require('../assets/images/phone_icon.png')} alt="icon" />
                              {aircraft?.pocPhoneNumber || ''}
                            </p>
                            <p>
                              <img src={require('../assets/images/phone_icon.png')} alt="icon" />
                              {aircraft?.pocPhoneNumber2 || '---'}
                            </p>
                            <p>
                              <img src={require('../assets/images/mail_icon.png')} alt="icon" />
                              {aircraft?.pocEmail || ''}
                            </p>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Escrow Agent</label>
                            <label
                              className="accordion-button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="true"
                              aria-controls="collapseTwo"
                            >
                              {aircraft?.esName || ''}
                            </label>
                          </div>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse broker_info"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <p>
                              <img src={require('../assets/images/phone_icon.png')} alt="icon" />
                              {aircraft?.esPhoneNumber || ''}
                            </p>
                            <p>
                              <img src={require('../assets/images/phone_icon.png')} alt="icon" />
                              {aircraft?.esPhoneNumber2 || '---'}
                            </p>
                            <p>
                              <img src={require('../assets/images/mail_icon.png')} alt="icon" />
                              {aircraft?.esEmail || ''}
                            </p>
                          </div>
                          <div className="aircraft_details_row">
                            <label>Buyer KYC</label>
                            <label>{capitalize(aircraft?.kycStatus || '')}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="dashbox dashbox_fullheight">
                      <div className="dashtitle_flex mb-3">
                        <h2 className="dash_inner_title dash_inner_title_small">Transaction Checkpoints</h2>
                      </div>
                      <div className="recent_activity_panel trans_checkpoints_panel">
                        <div
                          className={`recent_activity_div ${
                            aircraft?.checkPointLevel > 1 ? 'trans_success' : aircraft?.checkPointLevel == 1 ? '' : 'trans_disabled'
                          }`}
                        >
                          <div className="activity_dot"></div>
                          <div className="activity_content">
                            <h2>Letter of Intent (LOI)</h2>
                            {aircraft?.checkPointLevel >= 1 && (
                              <Fragment>
                                {aircraft?.checkPointLevel > 1 ? (
                                  <h5>
                                    Completed on{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['1'].end).format('DD MMM YYYY')}
                                  </h5>
                                ) : (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['1'].start).format('DD MMM YYYY')}
                                  </h5>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div
                          className={`recent_activity_div ${
                            aircraft?.checkPointLevel > 2 ? 'trans_success' : aircraft?.checkPointLevel == 2 ? '' : 'trans_disabled'
                          }`}
                        >
                          <div className="activity_dot"></div>
                          <div className="activity_content">
                            <h2>Deposit</h2>
                            {aircraft?.checkPointLevel >= 2 && (
                              <Fragment>
                                {aircraft?.checkPointLevel > 2 ? (
                                  <h5>
                                    Completed on{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['2'].end).format('DD MMM YYYY')}
                                  </h5>
                                ) : (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['2'].start).format('DD MMM YYYY')}
                                  </h5>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div
                          className={`recent_activity_div ${
                            aircraft?.checkPointLevel > 3 ? 'trans_success' : aircraft?.checkPointLevel == 3 ? '' : 'trans_disabled'
                          }`}
                        >
                          <div className="activity_dot"></div>
                          <div className="activity_content">
                            <h2>Purchase Agreement</h2>
                            {aircraft?.checkPointLevel >= 3 && (
                              <Fragment>
                                {aircraft?.checkPointLevel > 3 ? (
                                  <h5>
                                    Completed on{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['3'].end).format('DD MMM YYYY')}
                                  </h5>
                                ) : (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['3'].start).format('DD MMM YYYY')}
                                  </h5>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div
                          className={`recent_activity_div ${
                            aircraft?.checkPointLevel > 4 ? 'trans_success' : aircraft?.checkPointLevel == 4 ? '' : 'trans_disabled'
                          }`}
                        >
                          <div className="activity_dot"></div>
                          <div className="activity_content">
                            <h2>Pre purchase Inspection</h2>
                            {aircraft?.checkPointLevel >= 4 && (
                              <Fragment>
                                {aircraft?.checkPointLevel > 4 ? (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['4'].start).format('DD MMM YYYY')}
                                  </h5>
                                ) : (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['4'].start).format('DD MMM YYYY')}
                                  </h5>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div
                          className={`recent_activity_div ${
                            aircraft?.checkPointLevel > 5 ? 'trans_success' : aircraft?.checkPointLevel == 5 ? '' : 'trans_disabled'
                          }`}
                        >
                          <div className="activity_dot"></div>
                          <div className="activity_content">
                            <h2>Acceptance of Aircraft</h2>
                            {aircraft?.checkPointLevel >= 5 && (
                              <Fragment>
                                {aircraft?.checkPointLevel > 5 ? (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['5'].start).format('DD MMM YYYY')}
                                  </h5>
                                ) : (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['5'].start).format('DD MMM YYYY')}
                                  </h5>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div
                          className={`recent_activity_div ${
                            aircraft?.checkPointLevel > 6 ? 'trans_success' : aircraft?.checkPointLevel == 6 ? '' : 'trans_disabled'
                          }`}
                        >
                          <div className="activity_dot"></div>
                          <div className="activity_content">
                            <h2>Change of Registration</h2>
                            {aircraft?.checkPointLevel >= 6 && (
                              <Fragment>
                                {aircraft?.checkPointLevel > 6 ? (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['6'].start).format('DD MMM YYYY')}
                                  </h5>
                                ) : (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['6'].start).format('DD MMM YYYY')}
                                  </h5>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div
                          className={`recent_activity_div ${
                            aircraft?.checkPointLevel > 7 ? 'trans_success' : aircraft?.checkPointLevel == 7 ? '' : 'trans_disabled'
                          }`}
                        >
                          <div className="activity_dot"></div>
                          <div className="activity_content">
                            <h2>Delivery</h2>
                            {aircraft?.checkPointLevel >= 7 && (
                              <Fragment>
                                {aircraft?.checkPointLevel > 7 ? (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['7'].start).format('DD MMM YYYY')}
                                  </h5>
                                ) : (
                                  <h5>
                                    In Progress since{' '}
                                    {aircraft?.checkPointHistory && moment(aircraft?.checkPointHistory['7'].start).format('DD MMM YYYY')}
                                  </h5>
                                )}
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
