import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector,useDispatch } from 'react-redux';
import { format } from 'timeago.js';
import { getUnreadNotification } from '../../api/notification';

export default function RecentActivity(props) {
  const { notification } = useSelector((state) => state);
  const dispatch = useDispatch()
  // const [data, setData] = useState(notification.NoticeData)
  useEffect(() => {
    getUnreadNotification(dispatch, true)
  },[])

  return (
    <div className="dashbox dashbox_small_pad">
      <div className="dashtitle_flex mb-1">
        <h2 className="dash_inner_title dash_inner_title_small">Recent Activity</h2>
        {Array.isArray(notification.NoticeData) && notification.NoticeData.length > 0 && (
          <Link to="/notifications" className="view_all_link">
            View all
          </Link>
        )}
      </div>
      <div className="recent_activity_panel">
        <Scrollbars
          style={{ height: Array.isArray(notification.NoticeData) && notification.NoticeData.length == 0 ? 0 : notification.NoticeData.length === 1 ? 100 : 390 }}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              className="scrollTrackVertical"
              style={{
                ...style,
                backgroundColor: '#1E232E',
                right: '2px',
                bottom: '2px',
                top: '2px',
                borderRadius: '0',
                width: '2px'
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              className="scrollThumbVertical"
              style={{
                ...style,
                right: '1px',
                width: '4px',
                borderRadius: '30px',
                boxShadow: 'none',
                backgroundColor: 'rgba(135, 212, 246, 0.20)'
              }}
            />
          )}
        >
          {Array.isArray(notification.NoticeData) &&
            notification.NoticeData.length > 0 &&
            notification.NoticeData.map((item) => (
              <div className="recent_activity_div">
                <div className="activity_dot"></div>
                <div className="activity_content">
                  <h2>{item.title}</h2>
                  <h5>{item.description}</h5>
                  <p>{format(item.createdAt)}</p>
                </div>
              </div>
            ))}
        </Scrollbars>
      </div>
    </div>
  );
}
