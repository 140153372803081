import { useFormik } from 'formik';
import isEmpty from 'is-empty';
import React, { Fragment, useRef } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import * as yup from 'yup';
import { toast } from '../../lib/toast';
import { useDispatch } from 'react-redux';
import { postTicketReply } from '../../api/support';

const initialValues = {
  message: '',
  attachment: ''
};

const validationSchema = yup.object({
  message: yup.string().min(10, 'Message should have minimum 10 characters').max(1000, 'Message can have maximum of 1000 characters')
});

const TicketReply = ({ ticketId, updateReplies }) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { values, setValues, handleChange, handleSubmit, errors, touched, setErrors } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleReplySubmit
  });

  async function handleReplySubmit(values, { setErrors, resetForm }) {
    try {
      const formData = new FormData();
      formData.append('message', values.message);
      formData.append('attachment', values.attachment);
      formData.append('ticketId', ticketId);
      const { success, message, result } = await postTicketReply(formData);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        updateReplies(result);
        resetForm();
      } else {
        message && toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
        console.log(error)
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    if (!file.type.startsWith('image') && !file.type.startsWith('video')) {
      return setErrors({ attachment: 'Invalid file format' });
    } else if (file.size > 5 * 1024 * 1024) {
      return setErrors({ attachment: 'Maximum file size is 5MB' });
    }
    setValues((prev) => ({ ...prev, attachment: e.target.files[0] }));
  };

  return (
    <Fragment>
      {!isEmpty(values.attachment) && (
        <Fragment>
          <Form.Label className="reply_label">
            <p>{values.attachment.name}</p>
            <i className="fa fa-close" onClick={() => setValues({ ...values, attachment: '' })} />
          </Form.Label>
        </Fragment>
      )}
      <InputGroup className="mb-3">
        <Form.Control
          type="text"
          placeholder="Enter Message"
          className="primary_input"
          name="message"
          value={values.message}
          onChange={handleChange}
        />
        <Button variant="outline-secondary" id="button-addon2" className="msg_attach">
          <i className="fa-solid fa-paperclip" onClick={() => inputRef.current.click()}></i>
          <input ref={inputRef} type="file" style={{ display: 'hidden' }} onChange={handleFileChange} />
        </Button>
        <Button variant="outline-secondary" id="button-addon2" onClick={handleSubmit}>
          <i className="fa-solid fa-paper-plane"></i>
        </Button>
      </InputGroup>
      <p className="text-danger">{errors.attachment || errors.message || ''}</p>
    </Fragment>
  );
};

export default TicketReply;
