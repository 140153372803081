import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import isEmpty from 'is-empty';
import { toast } from '../../lib/toast';
import { useDispatch } from 'react-redux';
import { getCategories, postSupportTicket } from '../../api/support.js';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

const initialValues = {
  category: '',
  email: '',
  message: '',
  attachment: ''
};

const validationSchema = yup.object({
  category: yup.string().length(24, 'Invalid ticket type').required('Ticket type is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  message: yup
    .string()
    .min(10, 'Message should have minimum 10 characters')
    .max(1000, 'Message can have maximum of 1000 characters')
    .notRequired()
});

const TicketCreate = ({ fetchAllTickets }) => {
  const dispatch = useDispatch();
  const [issueType, setIssueType] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmitSupport = useCallback(
    async (values, { setErrors, setStatus, resetForm }) => {
      setLoading(true);
      try {
        const formData = new FormData();

        formData.append('category', values.category);
        formData.append('email', values.email);
        formData.append('message', values.message);
        formData.append('attachment', values.attachment);

        const { success, message, errors } = await postSupportTicket(formData);

        if (success) {
          toast({ message, type: 'success' }, dispatch);
          resetForm();
          setLoading(false);
          fetchAllTickets();
        } else {
          setLoading(false);
          errors && setErrors(errors);
          message && toast({ message, type: 'error' }, dispatch);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        toast({ message: 'Some error occurred', type: 'error' }, dispatch);
      }
    },
    [dispatch, fetchAllTickets]
  );

  const { values, status, touched, handleSubmit, handleChange, errors, resetForm, setValues, setErrors } = useFormik({
    initialValues,
    onSubmit: handleSubmitSupport,
    validationSchema
  });

  const fetchCategories = useCallback(async () => {
    try {
      const { success, message, result } = await getCategories();
      if (success) {
        setIssueType(result);
      } else {
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [dispatch]);

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    console.log(file);
    if (!file.type.startsWith('image') && !file.type.startsWith('video')) {
      return setErrors({ attachment: 'Invalid file format' });
    } else if (file.size > 5 * 1024 * 1024) {
      return setErrors({ attachment: 'Maximum file size is 5MB' });
    }
    setValues((prev) => ({ ...prev, attachment: e.target.files[0] }));
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
  return (
    <div className="row">
      <div className="col-xl-8 col-lg-8">
        <Form>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3" controlId="formBank">
                <Form.Select defaultValue="Type" className="primary_select" name="category" value={values.category} onChange={handleChange}>
                  {isEmpty(issueType) ? (
                    <option value={''}>No Ticket Type Available</option>
                  ) : (
                    <>
                      <option value={''}>Select Ticket Type</option>
                      {issueType.map((issue) => (
                        <option value={issue._id}>{issue.category}</option>
                      ))}
                    </>
                  )}
                </Form.Select>
                <p className="text-danger">{(touched.category && errors?.category) || ''}</p>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group controlId="formGridEmail" className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  className="primary_input"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                <p className="text-danger">{(touched.email && errors?.email) || ''}</p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Form.Group controlId="formGridManufacturing" className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Message"
                  className="primary_input"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                />
                <p className="text-danger">{(touched.message && errors?.message) || ''}</p>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Form.Group className="mb-3 pos_rel" controlId="formBank">
                <Form.Control
                  type="text"
                  placeholder="Add Attachment (Max File size 5MB), Accepted Formats: jpeg, jpg, png, webp, mp4, mov, 3gp, vga"
                  className="primary_input"
                  value={values.attachment?.name || values.attachment}
                />
                <Form.Control type="file" className="primary_input" name="attachment" onChange={handleFileChange} />
                <p className="text-danger">{errors?.attachment || ''}</p>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={6} className="mb-3">
              <Button className="border_btn" onClick={resetForm}>
                Cancel
              </Button>
            </Col>
            <Col lg={6} className="mb-3">
              <Button className="primary_btn" variant="contained" onClick={handleSubmit} disabled={loading}>
                {loading ? <Spinner size="sm" /> : 'Submit'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="col-xl-4 col-lg-4">
        <div className="dashtitle_flex mb-3">
          <h2 className="dash_inner_title dash_inner_title_small">Notes:</h2>
        </div>
        <p className="support_note">
          Please provide any details that can help us understand your issue and our customer service staff will get back to you as soon as
          possiblie. An email with possible solutions will be sent to your email address.
        </p>
      </div>
    </div>
  );
};

export default TicketCreate;
