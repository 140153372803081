import axios from './AxiosService';

import config from '../config'
import paramsEncoder from '../lib/paramEncoder';
import { getAuthToken } from '../lib/localStorage';

axios.defaults.baseURL = config.API_URL;
axios.defaults.paramsSerializer = paramsEncoder;
axios.defaults.headers.common['TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;


export const handleResponse = (responseData, type = 'success', doc = false) => {
  try {
    if (doc && type === 'success' && responseData && responseData.data) {
      return { data: responseData.data };
    }
    if (type === 'success' && responseData && responseData.data) {
      return responseData.data;
    } else if (type === 'error' && responseData && responseData.response && responseData.response.data) {
      return responseData.response.data;
    } else {
      return {
        success: false,
        message: 'Something went wrong'
      };
    }
  } catch (error) {
    return {
      success: false,
      message: 'Something went wrong'
    };
  }
};

export default axios;
