import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Button, capitalize } from '@mui/material';

import { toast } from '../lib/toast';
import { cancelQuote, getQuote } from '../api/payment';
import { SocketContext } from '../context/SocketContext';
import DataTable from 'react-data-table-component';
const trx = [
  {
    _id: '665efa5b91310c2fa4620468',
    userId: '659e0fe6fd01c1f4852ddf28',
    userCode: '86892328',
    aircraftId: null,
    intentId: '',
    quoteId: 'SFO2847BLQUTO',
    quoteAircraftId: 'LUFT1712L183',
    currencyId: '659fcd6962c7ec1831cf7712',
    symbol: 'ETH',
    isToken: false,
    tokenType: '',
    transactionHash: '0x3e79478611960c8db8bf88ea12f06f092b4be431eab4653b20e70d7612a59f94',
    address: '0x9CF2aC121221ceFFc171A608317F6F9e32D46B6c',
    privateKey: '',
    from: '0x161573a4bea31fbd5621ed791f802b09fa14923d',
    amount: 0.01,
    conversionAmount: 37.645,
    status: 'canceled',
    transactionType: 'quote',
    moveToAdmin: 'completed',
    createdAt: '2024-06-04T11:28:27.682Z',
    updatedAt: '2024-06-04T11:58:00.812Z',
    __v: 0
  }
];

const shortenTransactionHash = (hash) => {
  if (!hash || hash.length <= 6) return hash; // If hash is null or shorter than 6 characters, return as is
  const firstHalf = hash.substring(0, 6);
  const lastHalf = hash.substring(hash.length - 6);
  return `${firstHalf}...${lastHalf}`;
};

const columns = [
  {
    name: 'Paid On',
    selector: (row) => (row.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : '-'),
    sortable: false
    // width: '150px'
  },
  {
    name: 'From Address',
    selector: (row) => shortenTransactionHash(row.from),
    sortable: false
    // width: '200px'
  },
  {
    name: 'Transaction Hash',
    selector: (row) => shortenTransactionHash(row.transactionHash),
    sortable: false
    // width: '400px'
  },
  {
    name: 'Amount',
    selector: (row) => row.amount,
    sortable: false
    // width: '200px'
  }
];

const QuoteStatus = () => {
  const dispatch = useDispatch();

  const { quoteIntent } = useParams();
  const navigate = useNavigate();

  const socket = useContext(SocketContext).socket;

  const [payment, setPayment] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('load');
  const [expiresin, setExpires] = useState('');
  const [expiration, setExpiration] = useState('');

  const auth = useSelector((state) => state.auth);
  const fetchPayment = useCallback(async () => {
    try {
      setLoading(true);
      const { success, result, message } = await getQuote(quoteIntent);
      if (success) {
        setPayment(result);
        setLoading(false);
        setStatus(
          result.status === 'open' || result.status === 'pending' ? 'interface' : result.status === 'completed' ? 'success' : 'failed'
        );
        setExpiration(result.expirationTime);
      } else {
        toast({ message, type: 'error' }, dispatch);
        setLoading(false);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
      setLoading(false);
    }
  }, [dispatch, quoteIntent]);

  useEffect(() => {
    fetchPayment();
  }, [fetchPayment]);

  useEffect(() => {
    let timerId;
    if (expiration && payment?.status !== 'completed' && payment?.status !== 'canceled') {
      timerId = setInterval(() => {
        setExpires(() => {
          let timeRemain = new Date(expiration).getTime() - Date.now();
          if (timeRemain <= 0) {
            if (payment?.status !== 'completed') {
              fetchPayment()
                .then(() => {
                  setStatus('failed');
                })
                .catch(() => {
                  setStatus('failed');
                });
            }
            return '00:00:00';
          }
          let seconds = Math.floor(timeRemain / 1000);
          let timer = `00:${String(Math.floor(seconds / 60))}:${seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}`;
          return timer;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [expiration, fetchPayment, payment?.status]);

  const handleCopy = (text) => {
    if (!navigator.clipboard) {
      toast({ message: 'Cannot copy to clipboard', type: 'error' }, dispatch);
    } else {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast({ message: 'Copied to clipboard successfully', type: 'success' }, dispatch);
        })
        .catch(() => {
          toast({ message: 'Cannot copy to clipboard', type: 'error' }, dispatch);
        });
    }
  };

  const handleCancel = async () => {
    const { success, message } = await cancelQuote({ quoteNumber: payment.quotationId });
    if (success) {
      toast({ message, type: 'success' }, dispatch);
      fetchPayment();
    } else {
      toast({ message: 'something went wrong', type: 'error' }, dispatch);
    }
  };
  useEffect(() => {
    socket.on(quoteIntent, async (data) => {
      setPayment((prev) => ({ ...prev, receivedAmount: data.receivedAmount }));
      if (data.paymentStatus === 'completed' || data.paymentStatus === 'canceled') {
        await fetchPayment();
      }
      setStatus(data.status);
      toast({ message: data.message, type: data.success ? 'success' : 'error' }, dispatch);
    });

    return () => socket.off(quoteIntent);
  }, [socket, quoteIntent, dispatch, fetchPayment]);

  return (
    <div className="container">
      <div className="ps-contain quote_status_report">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            {status === 'interface' ? (
              <Row>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Phone Number
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      +{payment?.countryCode} {payment?.phoneNumber}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Payment Intent ID
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {quoteIntent}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Client
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.client}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Operator
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.operatorName}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Airline ID
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.aircraftId}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Flight Date
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {moment(payment.flightDate).format('DD-MM-YYYY')}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Origin
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.from}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Destination
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.to}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Payment Currency
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.symbol}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Payment Network
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.isToken
                        ? payment.tokenType === 'ERC20'
                          ? 'Ethereum'
                          : 'Tron Network'
                        : payment.symbol === 'ETH'
                          ? 'Ethereum'
                          : payment.symbol === 'BTC'
                            ? 'Bitcoin'
                            : 'Tron Network'}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Amount
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.networkAmount + payment.networkFee} {payment.symbol} ( {payment.amount + payment.fee} USD )
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Received Amount ({payment.symbol})
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.receivedAmount} {payment.symbol}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Pending Amount ({payment.symbol})
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {payment.networkAmount + payment.networkFee - payment.receivedAmount} {payment.symbol}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Receiving Address
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end align-items-center">
                      <button className="ps-copy-btn" onClick={() => handleCopy(payment.address)}>
                        {payment.address}
                      </button>{' '}
                      <i className={`ms-3 fa fa-copy`} onClick={() => handleCopy(payment.address)} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Payment Intent Created On
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {moment(payment.createdAt).format('DD MMM YYYY hh:mm:ss A')}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Payment QR Code (Scan this QR in your wallet to pay)
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      <img src={payment.paymentQR} alt="paymentQR" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Payment portal expires in
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {expiresin && expiresin}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="w-100">
                    <Col xs={12} md={6}>
                      Payment Status
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-end">
                      {capitalize(payment.status)}
                    </Col>
                  </Row>
                </Col>
                {auth.isAuth ? (
                  <Fragment>
                    <Col xs={4}>
                      <Button className="border_btn ps-pad-right" onClick={() => navigate('/transactions', { replace: true })}>
                        Go Back
                      </Button>
                    </Col>
                    <Col xs={4}>
                      <Button className="border_btn ps-pad-right" onClick={() => handleCopy(window.location.href)}>
                        Share Payment Page
                      </Button>
                    </Col>
                    {payment && payment?.status == 'open' && (
                      <Col xs={4}>
                        <Button className="border_btn ps-pad-right" onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Fragment>
                ) : (
                  <Col xs={12}>
                    <Button className="border_btn ps-pad-right" onClick={() => handleCopy(window.location.href)}>
                      Share Payment Page
                    </Button>
                  </Col>
                )}
              </Row>
            ) : status === 'success' || status === 'failed' ? (
              <div>
                <Row>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Payment Intent ID
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {quoteIntent}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Client
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.client}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Operator
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.operatorName}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Airline ID
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.aircraftId}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Flight Date
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {moment(payment.flightDate).format('DD-MM-YYYY')}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Origin
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.from}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Destination
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.to}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Payment Currency
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.symbol}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Payment Network
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.isToken
                          ? payment.tokenType === 'ERC20'
                            ? 'Ethereum'
                            : 'Tron Network'
                          : payment.symbol === 'ETH'
                            ? 'Ethereum'
                            : payment.symbol === 'BTC'
                              ? 'Bitcoin'
                              : 'Tron Network'}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Amount
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.networkAmount + payment.networkFee} {payment.symbol} ( {payment.amount + payment.fee} USD )
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Received Amount ({payment.symbol})
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.receivedAmount} {payment.symbol}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Pending Amount ({payment.symbol})
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {payment.networkAmount + payment.networkFee - payment.receivedAmount} {payment.symbol}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Receiving Address
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end align-items-center">
                        <button className="ps-copy-btn" onClick={() => handleCopy(payment.address)}>
                          {payment.address}
                        </button>{' '}
                        <i className={`ms-3 fa fa-copy`} onClick={() => handleCopy(payment.address)} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Payment Intent Created On
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {moment(payment.createdAt).format('DD MMM YYYY hh:mm:ss A')}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Payment Intent Expired On
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {moment(payment.expirationTime).format('DD MMM YYYY hh:mm:ss A')}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="w-100">
                      <Col xs={12} md={6}>
                        Payment Status
                      </Col>
                      <Col xs={12} md={6} className="d-flex justify-content-end">
                        {capitalize(payment.status)}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <DataTable columns={columns} data={payment?.transactions || []} theme="dark" />
                  </Col>
                  {auth.isAuth ? (
                    <Fragment>
                      <Col xs={6}>
                        <Button className="border_btn ps-pad-right" onClick={() => navigate('/transactions', { replace: true })}>
                          Go Back
                        </Button>
                      </Col>
                      <Col xs={6}>
                        <Button className="border_btn ps-pad-right" onClick={() => handleCopy(window.location.href)}>
                          Share Payment Page
                        </Button>
                      </Col>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Col xs={12}>
                        <Button className="border_btn ps-pad-right" onClick={() => handleCopy(window.location.href)}>
                          Share Payment Page
                        </Button>
                      </Col>
                    </Fragment>
                  )}
                </Row>
              </div>
            ) : (
              <div>
                <Spinner />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default QuoteStatus;
