import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import $ from 'jquery';

export default function Navbar(props) {
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(document).ready(function () {
      $('.close_icon').click(function () {
        $('.app_sidebar').removeClass('show');
        $('body').removeClass('menu_overlay');
      });
    });
  }

  return (
    <>
      <div className="app_sidebar">
        <i className="fa-solid fa-xmark close_icon"></i>
        <div className="app_sidebar_logo">
          <Link to="/">
            <img src={logo} alt="Logo" className="img-fluid brand_logo" />
          </Link>
        </div>
        <div className="app_sidebar_menu">
          <ul className="nav nav-pills flex-column mb-auto sidebar_menu" id="sidemenu">
            <li className="nav-item">
              <NavLink to="/dashboard" className="nav-link">
                <img
                  src={require("../assets/images/menu_icon_01.png")}
                  alt="Icon"
                />
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/transactions" className="nav-link">
                <img
                  src={require("../assets/images/menu_icon_02.png")}
                  alt="Icon"
                />
                Transactions
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/wallet" className="nav-link">
                <img
                  src={require("../assets/images/menu_icon_03.png")}
                  alt="Icon"
                />
                Wallet
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/profile" className="nav-link">
                <img
                  src={require("../assets/images/menu_icon_04.png")}
                  alt="Icon"
                />
                Profile
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/aircraft-sales" className="nav-link">
                <img
                  src={require("../assets/images/menu_icon_05.png")}
                  alt="Icon"
                />
                Aircraft Sales
              </NavLink>
            </li>
          </ul>
          <ul className="nav nav-pills flex-column sidebar_menu">
            <li className="nav-item">
              <NavLink to="/support" className="nav-link">
                <img
                  src={require("../assets/images/menu_icon_06.png")}
                  alt="Icon"
                />
                Support
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/logout" className="nav-link">
                <img
                  src={require("../assets/images/menu_icon_07.png")}
                  alt="Icon"
                />
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
