import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import findPercentInAssets from './util/findPercentInAssets';
import findConvertedAsset from './util/findConvertedAsset';
import findChartData from './util/findChartData';
import totalAsset from './util/totalAsset';
import Withdraw from '../wallet/Modals/withdrawModal';

export default function DigitalAssets(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const wallet = useSelector((state) => state.wallet);
  const currency = useSelector((state) => state.currency);
  const account = useSelector((state) => state.account);

  const { assets } = wallet;

  const chartData = {
    series: findChartData(assets, currency),
    labels: ['BTC', 'ETH', 'TRX', 'USDC-ERC20', 'USDT-ERC20', 'USDC-TRC20', 'USDT-TRC20']
  };

  const chartOptions = {
    chart: {
      type: 'donut'
    },
    labels: chartData.labels,
    stroke: {
      width: '0'
    },
    legend: {
      show: false,
      labels: {
        colors: '#fff',
        useSeriesColors: false
      }
    },
    fill: {
      colors: ['#83FAE3', '#FEE083', '#FE70B8', '#7EF37D', '#A67DF3', '#FFC14D', '#FF2E51']
    },
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: '24px',
              fontFamily: 'Outfit',
              fontWeight: 700,
              color: '#ffffff',
              offsetY: 5
            },
            total: {
              show: true,
              fontSize: '18px',
              fontFamily: 'Outfit',
              fontWeight: 300,
              color: '#ffffff',
              showAlways: true,
              label: 'Total Revenue',
              formatter: () => `$ ${parseFloat(wallet?.totalInbound?.toFixed(3)).toLocaleString('en-US')}`
            }
          }
        }
      }
    },
    responsive: [
      {
        breakpoint: 1199,
        options: {
          chart: {
            width: 320
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    fontSize: '18px',
                    offsetY: -3
                  },
                  total: {
                    fontSize: '16px'
                  }
                }
              }
            }
          }
        }
      },
      {
        breakpoint: 1199,
        options: {
          chart: {
            width: 300
          }
        }
      }
    ]
  };

  return (
    <>
      <div className="traffic_chart_flex">
        <ReactApexChart options={chartOptions} series={chartData.series} type="donut" width={400} />
        <div className="asset_chart_info">
          <h2>Type of Digital Asset Received</h2>
          <ul>
            <li>
              <p>
                BTC <span>${findConvertedAsset('BTC', assets, currency)}</span>
              </p>
              <label>
                {assets?.find((item) => item?.currencySymbol === 'BTC')?.balance?.toFixed(4) || '0.00'} | {findPercentInAssets('BTC', assets, currency)}
                %
              </label>
            </li>
            <li>
              <p>
                ETH <span>${findConvertedAsset('ETH', assets, currency)}</span>
              </p>
              <label>
                {assets?.find((item) => item?.currencySymbol === 'ETH')?.balance?.toFixed(4) || '0.00'} | {findPercentInAssets('ETH', assets, currency)}
                %
              </label>
            </li>
            <li>
              <p>
                TRX <span>${findConvertedAsset('TRX', assets, currency)}</span>
              </p>
              <label>
                {assets?.find((item) => item?.currencySymbol === 'TRX')?.balance?.toFixed(4) || '0.00'} | {findPercentInAssets('TRX', assets, currency)}
                %
              </label>
            </li>
            <li>
              <p>
                USDC - ERC20 <span>${findConvertedAsset('USDC', assets, currency, 'ERC20')}</span>
              </p>
              <label>
                {assets?.find((item) => item?.currencySymbol === 'USDC' && item?.tokenType === 'ERC20')?.balance?.toFixed(4) || '0.00'} |{' '}
                {findPercentInAssets('USDC', assets, currency, 'ERC20')}%
              </label>
            </li>
            <li>
              <p>
                USDT - ERC20 <span>${findConvertedAsset('USDT', assets, currency, 'ERC20')}</span>
              </p>
              <label>
                {assets?.find((item) => item?.currencySymbol === 'USDT' && item?.tokenType === 'ERC20')?.balance?.toFixed(4) || '0.00'} |{' '}
                {findPercentInAssets('USDT', assets, currency, 'ERC20')}%
              </label>
            </li>
            <li>
              <p>
                USDC - TRC20 <span>${findConvertedAsset('USDC', assets, currency, 'TRC20')}</span>
              </p>
              <label>
                {assets?.find((item) => item?.currencySymbol === 'USDC' && item?.tokenType === 'TRC20')?.balance?.toFixed(4) || '0.00'} |{' '}
                {findPercentInAssets('USDC', assets, currency, 'TRC20')}%
              </label>
            </li>
            <li>
              <p>
                USDT - TRC20 <span>${findConvertedAsset('USDT', assets, currency, 'TRC20')}</span>
              </p>
              <label>
                {assets?.find((item) => item?.currencySymbol === 'USDT' && item?.tokenType === 'TRC20')?.balance?.toFixed(4) || '0.00'} |{' '}
                {findPercentInAssets('USDT', assets, currency, 'TRC20')}%
              </label>
            </li>
          </ul>
          <Button className="border_btn" onClick={handleShow}>
            Withdraw
          </Button>
        </div>
      </div>

      {/* Withdraw Modal */}
      <Withdraw showWithdraw={show} handleCloseWithdraw={handleClose} account={account} currencyData={currency} />
    </>
  );
}
