import React from 'react';
import { Form, Modal, Row, Col, InputGroup } from 'react-bootstrap';
import DataTable, { createTheme } from 'react-data-table-component';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import isEmpty from 'is-empty';

const BankDetails = ({
  currencyData,
  data,
  showAddBank,
  handleCloseAddBank,
  handleShowAddBank,
  updateBankDetailModal,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit
}) => {
  let { bankName, holderName, currency, accountNo, IFSC, country, city, bankAddress, isPrimary } = values;
  let prefCurrencies = Array.isArray(data) && !isEmpty(data) ? data.map((item) => item.currency) : [];

  return (
    <>
      <div className="account_details_box">
        <div className="text-end mb-2">
          {/* <img src={require('../../assets/images/edit_icon.png')} alt="Edit" className="edit_icon" /> */}
        </div>
        <div className="profile_inp_row">
          <label>Preferred Currency:</label>
          <input className="profile_inp" value={prefCurrencies.join(', ')} disabled />
        </div>
      </div>
      {Array.isArray(data) &&
        data.length > 0 &&
        data.map((item) => (
          <div className="account_details_box">
            <div className="text-end mb-2">
              <img
                onClick={() => updateBankDetailModal(item._id)}
                src={require('../../assets/images/edit_icon.png')}
                alt="Edit"
                className="edit_icon"
              />
            </div>
            <div class="dashtitle_flex mb-1">
              <h2 class="dash_inner_title">
                {item.bankName}{' '}
                {item.isPrimary && (
                  <span>
                    -<small className="green_text"> Primary</small>
                  </span>
                )}
              </h2>
            </div>
            <div className="profile_inp_row">
              <label>Acc Holder Name:</label>
              <input className="profile_inp" value={item.holderName} disabled />
            </div>
            <div className="profile_inp_row">
              <label>IBAN:</label>
              <input className="profile_inp" value={item.accountNo} disabled />
            </div>
            {/* <div className="profile_inp_row">
                                      <label>Account Type:</label>
                                      <input className="profile_inp" value="Savings" disabled />
                                    </div> */}
            <div className="profile_inp_row">
              <label>Swift/BIC:</label>
              <input className="profile_inp" value={item.IFSC} disabled />
            </div>
          </div>
        ))}
      <div className="account_details_box text-center cur_pointer" onClick={handleShowAddBank}>
        <span>Add Bank</span>
      </div>
      <Modal show={showAddBank} size="lg" onHide={handleCloseAddBank} centered backdrop="static" className="primary_modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formBank">
                  <Form.Select
                    value={currency}
                    name="currency"
                    onChange={handleChange}
                    defaultValue="Select Currency"
                    className="primary_select"
                  >
                    <option value="">Select Currency</option>
                    {/* <option>USD</option>
                    <option>INR</option> */}
                    {currencyData.length > 0 && currencyData.map((item) => <option value={item.symbol}>{item.symbol}</option>)}
                    <option value={'AED'}>{'AED'}</option>
                    <option value={'EUR'}>{'EUR'}</option>
                  </Form.Select>
                  <p className="text-danger">{(touched.currency && errors.currency) || ''}</p>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formGridName" className="mb-3">
                  <Form.Control
                    type="text"
                    value={holderName}
                    placeholder="Enter Account Holder Name"
                    onChange={handleChange}
                    className="primary_input"
                    name="holderName"
                  />
                  <p className="text-danger">{(touched.holderName && errors.holderName) || ''}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formGridBank" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter Bank Name"
                    value={bankName}
                    className="primary_input"
                    onChange={handleChange}
                    name="bankName"
                  />
                  <p className="text-danger">{(touched.bankName && errors.bankName) || ''}</p>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formGridNumber" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter IBAN"
                    name="accountNo"
                    onChange={handleChange}
                    value={accountNo}
                    className="primary_input"
                  />
                  <p className="text-danger">{(touched.accountNo && errors.accountNo) || ''}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formGridifsc" className="mb-3">
                  <Form.Control
                    name="IFSC"
                    value={IFSC}
                    onChange={handleChange}
                    type="text"
                    placeholder="Swift/BIC"
                    className="primary_input"
                  />
                  <p className="text-danger">{(touched.IFSC && errors.IFSC) || ''}</p>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formGridcountry" className="mb-3">
                  <Form.Control
                    value={country}
                    onChange={handleChange}
                    name="country"
                    type="text"
                    placeholder="Enter Bank Country"
                    className="primary_input"
                  />
                  <p className="text-danger">{(touched.country && errors.country) || ''}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formGridCity" className="mb-3">
                  <Form.Control
                    value={city}
                    name="city"
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter Bank City"
                    className="primary_input"
                  />
                  <p className="text-danger">{(touched.city && errors.city) || ''}</p>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formGridAddress" className="mb-3">
                  <Form.Control
                    value={bankAddress}
                    name="bankAddress"
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter Bank address"
                    className="primary_input"
                  />
                  <p className="text-danger">{(touched.bankAddress && errors.bankAddress) || ''}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div class="form-check custom_formcheck">
                  <input
                    value={isPrimary}
                    onChange={handleChange}
                    name="isPrimary"
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={isPrimary}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Set as Primary
                  </label>
                </div>
                <p className="text-danger">{(touched.isPrimary && errors.isPrimary) || ''}</p>
              </Col>
            </Row>
            <div className="asset_btn_grp">
              <Button type="button" className="border_btn" variant="contained" onClick={handleCloseAddBank}>
                Cancel
              </Button>
              <Button type="submit" className="primary_btn" variant="contained">
                Confirm
              </Button>
            </div>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
};

export default BankDetails;
