import React, { Fragment, useEffect, useRef, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import DataTable, { createTheme } from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import AccountDetails from '../components/profile/AccountDetails.js';
import CompanyDetails from '../components/profile/CompanyDetails.js';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from '../lib/toast.js';
import { addBankDetails, fetchUserProfile, getBankDetails, updateBankDetails, updateProfilePicture } from '../api/user.js';
import useSWR from 'swr';
import isEmpty from 'is-empty';
import BankDetails from '../components/profile/BankDetails.js';
import { getFiatCurrency } from '../api/currency.js';
import config from '../config';
import WalletDetails from '../components/profile/WalletDetails.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const columns = [
  {
    name: 'Date',
    selector: (row) => row.date,
    sortable: true
  },
  {
    name: 'IP Address',
    selector: (row) => row.ip,
    sortable: true
  },
  {
    name: 'Device',
    selector: (row) => row.device,
    sortable: true
  },
  {
    name: 'Location',
    selector: (row) => row.location,
    sortable: true
  }
];

const data = [
  {
    date: '08-01-2024 12:27',
    ip: '115.246.231.139',
    device: 'Windows 11',
    location: 'India'
  },
  {
    date: '08-01-2024 12:27',
    ip: '115.246.231.139',
    device: 'Windows 11',
    location: 'India'
  },
  {
    date: '08-01-2024 12:27',
    ip: '115.246.231.139',
    device: 'Windows 11',
    location: 'India'
  },
  {
    date: '08-01-2024 12:27',
    ip: '115.246.231.139',
    device: 'Windows 11',
    location: 'India'
  },
  {
    date: '08-01-2024 12:27',
    ip: '115.246.231.139',
    device: 'Windows 11',
    location: 'India'
  },
  {
    date: '08-01-2024 12:27',
    ip: '115.246.231.139',
    device: 'Windows 11',
    location: 'India'
  }
];
let initialFormValue = {
  bankName: '',
  holderName: '',
  currency: '',
  accountNo: '',
  IFSC: '',
  country: '',
  city: '',
  bankAddress: '',
  isPrimary: false,
  _id: ''
};
const validationSchema = yup.object({
  bankName: yup.string().required('Bank Name is required'),
  holderName: yup.string().required('Holder Name is required'),
  currency: yup.string().required('Currency is required'),
  accountNo: yup.string().required('Account Number is required'),
  IFSC: yup.string().required('IFSC Code is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  bankAddress: yup.string().required('Bank Address is required')
  // isPrimary: yup.boolean().oneOf([true], 'Primary status must be true') // Ensure isPrimary is always true
});
export default function Profile(props) {
  // States
  const { account, currency } = useSelector((state) => state);

  const [showAddBank, setShowAddBank] = useState(false);
  const [edit, setEdit] = useState(false);
  const [fiatData, setFiatData] = useState([]);
  const [profile, setProfile] = useState('');
  const picRef = useRef(null);

  const dispatch = useDispatch();
  const { data, error, mutate } = useSWR('bankDetail', getBankDetail);

  const { values, errors, status, touched, handleSubmit, handleChange, setValues, setErrors, resetForm } = useFormik({
    initialValues: initialFormValue,
    validationSchema,
    onSubmit: edit ? updateBankDetail : addBankDetail
  });
  const handleCloseAddBank = () => setShowAddBank(false);
  const handleShowAddBank = () => {
    setErrors({});
    setShowAddBank(true);
  };

  // Add Bank TFA

  async function addBankDetail() {
    try {
      const { success, message, errors } = await addBankDetails(values);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        setShowAddBank(false);
        mutate(false, true);
        resetForm();
      } else {
        errors && setErrors(errors)
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Something went wrong', type: 'error' }, dispatch);
      console.log('UPDATE_COMPANY_DETAILS_ERROR', error);
    }
  }
  async function updateBankDetail() {
    try {
      const { success, message } = await updateBankDetails(values);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        mutate(false, true);
        resetForm();
        setShowAddBank(false);
        setEdit(false);
      } else {
        errors && setErrors(errors)
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Something went wrong', type: 'error' }, dispatch);
      console.log('UPDATE_COMPANY_DETAILS_ERROR', error);
    }
  }
  async function updateProfilePic(profilePic) {
    try {
      let formData = new FormData();
      formData.append('profilePic', profilePic);

      const { success, message } = await updateProfilePicture(formData, dispatch);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
      } else {
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Something went wrong', type: 'error' }, dispatch);
      console.log('UPDATE_COMPANY_DETAILS_ERROR', error);
    }
  }
  async function getBankDetail() {
    try {
      const { success, message, result } = await getBankDetails();
      if (success) {
        return result;
      } else {
        return [];
      }
    } catch (error) {
      console.log('UPDATE_COMPANY_DETAILS_ERROR', error);
      return [];
    }
  }
  const updateBankDetailModal = async (id) => {
    setShowAddBank(true);
    setEdit(true);
    let bankDetail = {};
    if (Array.isArray(data) && data.length > 0) {
      bankDetail = data.find((el) => el._id === id);
    }
    if (!isEmpty(bankDetail)) {
      setValues(bankDetail);
    }
  };
  const getFiatCurrencyData = async () => {
    try {
      const { success, message, result } = await getFiatCurrency();
      if (success) {
        setFiatData(result);
      }
    } catch (error) {}
  };
  // For Datatable Starts
  createTheme(
    'dark',
    {
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(0,0,0,.12)'
      },
      background: {
        default: '#1A1E27'
      },
      context: {
        background: '#1A1E27',
        text: '#DA1818'
      },
      divider: {
        default: 'rgb(22 21 21)'
      },
      button: {
        default: '#FFFFFF',
        focus: 'rgba(255, 255, 255, .54)',
        hover: 'rgba(255, 255, 255, .12)',
        disabled: 'rgba(255, 255, 255, .18)'
      },
      selected: {
        default: 'rgba(0, 0, 0, .7)',
        text: '#FFFFFF'
      },
      highlightOnHover: {
        default: 'rgba(0, 0, 0, .7)',
        text: '#FFFFFF'
      },
      striped: {
        default: 'rgba(0, 0, 0, .87)',
        text: '#FFFFFF'
      }
    },
    'dark'
  );
  // For Datatable Ends
  useEffect(() => {
    getFiatCurrencyData();
  }, []);

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row py-3">
                  <div className="col-lg-12">
                    <div className="dashbox">
                      <div className="profile_top">
                        <div className="text-center">
                          {/* <img src={require('../assets/images/user_img.png')} alt="Profile" className="profile_img img-fluid" /> */}
                          <div className="user_details">
                            {!account.profileImage ? (
                              // <span onClick={() => picRef.current.click()} style={{ cursor: 'pointer' }}>
                              //   {account.userName[0]}
                              // </span>
                              <div onClick={() => picRef.current.click()} style={{ cursor: 'pointer' }}>
                                <img
                                  width={'100px'}
                                  height={'100px'}
                                  style={{ borderRadius: '9999px' }}
                                  src={require('../assets/images/user_placeholder.png')}
                                  alt="profile_image"
                                />
                              </div>
                            ) : (
                              <div onClick={() => picRef.current.click()} style={{ cursor: 'pointer' }}>
                                <img
                                  width={'100px'}
                                  height={'100px'}
                                  style={{ borderRadius: '9999px' }}
                                  src={`${config.API_URL}/${account.profileImage}`}
                                  alt="profile_image"
                                />
                              </div>
                            )}
                            <div>
                              <input
                                type="file"
                                hidden
                                name="profilePic"
                                accept="image/*"
                                ref={picRef}
                                onChange={(e) => {
                                  updateProfilePic(e.target.files[0]);
                                }}
                              />
                            </div>
                          </div>
                          <small className='really-small-font blue_text'>Formats JPG / PNG / WEBP</small>
                          <h3>{account.userName || 'Please update your name'}</h3>
                        </div>
                        <div className="profile_data">
                          <p>
                            User ID: <span>{account.userId}</span>
                          </p>
                          <p>
                            Email: <span>{account.email}</span>
                          </p>
                          <p>
                            Mobile Number: <span>{account.phoneNumber}</span>
                          </p>
                          <p>{/* D O B: <span>01/01/1989</span> */}</p>
                        </div>
                      </div>
                    </div>

                    <div className="dashbox dashbox_dark dashbox_nopad">
                      <div className="account_details_panel">
                        <div className="account_details_left">
                          <div
                            class="nav flex-column nav-pills custom_vertical_tab"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <button
                              class="nav-link active"
                              id="v-pills-company-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-company"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-company"
                              aria-selected="true"
                            >
                              Company Details
                            </button>
                            <button
                              class="nav-link"
                              id="v-pills-account-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-account"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-account"
                              aria-selected="false"
                            >
                              Account Details
                            </button>
                            <button
                              class="nav-link"
                              id="v-pills-bank-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-bank"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-bank"
                              aria-selected="false"
                            >
                              Bank Details
                            </button>
                            <button
                              class="nav-link"
                              id="v-pills-wallet-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-wallet"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-wallet"
                              aria-selected="false"
                            >
                              Wallet Details
                            </button>
                          </div>
                        </div>
                        <div className="account_details_right">
                          <div class="tab-content" id="v-pills-tabContent">
                            <div
                              class="tab-pane fade show active"
                              id="v-pills-company"
                              role="tabpanel"
                              aria-labelledby="v-pills-company-tab"
                              tabindex="0"
                            >
                              <CompanyDetails />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-account"
                              role="tabpanel"
                              aria-labelledby="v-pills-account-tab"
                              tabindex="0"
                            >
                              <AccountDetails />
                            </div>
                            <div class="tab-pane fade" id="v-pills-bank" role="tabpanel" aria-labelledby="v-pills-bank-tab" tabindex="0">
                              <BankDetails
                                currencyData={fiatData}
                                updateBankDetailModal={updateBankDetailModal}
                                handleShowAddBank={handleShowAddBank}
                                data={data}
                                touched={touched}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                                values={values}
                                errors={errors}
                                showAddBank={showAddBank}
                                handleCloseAddBank={handleCloseAddBank}
                              />
                            </div>
                            <div
                              class="tab-pane fade"
                              id="v-pills-wallet"
                              role="tabpanel"
                              aria-labelledby="v-pills-wallet-tab"
                              tabindex="0"
                            >
                              <WalletDetails />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Change Password Modal */}

      {/* Login History Modal */}

      {/* Enable TFA Modal */}

      {/* Disable TFA Modal */}

      {/* Add Bank Modal */}
    </>
  );
}
