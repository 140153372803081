import React, { useCallback, useEffect, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import { toast } from '../lib/toast';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { EMAIL, ISALNUMHYP, ISALNUMHYPCASE, PHONE, PRICE } from '../constant/regex.js';
import { createAircraft, getSingleAircraft } from '../api/aircraft.js';
import createFormData from '../lib/createFormData.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialValues = {
  name: '',
  id: '',
  price: '',
  yearOfManufacturing: '',
  serialNumber: '',
  registration: '',
  engine1: '',
  engine2: '',
  engine3: '',
  engine4: '',
  apu: '',
  pocName: '',
  pocEmail: '',
  pocPhoneNumber: '',
  pocPhoneNumber2: '',
  esName: '',
  esEmail: '',
  esPhoneNumber: '',
  esPhoneNumber2: '',
  buyer: '',
  kycDoc1: '',
  kycDoc2: '',
  kycDoc3: ''
};

const validationSchema = yup.object({
  name: yup
    .string()
    .min(5, 'Name should be more than 5 characters')
    .max(100, 'Name cannot be more than 100 characters')
    .matches(ISALNUMHYPCASE, { message: 'Name contains invalid characters' })
    .required('Name is required'),
  id: yup
    .string()
    .min(4, 'Aircraft ID should be more than 4 characters')
    .max(20, 'Aircraft ID should be more than 20 characters')
    .matches(ISALNUMHYP, { message: 'Aircraft ID can only have uppercase alpha-numeric characters' })
    .required('Aircraft ID is required'),
  price: yup.string().matches(PRICE, { message: 'Price contains invalid characters' }).required('Price is required'),
  serialNumber: yup
    .string()
    .matches(ISALNUMHYP, { message: 'Serial Number can have only Uppercase Alpha-Numeric characters with "-" & "_"' })
    .required('Serial Number is required'),
  registration: yup.string().required('Resgistration is required'),
  engine1: yup.string().required('Engine 1 is required'),
  pocName: yup
    .string()
    .matches(ISALNUMHYPCASE, { message: "Point of Contract's name contains invalid characters" })
    .min(5, 'Should be more than 5 characters')
    .max(100, 'Should not be more than 100 characters')
    .required('Point of Contact name is required'),
  pocEmail: yup.string().email('Invalid email').matches(EMAIL, { message: 'Invalid email' }).required('Email is required'),
  pocPhoneNumber: yup
    .string()
    .matches(PHONE, { message: 'Invalid phone number, include country code with + sign in the front' })
    .required('Phone Number is required'),
  esName: yup
    .string()
    .matches(ISALNUMHYPCASE, { message: 'Escrow name contains invalid characters' })
    .min(5, 'Should be more than 5 characters')
    .max(100, 'Should not be more than 100 characters')
    .required('Escrow name is required'),
  esEmail: yup.string().email('Invalid email').matches(EMAIL, { message: 'Invalid email' }).required('Email is required'),
  esPhoneNumber: yup
    .string()
    .matches(PHONE, { message: 'Invalid phone number, include country code with + sign in the front' })
    .required('Phone Number is required'),
  buyer: yup
    .string()
    .matches(ISALNUMHYPCASE, { message: 'Buyer name contains invalid characters' })
    .min(5, 'Should be more than 5 characters')
    .max(100, 'Should not be more than 100 characters')
    .required('Buyer name is required')
});

export default function AddAircraft(props) {
  const { aircraftId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [edit] = useState(!!aircraftId);
  const { values, errors, touched, handleChange, handleSubmit, setErrors, setValues, resetForm } = useFormik({
    initialValues,
    onSubmit: handleAircraftSubmit,
    validationSchema
  });

  async function handleAircraftSubmit(values, { setStatus, setErrors, resetForm }) {
    try {
      const refinedValues = createFormData(values);
      refinedValues.append('isSaveLater', false);
      refinedValues.append('isUpdate', edit)

      const { success, message, errors } = await createAircraft(refinedValues);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        resetForm();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } else {
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }

  async function handleSaveLater() {
    try {
      const refinedValues = createFormData(values);
      refinedValues.append('isSaveLater', true);
      refinedValues.append('isUpdate', edit)

      const { success, message, errors } = await createAircraft(refinedValues);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        resetForm();
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      } else {
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }

  const handleFileChange = (e, field) => {
    let file = e.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      return setErrors({ [field]: 'Maximum file size is 5MB' });
    }
    setValues((prev) => ({ ...prev, [field]: e.target.files[0] }));
  };

  const fetchSingleAircraft = useCallback(async () => {
    try {
      const { success, message, result } = await getSingleAircraft(aircraftId);
      if (success) {
        setValues(result);
      } else {
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [aircraftId, setValues, dispatch]);

  useEffect(() => {
    if (aircraftId) {
      fetchSingleAircraft();
    }
  }, [fetchSingleAircraft, aircraftId]);

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row py-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <div className="dashtitle_flex mb-3 justify-content-center">
                        <h2 className="dash_inner_title">Add an Aircraft for Sale</h2>
                      </div>
                      <div className="row">
                        <div className="col-xl-8 col-lg-10 m-auto">
                          <Form>
                            <Row>
                              <Col lg={12}>
                                <Form.Group controlId="formGridName" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Aircraft Name"
                                    className="primary_input"
                                    onChange={handleChange}
                                    name="name"
                                    value={values.name}
                                  />
                                  <p className="text-danger">{(touched.name && errors?.name) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridID" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Aircraft ID"
                                    className="primary_input"
                                    onChange={handleChange}
                                    name="id"
                                    value={values.id}
                                  />
                                  <p className="text-danger">{(touched.id && errors?.id) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group controlId="formGridPrice" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Aircraft Price"
                                    className="primary_input"
                                    onChange={handleChange}
                                    name="price"
                                    value={values.price}
                                  />
                                  <p className="text-danger">{(touched.price && errors?.price) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridManufacturing" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Year Of Manufacturing"
                                    className="primary_input"
                                    onChange={handleChange}
                                    name="yearOfManufacturing"
                                    value={values.yearOfManufacturing}
                                  />
                                  <p className="text-danger">{(touched.yearOfManufacturing && errors?.yearOfManufacturing) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group controlId="formGridSerialNumber" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Serial Number"
                                    className="primary_input"
                                    name="serialNumber"
                                    value={values.serialNumber}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.serialNumber && errors?.serialNumber) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <Form.Group controlId="formGridRegistration" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Registration"
                                    className="primary_input"
                                    name="registration"
                                    value={values.registration}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.registration && errors?.registration) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridEngine1S/N" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Engine 1 S/N"
                                    className="primary_input"
                                    name="engine1"
                                    value={values.engine1}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.engine1 && errors?.engine1) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group controlId="formGridEngine2S/N" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Engine 2 S/N"
                                    className="primary_input"
                                    name="engine2"
                                    value={values.engine2}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.engine2 && errors?.engine2) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridEngine3S/N" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Engine 3 S/N"
                                    className="primary_input"
                                    name="engine3"
                                    value={values.engine3}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.engine3 && errors?.engine3) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group controlId="formGridEngine4S/N" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Engine 4 S/N"
                                    className="primary_input"
                                    name="engine4"
                                    value={values.engine4}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.engine4 && errors?.engine4) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <Form.Group controlId="formGridAPUS/N" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="APU S/N"
                                    className="primary_input"
                                    name="apu"
                                    value={values.apu}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.apu && errors?.apu) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>

                            <h6 className="form_subhead">Point of Contact</h6>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridName" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    className="primary_input"
                                    value={values.pocName}
                                    name="pocName"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.pocName && errors?.pocName) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group controlId="formGridEmail" className="mb-3">
                                  <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    className="primary_input"
                                    value={values.pocEmail}
                                    name="pocEmail"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.pocEmail && errors?.pocEmail) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridMobile1" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Mobile 1"
                                    className="primary_input"
                                    value={values.pocPhoneNumber}
                                    name="pocPhoneNumber"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.pocPhoneNumber && errors?.pocPhoneNumber) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group controlId="formGridMobile2" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Mobile 2"
                                    className="primary_input"
                                    value={values.pocPhoneNumber2}
                                    name="pocPhoneNumber2"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.pocPhoneNumber2 && errors?.pocPhoneNumber2) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>

                            <h6 className="form_subhead">Escrow Agent</h6>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridName" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    className="primary_input"
                                    value={values.esName}
                                    name="esName"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.esName && errors?.esName) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group controlId="formGridEmail" className="mb-3">
                                  <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    className="primary_input"
                                    value={values.esEmail}
                                    name="esEmail"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.esEmail && errors?.esEmail) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridMobile1" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Mobile 1"
                                    className="primary_input"
                                    value={values.esPhoneNumber}
                                    name="esPhoneNumber"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.esPhoneNumber && errors?.esPhoneNumber) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group controlId="formGridMobile2" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Mobile 2"
                                    className="primary_input"
                                    value={values.esPhoneNumber2}
                                    name="esPhoneNumber2"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.esPhoneNumber2 && errors?.esPhoneNumber2) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>

                            <h6 className="form_subhead">Buyer KYC</h6>
                            <Row>
                              <Col lg={6}>
                                <Form.Group controlId="formGridOperator" className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Operator Name"
                                    className="primary_input"
                                    value={values.buyer}
                                    name="buyer"
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger">{(touched.buyer && errors?.buyer) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3 pos_rel" controlId="formBank">
                                  <Form.Control
                                    type="text"
                                    placeholder="Upload Document 1"
                                    className="primary_input"
                                    value={values.kycDoc1?.name || ''}
                                  />
                                  <Form.Control type="file" className="primary_input" onChange={(e) => handleFileChange(e, 'kycDoc1')} />
                                  <p className="text-danger">{(touched.kycDoc1 && errors?.kycDoc1) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <Form.Group className="mb-3 pos_rel" controlId="formBank">
                                  <Form.Control
                                    type="text"
                                    placeholder="Upload Document 2"
                                    className="primary_input"
                                    value={values.kycDoc2?.name || ''}
                                  />
                                  <Form.Control type="file" className="primary_input" onChange={(e) => handleFileChange(e, 'kycDoc2')} />
                                  <p className="text-danger">{(touched.kycDoc2 && errors?.kycDoc2) || ''}</p>
                                </Form.Group>
                              </Col>
                              <Col lg={6}>
                                <Form.Group className="mb-3 pos_rel" controlId="formBank">
                                  <Form.Control
                                    type="text"
                                    placeholder="Upload Document 3"
                                    className="primary_input"
                                    value={values.kycDoc3?.name || ''}
                                  />
                                  <Form.Control type="file" className="primary_input" onChange={(e) => handleFileChange(e, 'kycDoc3')} />
                                  <p className="text-danger">{(touched.kycDoc3 && errors?.kycDoc3) || ''}</p>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col lg={6} className="mb-3">
                                <Button className="border_btn" onClick={handleSaveLater}>
                                  Save and Continue Later
                                </Button>
                              </Col>
                              <Col lg={6} className="mb-3">
                                <Button className="primary_btn" variant="contained" onClick={handleSubmit}>
                                  Create Quote
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
