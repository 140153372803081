import axios, { handleResponse } from './Axios';

export const getPayment = async (id) => {
  try {
    let response = await axios({
      url: `/v1/payment/payment-status/${id}`,
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const createPayment = async (data) => {
  try {
    let response = await axios({
      url: '/v1/payment/create-payment',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const createQuote = async (data) => {
  try {
    let response = await axios({
      url: '/v1/payment/create-quote',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const cancelQuote = async (data) => {
  try {
    let response = await axios({
      url: '/v1/payment/cancel-quote',
      method: 'PUT',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getQuote = async (id) => {
  try {
    let response = await axios({
      url: `/v1/payment/quote-status/${id}`,
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getQuoteTransactions = async (params) => {
  try {
    let response = await axios({
      url: '/v1/payment/quote-transactions',
      method: 'GET',
      params
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getPaymentTransactions = async (params) => {
  try {
    let response = await axios({
      url: '/v1/payment/payment-transactions',
      method: 'GET',
      params
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getWithdrawTransactions = async (params) => {
  try {
    let response = await axios({
      url: '/v1/payment/withdraw-transactions',
      method: 'GET',
      params
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getTransferTransactions = async (params) => {
  try {
    let response = await axios({
      url: '/v1/payment/transfer-transactions',
      method: 'GET',
      params
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getQuoteRecentTransactions = async () => {
  try {
    let response = await axios({
      url: '/v1/payment/recent-transactions',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getAircraftTransactions = async (params, id) => {
  try {
    let response = await axios({
      url: `/v1/payment/aircraft-transactions/${id}`,
      method: 'GET',
      params
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
