import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Form, Modal, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import { Button, ButtonBase } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment';
import isEmpty from 'is-empty';
import * as yup from 'yup';
import { toast } from '../../../lib/toast';
import { createQuote } from '../../../api/payment';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import { ISALNUMNOSPACE } from '../../../constant/regex';
import { CountrySelect } from '../../dashboard/CountrySelect';
import en from 'react-phone-number-input/locale/en.json';
import PhoneInputWithCountry, { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input/input';

const initialValues = {
  quoteNumber: '',
  fee: '0',
  feePercent: '0%',
  client: '',
  from: '',
  to: '',
  aircraftId: '',
  amount: '',
  operatorName: '',
  network: '',
  flightDate: '',
  currency: '',
  modularCurrency: '',
  country: '',
  phoneNumber: ''
};

const validationSchema = yup.object({
  quoteNumber: yup
    .string()
    .matches(ISALNUMNOSPACE, { message: 'Quotation ID must be alphanumeric' })
    .required('Quote ID is required')
    .min(6, 'Should have minimum 6 characters')
    .max(16, 'Can have maximum of 16 characters'),
  client: yup.string().required('Client is required'),
  from: yup.string().required('Location required'),
  to: yup.string().required('Location required'),
  aircraftId: yup.string().required('Aircraft ID is required'),
  operatorName: yup.string().required('Operator Name is required'),
  network: yup.string().required('Network is required'),
  currency: yup.string().required('Currency is required'),
  amount: yup.number().required('Amount is required'),
  flightDate: yup.string().required('Flight Date is required'),
  phoneNumber: yup.string().required('Phone Number is required'),
  country: yup.string().required('Country Code is required')
});

const SendQuote = ({ showSendQuote, handleCloseSendQuote }) => {
  const dateRef = useRef(null);
  const currency = useSelector((state) => state.currency);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [networks, setNetworks] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const { values, touched, errors, status, handleChange, resetForm, setValues, handleSubmit, setErrors, setTouched, setFieldError } =
    useFormik({
      initialValues,
      onSubmit: handleSubmitQuote,
      validationSchema
    });

  const handleCountrySelect = (value) => {
    console.log(value);
    setValues({ ...values, countryCode: value ? value.split('-')[1] : '', country: value ? value.split('-')[0] : '', phoneNumber: '' });
  };

  const handleMobileNo = (e) => {
    const num = e.target.value;

    setValues({ ...values, phoneNumber: num });

    if (!num) {
      setFieldError('phoneNumber', 'Mobile Number required');
      return;
    }

    const countryCode = getCountryCallingCode(values.country);
    const fullNumber = `+${countryCode}${num}`;

    if (!isValidPhoneNumber(fullNumber)) {
      setFieldError('phoneNumber', 'Invalid mobile no');
    } else {
      setFieldError('phoneNumber', '');
    }
  };

  async function handleSubmitQuote(values, { setErrors, setStatus }) {
    try {
      console.log('values', values, 'handleSubmitQuote');
      setStatus('loading');
      const { success, message, errors, result } = await createQuote(values);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        setTimeout(() => {
          setStatus('');
          // navigate(`/quote-status/${result.quoteIntent}`);
          window.open(`${config.FRONTEND}/quote-status/${result.quoteIntent}`, '_blank');
          hideQuoteModal();
        }, 2000);
      } else {
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
        setStatus('');
      }
    } catch (error) {
      console.log(error);
      setStatus('');
    }
  }
  const handleCancel = async () => {};

  function hideQuoteModal() {
    resetForm();
    handleCloseSendQuote();
  }

  function handleDateUpdate(e) {
    setValues({ ...values, flightDate: e?.toISOString() || '' });
  }

  useEffect(() => {
    if (!isEmpty(currency)) {
      let allCurrencies = currency.map((item) => item.symbol);
      setCurrencies([...new Set([...allCurrencies])]);
    }
  }, [currency]);

  const calculateFee = useCallback(
    (currencyItem) => {
      if (!values.amount) return { fee: '0', feePercent: `${currencyItem.txnFee}%` };
      let feeAmt = (currencyItem.txnFee * parseFloat(values.amount)) / 100;
      if (!isNaN(feeAmt)) return { fee: feeAmt, feePercent: `${currencyItem.txnFee}%` };
      else return { fee: '0', feePercent: `${currencyItem.txnFee}%` };
    },
    [values]
  );

  const handleAssetChange = useCallback(
    async (e) => {
      const { name, value } = e.target;

      if (name === 'modularCurrency') {
        if (value === 'ETH') {
          let particularCurrency = currency.find((item) => item.symbol === 'ETH');
          setNetworks([{ value: 'ETH-ERC20', label: 'Ethereum' }]);
          let { fee, feePercent } = calculateFee(particularCurrency);
          await setValues(
            (prev) => ({ ...prev, network: 'ETH-ERC20', currency: particularCurrency._id, modularCurrency: value, fee, feePercent }),
            false
          );
        } else if (value === 'TRX') {
          let particularCurrency = currency.find((item) => item.symbol === 'TRX');
          setNetworks([{ value: 'TRX-TRC20', label: 'Tron Network' }]);
          let { fee, feePercent } = calculateFee(particularCurrency);
          await setValues(
            (prev) => ({ ...prev, network: 'TRX-TRC20', currency: particularCurrency._id, modularCurrency: value, fee, feePercent }),
            false
          );
        } else if (value === 'BTC') {
          let particularCurrency = currency.find((item) => item.symbol === 'BTC');
          setNetworks([{ value: 'BTC-BTC', label: 'Bitcoin' }]);
          let { fee, feePercent } = calculateFee(particularCurrency);
          await setValues(
            (prev) => ({ ...prev, network: 'BTC-BTC', currency: particularCurrency._id, modularCurrency: value, fee, feePercent }),
            false
          );
        } else if (value === 'USDT' || value === 'USDC') {
          setNetworks([
            { value: 'ETH-ERC20', label: 'Ethereum' },
            { value: 'TRX-TRC20', label: 'Tron Network' }
          ]);
          let particularCurrency = currency.find(item => item.symbol === value)
          setValues((prev) => ({ ...prev, modularCurrency: value, currency: particularCurrency._id }));
        }
      } else if (name === 'network') {
        if (!isEmpty(values.modularCurrency) && (values.modularCurrency === 'USDT' || values.modularCurrency === 'USDC')) {
          if (value === 'ETH-ERC20') {
            let particularCurrency = currency.find((item) => item.symbol === values.modularCurrency && item.tokenType === 'ERC20');
            let { fee, feePercent } = calculateFee(particularCurrency);
            await setValues((prev) => ({ ...prev, currency: particularCurrency._id, network: value, fee, feePercent }), false);
          } else if (value === 'TRX-TRC20') {
            let particularCurrency = currency.find((item) => item.symbol === values.modularCurrency && item.tokenType === 'TRC20');
            let { fee, feePercent } = calculateFee(particularCurrency);
            await setValues((prev) => ({ ...prev, currency: particularCurrency._id, network: value, fee, feePercent }), false);
          } else {
            handleChange(e);
          }
        } else {
          handleChange(e);
        }
      } else if (name === 'amount') {
        if (!isEmpty(values.currency)) {
          let particularCurrency = currency.find((item) => item._id === values.currency);
          calculateFee(particularCurrency);
        }
        handleChange(e);
      }
    },
    [calculateFee, currency, handleChange, setValues, values.currency, values.modularCurrency]
  );

  return (
    <>
      <Modal show={showSendQuote} size="lg" onHide={hideQuoteModal} centered backdrop="static" className="primary_modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Control
                  id="quoteNumber"
                  name="quoteNumber"
                  className="primary_input"
                  placeholder="Quotation ID"
                  onChange={handleChange}
                  value={values.quoteNumber}
                />
                <p className="red_text">{(touched.quoteNumber && errors.quoteNumber) || ''}</p>
              </Form.Group>
            </Col>
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Control
                  id="fee"
                  name="fee"
                  className="primary_input"
                  placeholder="Fee"
                  onChange={handleChange}
                  value={`${values.fee} USD | ${values.feePercent}`}
                  readOnly
                />
                <p className="red_text">{(touched.fee && errors.fee) || ''}</p>
              </Form.Group>
            </Col>
            <Col xs={12} className="mb-3">
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Control
                      id="client"
                      name="client"
                      className="primary_input"
                      placeholder="Client Name"
                      onChange={handleChange}
                      value={values.client}
                    />
                    <p className="red_text">{(touched.client && errors.client) || ''}</p>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Control
                          id="from"
                          name="from"
                          className="primary_input"
                          placeholder="From Airport"
                          onChange={handleChange}
                          value={values.from}
                        />
                      </Form.Group>
                      <p className="red_text">{(touched.from && errors.from) || ''}</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group>
                        <Form.Control
                          id="to"
                          name="to"
                          className="primary_input"
                          placeholder="To Airport"
                          onChange={handleChange}
                          value={values.to}
                        />
                        <p className="red_text">{(touched.to && errors.to) || ''}</p>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mb-3">
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Control
                      id="flightDate"
                      name="flightDate"
                      className="primary_input"
                      placeholder="Flight Date"
                      onClick={() => dateRef?.current?.toggleCalendar()}
                      value={!isEmpty(values.flightDate) ? moment(values.flightDate).format('DD/MM/YYYY') : ''}
                      readOnly
                    />
                    <p className="red_text mb-0">{(touched.flightDate && errors.flightDate) || ''}</p>
                    <DatePicker className="datepicker_hidden" ref={dateRef} onChange={handleDateUpdate} name="flightDate" />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Control
                      id="operatorName"
                      name="operatorName"
                      className="primary_input"
                      placeholder="Operator Name"
                      onChange={handleChange}
                      value={values.operatorName}
                    />
                    <p className="red_text">{(touched.operatorName && errors.operatorName) || ''}</p>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mb-3">
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Control
                      id="aircraftId"
                      name="aircraftId"
                      className="primary_input"
                      placeholder="Aircraft ID"
                      onChange={handleChange}
                      value={values.aircraftId}
                    />
                    <p className="red_text">{(touched.aircraftId && errors.aircraftId) || ''}</p>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Control
                      id="amount"
                      name="amount"
                      className="primary_input"
                      placeholder="Amount (USD)"
                      type="number"
                      onChange={handleAssetChange}
                      value={values.amount}
                    />
                    <p className="red_text">{(touched.amount && errors.amount) || ''}</p>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mb-3">
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Select
                      id="curency"
                      name="modularCurrency"
                      className="primary_input"
                      placeholder="Select Currency"
                      value={values.modularCurrency}
                      onChange={handleAssetChange}
                    >
                      <option>Select Currency</option>
                      {!isEmpty(currencies) &&
                        currencies.map((curr) => {
                          return (
                            <option key={curr} value={curr}>
                              {curr}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <p className="red_text">{(touched.currency && errors.currency) || ''}</p>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Select
                      id="network"
                      name="network"
                      className="primary_input"
                      placeholder="Select Network"
                      onChange={handleChange}
                      value={values.network}
                    >
                      {(values.modularCurrency === 'USDT' || values.modularCurrency === 'USDC') && !isEmpty(networks) ? (
                        <option>Select Network</option>
                      ) : (
                        <Fragment>{!isEmpty(networks) ? <option>{networks[0].label}</option> : <option>Select Network</option>}</Fragment>
                      )}
                      {!isEmpty(networks) && networks.map((item) => <option key={item?.value}>{item.label}</option>)}
                    </Form.Select>
                    <p className="red_text">{(touched.network && errors.network) || ''}</p>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mb-3">
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <CountrySelect
                      className="form-control primary_input form-select"
                      labels={en}
                      value={values.country}
                      onChange={handleCountrySelect}
                      name="countrySelect"
                    />
                    <p className="red_text">{(touched.country && errors.country) || ''}</p>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Control
                      type="number"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="primary_input"
                      placeholder="Phone Number"
                      onChange={handleMobileNo}
                      value={values.phoneNumber}
                    />
                    <p className="red_text">{(touched.phoneNumber && errors.phoneNumber) || ''}</p>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="mb-3">
              <Button className="border_btn" disabled={status === 'loading'} onClick={handleSubmit}>
                {status === 'loading' ? <Spinner size="sm" /> : 'Request Payment Quote'}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SendQuote;
