import { getCountries, getCountryCallingCode } from 'react-phone-number-input';

export const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <select
    {...rest}
    value={value ? `${value}-${getCountryCallingCode(value)}` : ''}
    onChange={(event) => {
      onChange(event.target.value || undefined);
    }}
  >
    <option value="">Select Country Code</option>
    {getCountries().map((country) => (
      <option key={country} value={`${country}-${getCountryCallingCode(country)}`}>
        {labels[country]} +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);
