import React, { useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export default function USDT({ balance, scale }) {
  const chartData = {
    series: [scale],
    labels: ['USDT']
  };

  const chartOptions = {
    chart: {
      type: 'radialBar'
    },
    labels: chartData.labels,

    stroke: {
      lineCap: 'round'
    },
    legend: {
      show: false,
      labels: {
        colors: '#fff',
        useSeriesColors: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        colorStops: [
          {
            offset: 0,
            color: '#FF83C6'
          },
          {
            offset: [100],
            color: '#FD5BA9'
          }
        ],
        shadeIntensity: 1,
        inverseColors: true,
        opacityFrom: 0.2,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          background: 'transparent',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front'
          // dropShadow: {
          //   enabled: true,
          //   top: 3,
          //   left: 0,
          //   blur: 4,
          //   opacity: 0.24,
          // },
        },
        track: {
          background: '#2B313D',
          strokeWidth: '100%',
          margin: 0
          // dropShadow: {
          //   enabled: true,
          //   top: -3,
          //   left: 0,
          //   blur: 4,
          //   opacity: 0.35,
          // },
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 0,
            show: true,
            color: '#fff',
            fontFamily: 'Outfit',
            fontSize: '12px',
            fontWeight: 300
          },
          value: {
            formatter: useCallback(() => `$${balance}`, [balance]),
            fontSize: '16px',
            fontFamily: 'Outfit',
            fontWeight: 700,
            color: '#fff',
            show: true,
            offsetY: -8
          }
        }
      }
    },
    labels: [''],
    responsive: [
      {
        breakpoint: 575,
        options: {
          chart: {
            width: 250
          }
        }
      }
    ]
  };

  return (
    <>
      <div>
        <ReactApexChart options={chartOptions} series={chartData.series} type="radialBar" width={250} />
      </div>
    </>
  );
}
