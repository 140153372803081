import { Button } from '@mui/material';
import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { createTheme } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { getUserProfile } from '../../redux/slices/account';
import { updateAccountDetails } from '../../api/user';
import ChangePassword from './ChangePassword';
import LoginHistory from './LoginHistory';
import Enable2FA from './Enable2FA';
import Disable2Fa from './Disable2Fa';
import { toast } from '../../lib/toast';
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

const validationSchema = yup.object({
  userName: yup
    .string()
    .min(3, 'Name should have a minimum of 3 characters')
    .max(35, 'Name can have a maximum of 35 characters')
    .required('Name is required')
});

const AccountDetails = () => {
  const [isEdit, setIsEdit] = useState(false);
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();

  // refs
  const changePassRef = useRef(null);
  const loginHistoryRef = useRef(null);
  const enable2FARef = useRef(null);
  const disable2FARef = useRef(null);

  const initialValues = { userName: account.userName, email: account.email, phoneNumber: account.phoneNumber };

  const {
    values,
    status,
    errors,
    handleChange,
    resetForm,
    setErrors,
    handleSubmit: handleSubmitUpdateAccount
  } = useFormik({ initialValues, onSubmit: handleAccountUpdate, validationSchema });

  async function handleAccountUpdate(values, { setErrors, resetForm, setStatus }) {
    try {
      if (initialValues.userName == values.userName && initialValues.phoneNumber == values.phoneNumber) {
        return toast({ message: 'Nothing to update', type: 'info' }, dispatch);
      }
      setStatus('loading');
      const { success, message, errors } = await updateAccountDetails(values);
      if (success) {
        setStatus('');
        setIsEdit(false);
        toast({ message, type: 'success' }, dispatch);
        dispatch(getUserProfile());
      } else {
        setStatus('');
        message && toast({ message, type: 'error' });
        errors && setErrors(errors);
      }
    } catch (error) {
      setStatus('');
      toast({ message: 'Something went wrong', type: 'error' });
    }
  }

  createTheme(
    'dark',
    {
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(0,0,0,.12)'
      },
      background: {
        default: '#1A1E27'
      },
      context: {
        background: '#1A1E27',
        text: '#DA1818'
      },
      divider: {
        default: 'rgb(22 21 21)'
      },
      button: {
        default: '#FFFFFF',
        focus: 'rgba(255, 255, 255, .54)',
        hover: 'rgba(255, 255, 255, .12)',
        disabled: 'rgba(255, 255, 255, .18)'
      },
      selected: {
        default: 'rgba(0, 0, 0, .7)',
        text: '#FFFFFF'
      },
      highlightOnHover: {
        default: 'rgba(0, 0, 0, .7)',
        text: '#FFFFFF'
      },
      striped: {
        default: 'rgba(0, 0, 0, .87)',
        text: '#FFFFFF'
      }
    },
    'dark'
  );

  const handleHide = () => {
    setIsEdit(false);
  };

  return (
    <React.Fragment>
      <div className="account_details_box">
        <div className="text-end mb-2">
          {/* {isEdit && status !== 'loading' && <Button onClick={() => handleSubmitUpdateAccount()}>Update</Button>} */}
          <img
            src={require('../../assets/images/edit_icon.png')}
            alt="Edit"
            className="edit_icon"
            onClick={() =>
              setIsEdit((prev) => {
                if (prev) {
                  setErrors({});
                  resetForm();
                }
                return !prev;
              })
            }
          />
        </div>
        <div className="profile_inp_row">
          <label>Username:</label>
          <input
            className="profile_inp"
            value={values.userName || ''}
            name="userName"
            // onChange={handleChange}
            disabled={true}
          />
        </div>
        <p className="red_text">{errors?.userName || ''}</p>
        <div className="profile_inp_row">
          <label>Email Address:</label>
          <input className="profile_inp" value={values.email} disabled />
        </div>
        <div className="profile_inp_row">
          <label>Mobile Number:</label>
          <input
            className="profile_inp"
            name="phoneNumber"
            value={values.phoneNumber}
            disabled={true}
            // onChange={handleChange}
          />
        </div>
        <p className="red_text">{errors?.phoneNumber || ''}</p>
      </div>
      <div className="account_details_box">
        <div class="dashtitle_flex mb-1">
          <h2 class="dash_inner_title">Security</h2>
        </div>
        <ul className="security_list">
          <li>
            <Button onClick={() => changePassRef.current?.handleShowChangePassword()}>Change Password</Button>
          </li>
          <li>
            <Button onClick={() => loginHistoryRef.current.handleShowLoginHistory()}>Login History</Button>
          </li>
          <li>
            Two Factor Authentication{' '}
            <span className="d-flex align-items-center">
              {!account.isTwoFA ? (
                <Button className="blue_text" onClick={() => enable2FARef.current.handleShowEnableTFA()}>
                  Enable
                </Button>
              ) : (
                <Button className="blue_text" onClick={() => disable2FARef.current.handleShowDisableTFA()}>
                  Disable
                </Button>
              )}
            </span>
          </li>
        </ul>
      </div>
      <ChangePassword ref={changePassRef} />
      <LoginHistory ref={loginHistoryRef} />
      <Enable2FA ref={enable2FARef} />
      <Disable2Fa ref={disable2FARef} />
      <Modal show={isEdit} onHide={handleHide} centered backdrop="static" className="primary_modal" size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitUpdateAccount}>
            <Row className="align-items-center">
              <Col xs={12}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    className="primary_input"
                    name="userName"
                    value={values.userName}
                    onChange={handleChange}
                  />
                  <p className="red_text">{errors.userName || ''}</p>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="formGridTransactionnumber" className="mb-3">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    className="primary_input"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                  <p className="red_text">{errors.phoneNumber || ''}</p>
                </Form.Group>
              </Col>
            </Row>
            <Button className="border_btn" type="submit" disabled={status === 'loading'}>
              {status === 'loading' ? <Spinner size="sm" /> : 'Update'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AccountDetails;
