import { Button, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import phone from './phone.svg';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EMAIL, EMAIL_CHARS, ISALNUMHYPCASE, PHONE, PHONE2 } from '../../../constant/regex';
import { toast } from '../../../lib/toast';
import { useDispatch } from 'react-redux';
import { postContactUs } from '../../../api/user';

const initialValues = {
  name: '',
  email: '',
  phNumber: '',
  message: ''
};

const validationSchema = yup.object({
  name: yup.string().matches(ISALNUMHYPCASE, { message: 'Name has invalid characters' }).required('Name is required'),
  email: yup
    .string()
    .matches(EMAIL, { message: 'Invalid email' })
    .matches(EMAIL_CHARS, { message: 'Email has invalid characters' })
    .required('Email is required'),
  phNumber: yup
    .string()
    .matches(PHONE2, { message: 'Invalid phone Number' })
    .matches(PHONE, { message: 'Include country code + sign' })
    .required('Phone number is required'),
  message: yup.string().min(10).max(1000).required('Message is required')
});

const Contact = ({ show, onHide }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { values, touched, errors, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: handleContactSubmit,
    validationSchema
  });

  async function handleContactSubmit(values, { resetForm }) {
    try {
      setLoading(true);
      const { success, message } = await postContactUs(values);
      if (success) {
        setLoading(false);
        toast({ message, type: 'success' }, dispatch);
        resetForm();
        onHide();
      } else {
        setLoading(false);
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }
  return (
    <Modal show={show} onHide={onHide} centered size="lg" contentClassName="contact-modal-header">
      <Modal.Header closeButton className="contact-modal-header">
        Contact Us
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 px-3 py-3">
          <Row>
            <Col xs={12} lg={4} className="mb-3">
              <TextField
                className="w-100"
                variant="outlined"
                label="Name"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i class="fa-regular fa-user"></i>
                    </InputAdornment>
                  )
                }}
              />
              {touched?.name && <span className="red_text">{errors?.name}</span>}
            </Col>
            <Col xs={12} lg={4} className="mb-3">
              <TextField
                className="w-100"
                variant="outlined"
                label="Email"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <i class="fa-regular fa-envelope"></i>
                    </InputAdornment>
                  )
                }}
              />
              {touched?.email && <span className="red_text">{errors?.email}</span>}
            </Col>
            <Col xs={12} lg={4} className="mb-3">
              <TextField
                className="w-100"
                variant="outlined"
                label="Ph. Number"
                type="tel"
                name="phNumber"
                value={values.phNumber}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={phone} alt="phone_icon" />
                    </InputAdornment>
                  )
                }}
              />
              {touched?.phNumber && <span className="red_text">{errors?.phNumber}</span>}
            </Col>
            <Col xs={12} className="mb-3">
              <TextField
                className="w-100 rounded-sm"
                variant="outlined"
                label="Message"
                type="text"
                name='message'
                value={values.message}
                onChange={handleChange}
                multiline
                InputProps={{
                  className: 'rounded-sm',
                  startAdornment: (
                    <InputAdornment position="start">
                      <i class="fa-solid fa-pencil"></i>
                    </InputAdornment>
                  )
                }}
              />
              {touched?.message && <span className="red_text">{errors?.message}</span>}
            </Col>
          </Row>
          <div className="w-100 flex justify-end items-end">
            <Button className="border_btn w-fit" type="button" onClick={handleSubmit} disabled={loading}>
              {loading ? <Spinner size="sm" /> : 'Submit'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Contact;
