let config = {
  API_HOST: '',
  API_URL: '',
  BASENAME: '/',
  NAME: 'Aeroverse Pay',
  FRONTEND: ''
};

if (process.env.REACT_APP_MODE === 'production') {
  config = {
    API_HOST: 'https://prodapi.aeroversepay.com',
    API_URL: 'https://prodapi.aeroversepay.com/api',
    BASENAME: '/',
    NAME: 'Aeroverse Pay',
    FRONTEND: 'https://aeroversepay.com'
  };
} else if (process.env.REACT_APP_MODE === 'demo') {
  config = {
    API_HOST: 'https://aeroversepayapi.wearedev.team',
    API_URL: 'https://aeroversepayapi.wearedev.team/api',
    BASENAME: '/',
    NAME: 'Aeroverse Pay',
    FRONTEND: 'https://aeroversepay-frontend.pages.dev'
  };
} else {
  config = {
    API_HOST: 'http://localhost:5002',
    API_URL: 'http://localhost:5002/api',
    BASENAME: '/',
    NAME: 'Aeroverse Pay',
    FRONTEND: 'http://localhost:3000'
  };
}

export default config;
