import logo from '../../../assets/images/logo.svg'

export default function Header() {

    return (
        <div className="w-screen bg-[#1A1E27] h-[80px]">
            <div className="max-w-[1440px] h-full px-2.5 md:px-[88px] flex justify-between items-center mx-auto">
                <div>
                    <img src={logo} alt="logo" />
                </div>
                <nav className="flex gap-[22px] md:gap-[32px] text-[16px] font-extralight md:font-semibold">
                    {/* <a>Solution <FontAwesomeIcon icon={faAngleDown} /></a> */}
                    <a href="/register">Register</a>
                    <a href="/login">Login</a>
                </nav>
            </div>
        </div>
    )
}