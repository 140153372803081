import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Modal, Row, Col, Spinner } from 'react-bootstrap';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from '../../../lib/toast';
import { withdrawRequestApi } from '../../../api/wallet';
import { percentageCalculation } from '../../../lib/common';
import isEmpty from 'is-empty';
let initialFormValue = {
  symbol: '',
  amount: '',
  receiverAmount: '',
  accountNo: '',
  holderName: '',
  currency: '',
  ifsc: '',
  percentage: 0
};
const validationSchema = yup.object({
  symbol: yup.string().required('Symbol is required'),
  amount: yup.string().required('Amount is required'),
  accountNo: yup.string().required('Please select your bank account')
});
const Withdraw = ({ account, showWithdraw, handleCloseWithdraw, currencyData }) => {
  const {
    values,
    errors,
    status,
    touched,
    handleSubmit,
    handleChange: UseFormikHandleChange,
    resetForm,
    setErrors,
    setValues,
    isSubmitting
  } = useFormik({
    initialValues: initialFormValue,
    validationSchema,
    onSubmit: withdrawRequest
  });
  let { symbol, amount, holderName, percentage, receiverAmount, accountNo } = values;

  const dispatch = useDispatch();
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'amount') {
      if (parseFloat(value) == 0) {
        return setValues((prev) => ({
          ...prev,
          receiverAmount: value,
          amount: value
        }));
      }
      let fee = percentageCalculation(!isEmpty(percentage) ? percentage : 0, !isEmpty(value) ? value : 0);
      setValues((prev) => ({
        ...prev,
        receiverAmount: !isEmpty(value) ? value - fee : 0
      }));

      UseFormikHandleChange(event);
    } else if (name === 'symbol') {
      let symbol = !isEmpty(currencyData) ? currencyData?.find(item => item._id === event.target.value) : null 
      let fee = percentageCalculation(!isEmpty(symbol) ? symbol.withdrawFee : 0, !isEmpty(amount) ? amount : 0);
      setValues((prev) => ({
        ...prev,
        receiverAmount: !isEmpty(amount) ? amount - fee : 0,
        percentage: currencyData.find((item) => item._id == value)?.withdrawFee
      }));
      UseFormikHandleChange(event);
    } else if (name === 'accountNo') {
      let accountDetails = !isEmpty(account) && account.bankDetails.find((item) => item.accountNo == value);
      setValues((prev) => ({
        ...prev,
        holderName: !isEmpty(accountDetails) ? accountDetails.holderName : '',
        ifsc: !isEmpty(accountDetails) ? accountDetails.IFSC : '',
        currency: !isEmpty(accountDetails) ? accountDetails.currency : ''
      }));
      UseFormikHandleChange(event);
    } else {
      UseFormikHandleChange(event);
    }
  };
  async function withdrawRequest() {
    try {
      let curData;
      if (!isEmpty(symbol)) {
        curData = currencyData.find((item) => item._id === symbol);
      } else {
        console.error('Please fill the empty fields');
        toast({ message: 'Please fill the empty fields', type: 'success' }, dispatch);
      }
      let data = {
        ...values,
        currencyId: curData?._id,
        fee: curData?.withdrawFee
      };
      const { success, message, errors } = await withdrawRequestApi(data);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        handleCloseWithdraw();
        resetForm();
      } else {
        if (errors) setErrors(errors);
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Something went wrong', type: 'error' }, dispatch);
      console.log('UPDATE_COMPANY_DETAILS_ERROR', error);
    }
  }

  return (
    <>
      <Modal show={showWithdraw} size="lg" onHide={handleCloseWithdraw} centered backdrop="static" className="primary_modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formGridName" className="mb-3">
                  <Form.Select value={symbol} name="symbol" onChange={handleChange} className="primary_select">
                    <option value="">Select Coin</option>
                    {Array.isArray(currencyData) &&
                      currencyData.map((item) =>
                        item.type === 'token' ? (
                          <option value={item._id}>{`${item.symbol} - ${item.tokenType}`}</option>
                        ) : (
                          <option value={item._id}>{`${item.symbol}`}</option>
                        )
                      )}
                  </Form.Select>
                  <p className="text-danger">{(touched.symbol && errors.symbol) || ''}</p>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formGridName" className="mb-3">
                  <Form.Select value={accountNo} name="accountNo" onChange={handleChange} className="primary_select">
                    <option value="">Bank Account</option>
                    {Array.isArray(account?.bankDetails) &&
                      account?.bankDetails.length > 0 &&
                      account?.bankDetails.map((item) => (
                        <option value={item.accountNo}>
                          <span className="mr-5">
                            {' '}
                            {item.currency} - {item.bankName}, {item.country}
                          </span>{' '}
                          {item.isPrimary && (
                            <span>
                              <small className="green_text"> (Primary)</small>
                            </span>
                          )}
                        </option>
                      ))}
                    {/* <option>USD</option>
                    <option>INR</option> */}
                    {/* {currencyData.length > 0 && currencyData.map((item) => <option value={item.symbol}>{item.symbol}</option>)} */}
                  </Form.Select>
                  <p className="text-danger">{(touched.accountNo && errors.accountNo) || ''}</p>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formGridName" className="mb-3">
                  <Form.Control
                    type="number"
                    value={amount}
                    placeholder="Amount"
                    onChange={handleChange}
                    className="primary_input"
                    name="amount"
                    step={'0.00001'}
                  />
                  <p className="text-danger">{(touched.amount && errors.amount) || ''}</p>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formGridBank" className="mb-3">
                  {/* <Form.Control
                    type="text"
                    placeholder="Account Number"
                    value={accountNo}
                    className="primary_input"
                    // onChange={handleChange}
                    name="accountNo"
                  /> */}
                  {!isEmpty(accountNo) && <Form.Label>Account No: {accountNo}</Form.Label>}
                  {/* <p className="text-danger">{(touched.bankName && errors.bankName) || ''}</p> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formGridBank" className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="You Receive"
                    value={receiverAmount}
                    className="primary_input"
                    // onChange={handleChange}
                    name="receiverAmount"
                  />
                  {/* <p className="text-danger">{(touched.bankName && errors.bankName) || ''}</p> */}
                </Form.Group>
              </Col>
              <Col lg={6}>
                {!isEmpty(symbol) && (
                  <Form.Label>Fee: {parseFloat(currencyData.find((item) => item._id == symbol).withdrawFee).toFixed(4)} %</Form.Label>
                )}
              </Col>
            </Row>
            <Row></Row>
            <Button type="button" className="border_btn" variant="contained" onClick={handleCloseWithdraw}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" className="primary_btn" variant="contained">
              {isSubmitting ? <Spinner size="sm" /> : 'Confirm'}
            </Button>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Withdraw;
