import { setAccount, setProfilePicture } from '../redux/slices/account';
import axios, { handleResponse } from './Axios';

export const retrieveUserProfile = async () => {
  try {
    const response = await axios({
      url: '/v1/user/retrieve-user-profile',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    console.log(error);
    return handleResponse(error, 'error');
  }
};

export const fetchUserProfile = async (dispatch) => {
  try {
    const response = await axios({
      url: '/v1/user/retrieve-user-profile',
      method: 'GET'
    });

    dispatch(setAccount(response.data.result));
  } catch (error) {
    console.log(error);
    return handleResponse(error, 'error');
  }
};

export const updateAccountDetails = async (data) => {
  try {
    const response = await axios({
      url: '/v1/user/update-account',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const updatePassword = async (data) => {
  try {
    const response = await axios({
      url: '/v1/user/change-password',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getLoginHistory = async (data) => {
  try {
    const response = await axios({
      url: '/v1/user/login-history',
      method: 'GET',
      params: data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const get2FA = async () => {
  try {
    const response = await axios({
      url: '/auth/user/two-factor-auth',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const postEnable2FA = async (data) => {
  try {
    const response = await axios({
      url: '/auth/user/two-factor-auth',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const putDisable2FA = async (data) => {
  try {
    const response = await axios({
      url: '/auth/user/two-factor-auth',
      method: 'PUT',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const postForgetPassword = async (data) => {
  try {
    const response = await axios({
      url: '/auth/user/forget-password',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const otpVerification = async (data) => {
  try {
    const response = await axios({
      url: '/auth/user/forget-password',
      method: 'put',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const resetPassword = async (data) => {
  try {
    const response = await axios({
      url: '/auth/user/reset-password',
      method: 'post',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const retrieveUserWallet = async () => {
  try {
    const response = await axios({
      url: '/v1/wallet/user-wallet',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const retrieveCurrency = async () => {
  try {
    const response = await axios({
      url: '/v1/currency/get-currency',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const updateCompanyDetails = async (data) => {
  try {
    const response = await axios({
      url: '/v1/user/account-details',
      method: 'PUT',
      data
    });
    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const addBankDetails = async (data) => {
  try {
    const response = await axios({
      url: '/v1/user/bank-details',
      method: 'post',
      data
    });
    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const updateBankDetails = async (data) => {
  try {
    const response = await axios({
      url: '/v1/user/bank-details',
      method: 'put',
      data
    });
    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const getBankDetails = async () => {
  try {
    const response = await axios({
      url: '/v1/user/bank-details',
      method: 'get'
    });
    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const updateProfilePicture = async (data, dispatch) => {
  try {
    const response = await axios({
      url: '/v1/user/update-profile-picture',
      method: 'post',
      data
    });
    dispatch(setProfilePicture(response.data.profileImage));
    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
export const getDashboard = async () => {
  try {
    const response = await axios({
      url: '/v1/user/dashboard',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const postContactUs = async (data) => {
  try {
    const response = await axios({
      url: '/v1/user/contact-us',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
