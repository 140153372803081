import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import RiskChart from '../components/audit-report/RiskChart.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AuditReportDetail(props) {
  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row pt-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <div class="dashtitle_flex mb-3">
                        <Link to="/audit-report" className="d-flex align-items-center">
                          <img src={require('../assets/images/arrow_back.png')} alt="Back" className="me-1" />
                          <h2 class="dash_inner_title">Aaron Richard</h2>
                        </Link>
                        <Button className="download_btn">
                          <img src={require('../assets/images/download_icon.png')} alt="Download" />
                          Download
                        </Button>
                      </div>
                      <div className="audit_detail_top">
                        <div className="audit_detail_top_left">
                          <RiskChart />
                          <p>
                            Sanction Lists : <span>Sanction Free</span>{' '}
                            <img src={require('../assets/images/tick_icon.png')} alt="Download" />
                          </p>
                          <p>
                            Customer Due Diligence: <span>Completed</span>
                            <img src={require('../assets/images/tick_icon.png')} alt="Download" />
                          </p>
                        </div>
                        <div className="audit_detail_top_right">
                          <p>
                            ETH address : <span className="address_wrap">0xb42d0c59549477bb3751369fd6a46686688a584c</span>
                          </p>
                          <p>
                            Balance : <span>0.323 ($000.000)</span>
                          </p>
                        </div>
                      </div>
                      <div className="audit_detail_middle">
                        <div className="audit_detail_middle_left">
                          <h3>Balance</h3>
                          <ul>
                            <li>
                              Total Balance : <span>0000000</span>
                            </li>
                            <li>
                              Total Received : <span>0000000</span>
                            </li>
                            <li>
                              Total Spent : <span>0000000</span>
                            </li>
                          </ul>
                        </div>
                        <div className="audit_detail_middle_right">
                          <h3>Info</h3>
                          <ul>
                            <li>
                              First change of balance : <span>10/01/2024</span>
                            </li>
                            <li>
                              Last change of balance : <span>10/01/2024</span>
                            </li>
                            <li>
                              Number of transactions : <span>0000</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="audit_detail_bottom">
                        <h3>
                          Suspicious Activity : <span className="low_risk">Low</span>
                        </h3>
                        <p>
                          Trusted sources can include: coins obtained via centralized exchange, Initial Coin Offerings, marketplace with
                          legal activities, regulated merchant services, coins mined by miners and licensed P2P exchange.
                        </p>
                        <h5>What Is A Risk Score </h5>
                        <p>
                          Risk Score is a metric that estimates the likelihood that an address/transaction is rellated to illegal
                          activities. The value can range from High Risk (max. 100%) to Low Risk (min. 0%).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
