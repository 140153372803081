import axios, { handleResponse } from './Axios';

export const withdrawRequestApi = async (data) => {
  try {
    let response = await axios({
      url: '/v1/wallet/withdraw-request',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const postWithdrawCrypto = async (data) => {
  try {
    let response = await axios({
      url: '/v1/wallet/withdraw-crypto-request',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success')
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const transferAssets = async (data) => {
  try {
    let response = await axios({
      url: '/v1/wallet/transfer',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
