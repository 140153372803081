export const getAuthToken = () => {
  if (sessionStorage.getItem('transientToken')) {
    return sessionStorage.getItem('transientToken');
  } else if (localStorage.getItem('transientToken')) {
    return localStorage.getItem('transientToken');
  }
  return '';
};

export const setAuthToken = (name, token) => {
  if (token) {
    localStorage.setItem(name, token);
  }
};

export const isLogin = (name) => {
  if (localStorage.getItem(name)) {
    return true;
  }
  return false;
};

export const setupAuthTokens = ( transientToken, isRemember) => {
  if (!isRemember) {
    sessionStorage.setItem('transientToken', transientToken);
  } else {
    localStorage.setItem('transientToken', transientToken);
  }
  return true;
};
