import { Button, capitalize } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { toast } from '../../lib/toast';

import moment from 'moment';
import { getAircraftTransactions } from '../../api/payment';

const TransactionHistory = (props) => {
  const [aircraftId] = useState(props.aircraftId);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState({ page: 1, limit: 10, sort: {} });
  const dispatch = useDispatch();

  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ page: page, limit: newPerPage } };
    setFilter(filterData);
  };
  const handlePageChange = (page) => {
    let filterData = { ...filter, ...{ page: page } };
    setFilter(filterData);
  };
  const handleSort = (column, direction) => {
    let filterData = { ...filter, sort: { [column.sortField]: direction === 'desc' ? -1 : 1 } };
    setFilter(filterData);
  };
  const columns = [
    {
      name: 'Payment ID',
      selector: (row) => <a href={`/payment-status/${row.intentId}`}>{row.intentId}</a>,
      sortable: true,
      sortField: 'intentId'
    },
    {
      name: 'Payment Date',
      selector: (row) => (row.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : '-'),
      sortable: true,
      sortField: 'createdAt'
    },
    {
      name: 'Amount',
      selector: (row) => `$${row.amount}`,
      sortable: true,
      sortField: 'amount'
    },
    {
      name: 'Network Amount',
      selector: (row) => `${parseFloat(row.networkAmount).toFixed(4)}`,
      sortable: true,
      sortField: 'amount'
    },
    {
      name: 'Type',
      selector: (row) => row.symbol,
      sortable: true,
      sortField: 'symbol'
    },
    {
      name: 'Status',
      selector: (row) => (row.status ? capitalize(row.status) : '-'),
      sortable: true,
      sortField: 'status'
    }
  ];

  const fetchTransactionHistory = useCallback(
    async (filter) => {
      try {
        const { success, result, message } = await getAircraftTransactions(filter, aircraftId);
        if (success) {
          setData(result.data);
          setCount(result.count);
        } else {
          toast({ message, type: 'error' }, dispatch);
        }
      } catch (error) {
        toast({ message: 'Some error occurred', type: 'error' }, dispatch);
      }
    },
    [dispatch, aircraftId]
  );

  useEffect(() => {
    if (filter.limit && filter.page && props.showTransactionHistory) {
      fetchTransactionHistory(filter);
    }
  }, [filter, fetchTransactionHistory, props.showTransactionHistory]);

  return (
    <Modal show={props.showTransactionHistory} size="xl" onHide={props.handleClose} centered backdrop="static" className="primary_modal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <DataTable
          columns={columns}
          data={data}
          responsive
          theme="dark"
          pagination
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          paginationTotalRows={count}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationServer
          sortServer
          onSort={handleSort}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="primary_btn" variant="contained" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransactionHistory;
