import { Button, capitalize } from '@mui/material';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { useCallback } from 'react';
import { toast } from '../../lib/toast';
import { useDispatch } from 'react-redux';
import config from '../../config';

const ExpandedComponent = ({ data }) => {
  const dispatch = useDispatch()
  const handleDownloadTransaction = useCallback(
    async (id, isShare) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const element = document.getElementById(id);
      const canvas = await html2canvas(element);
      const hrefdata = canvas.toDataURL('image/png');
      const link = document.createElement('a');

      link.href = hrefdata;
      link.download = `${data.quotationId}.png`;

      //   if (isShare) {

      //   }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [data]
  );

  const handleCopy = (text) => {
    try {
      navigator.clipboard.writeText(text).then(() => {
        toast({ message: 'Payment link copied to clipboard', type: 'success' }, dispatch);
      }).catch(() => {
        toast({ message: 'Some error occurred', type: 'error' }, dispatch);
      })
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  };

  return [
    <div className="table_expand_data" id={data._id}>
      <h2>
        Transaction ID <a href={`/quote-status/${data?.quotationId}`}>#{data?.quotationId || ''}</a>
      </h2>
      <div className="table_expand_data_wrap">
        <div className="table_expand_data_left">
          <label>
            Client Name : <span>{data?.client || ''}</span>
          </label>
          <label>
            Aircraft ID : <span>{data?.aircraftId || ''}</span>
          </label>
          <label>
            Operator : <span>{data?.operatorName || ''}</span>
          </label>
          <label>
            Flight Date : <span>{data?.flightDate ? moment(data?.flightDate).format('DD/MM/YYYY') : '-'}</span>
          </label>
        </div>
        <div className="table_expand_data_right">
          <label>
            From : <span>{data?.from || '-'}</span>
          </label>
          <br />
          <label>
            To : <span>{data?.to || '-'}</span>
          </label>
          <label className="book_charge">
            {data?.networkAmount?.toFixed(2) || '0.00'} {data?.symbol || ''} <span>{data?.tokenType}</span>
          </label>
          <label>
            Status : <span>{data?.status ? capitalize(data?.status) : '-'}</span>
          </label>
        </div>
      </div>
      <div className="table_expand_data_button">
        <Button className="border_btn" onClick={() => handleCopy(`${config.FRONTEND}/quote-status/${data?.quotationId}`)}>
          Share
        </Button>
        <Button className="primary_btn" variant="contained" onClick={() => handleDownloadTransaction(data._id, false)}>
          Download
        </Button>
      </div>
    </div>
  ];
};

export default ExpandedComponent;
