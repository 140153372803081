import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';

export default function MonthlyBarChart({data}) {
  const chartData = {
    series: [
      {
        data: data.chartData.value
      },
      {}
    ]
  };

  const chartOptions = {
    chart: {
      width: '100%',
      type: 'bar',
      toolbar: {
        show: false
      },
      events: {
        click: (e, context, config) => {
          console.log(e, context, config)
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '35%',
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: 'top'
        }
      }
    },
    colors: ['#2B313D', '#2B313D', '#2B313D', '#83FFE1'],
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    stroke: {
      show: false,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      enabled: true,
      x: { // X-axis tooltip options
        show: false, // Disable x-axis value (tooltip title)
      },
      theme: 'dark',
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          const categories = w.config.xaxis.categories; // Get x-axis categories
          const xAxisValue = categories[dataPointIndex]; // Access x-axis value by index
      
          // Format the tooltip content (optional)
          return `${xAxisValue}: ${value} `;
        },
      },
    },
    grid: {
      show: false
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    xaxis: {
      borderColor: '#FF83C6',
      categories: data.chartData.labels,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: ['#8b8a8a', '#8b8a8a', '#8b8a8a', '#fff'],
          fontSize: '12px',
          fontFamily: 'Outfit',
          fontWeight: 300
        }
      }
    },

    responsive: [
      {
        breakpoint: 1366,
        options: {
          chart: {
            height: 200
          }
        }
      },
      {
        breakpoint: 1279,
        options: {
          chart: {
            height: 150,
            width: 150
          }
        }
      }
    ]
  };

  return (
    <>
      <div>
        <ReactApexChart
          options={chartOptions}
          series={chartData.series}
          type="bar"
          height={150}
          // width={100}
        />
      </div>
    </>
  );
}
