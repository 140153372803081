import React, { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import { Button } from '@mui/material';
import TicketCreate from '../components/support/TicketCreate.js';
import TicketList from '../components/support/TicketList.js';
import { useDispatch } from 'react-redux';
import { getAllTickets } from '../api/support.js';
import { toast } from '../lib/toast.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Support(props) {
  const [tickets, setTickets] = useState([]);
  const dispatch = useDispatch();
  const ref = useRef(null);

  const fetchAllTickets = useCallback(async () => {
    try {
      ref.current?.setLoading(true);
      const { success, result, message } = await getAllTickets();
      if (success) {
        ref.current?.setLoading(false);
        setTickets(result);
      } else {
        ref.current?.setLoading(false);
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      ref.current?.setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchAllTickets();
  }, [fetchAllTickets]);

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row pt-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <div className="dashtitle_flex mb-3">
                        <h2 className="dash_inner_title">Create Support Ticket</h2>
                      </div>
                      <TicketCreate fetchAllTickets={fetchAllTickets} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <div className="dashtitle_flex mb-3">
                        <h2 className="dash_inner_title">Ticket Details</h2>
                      </div>
                      <div class="accordion support_accordion" id="supportaccordion">
                        <TicketList ref={ref} tickets={tickets} setTickets={setTickets} fetchAllTickets={fetchAllTickets} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
