import React, { useCallback, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetAccount } from '../redux/slices/account';
import { resetAuth } from '../redux/slices/auth';
import { apiLogout } from '../api/auth';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = useCallback(
    function () {
      dispatch(resetAccount());
      dispatch(resetAuth());
      localStorage.removeItem('transientToken');
      sessionStorage.removeItem('transientToken');
      localStorage.removeItem('sessionId')
      navigate('/login');
    },
    [dispatch, navigate]
  );

  const handleLogout = useCallback(async () => {
    try {
      let sessionId = sessionStorage.getItem('sessionId') || localStorage.getItem('sessionId');
      await apiLogout(sessionId);
      logout();
    } catch (error) {
      logout();
    }
  }, [logout]);
  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return (
    <div className="logout-container">
      <Spinner />
      <p>Signing Out, please hold on...</p>
    </div>
  );
};

export default Logout;
