import React, { useCallback, useEffect, useRef, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Button } from '@mui/material';
import { format } from 'timeago.js';
import { clearNotifications, deleteNotification, getNotificationHistory } from '../api/notification.js';
import { toast } from '../lib/toast.js';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import isEmpty from 'is-empty';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Notifications(props) {
  // const { data, mutate } = useSWR('notification_get', getNotification);
  const [data, setData] = useState({ result: [], count: 0 });
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({ limit: 10, page: 1 });
  const [isContinue, setContinue] = useState(true);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const notifyContainer = useRef(null);

  const handleParamsChange = async () => {
    if (loading) return false;
    else {
      setParams((prev) => ({ ...prev, page: prev.page + 1 }));
      if (data.count > (params.page ) * params.limit) {
        await getNotification(params.page + 1, params.limit);
        setContinue(true);
        return true;
      } else {
        setContinue(false);
        return false;
      }
    }
  };

  const getNotification = useCallback(async (page = 1, limit = 10) => {
    try {
      setLoading(true);
      let { success, result, count } = await getNotificationHistory({ page, limit });
      if (success) {
        setLoading(false);
        setData((prev) => ({ count: count, result: [...prev.result, ...result] }));
      } else {
        setLoading(false);
        setData({ count: 0, result: [] });
      }
    } catch (error) {
      setLoading(false);
      setData({ count: 0, result: [] });
    }
  }, []);

  const handleDelete = async (id) => {
    try {
      const { success, message } = await deleteNotification(id);
      if (success) {
        setData((prev) => ({ count: prev.count - 1, result: prev.result.filter((item) => item._id !== id) }));
      } else {
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Something went wrong', type: 'error' }, dispatch);
      console.log('UPDATE_COMPANY_DETAILS_ERROR', error);
    }
  };

  useEffect(() => {
    clearNotifications(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getNotification();
  }, [getNotification]);

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar count={data?.count} />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row pt-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <div className="dashtitle_flex mb-3">
                        <h2 className="dash_inner_title">System Notifications</h2>
                      </div>
                      {isEmpty(data?.result) ? (
                        <div className="col-md-12">No Notifications</div>
                      ) : (
                        <InfiniteScroll
                          dataLength={data.result.length}
                          loader={<Spinner size="sm" />}
                          next={handleParamsChange}
                          endMessage={<div className="col-md-12 d-flex justify-content-center align-items-center py-4"></div>}
                          hasMore={isContinue}
                          className="notification_panel"
                        >
                          {data.result.map((item) => (
                            <div className="notification_div" id={item._id}>
                              <div class="recent_activity_div">
                                <div class="activity_dot"></div>
                                <div class="activity_content">
                                  <h2>{item.title}</h2>
                                  <h5>{item.description}</h5>
                                  <p>{format(item.createdAt)}</p>
                                </div>
                              </div>
                              <Button onClick={() => handleDelete(item._id)} className="notify_delete">
                                <img src={require('../assets/images/trash_icon.png')} alt="Delete" />
                              </Button>
                            </div>
                          ))}
                        </InfiniteScroll>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
