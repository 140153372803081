import isEmpty from "is-empty";

export default function formatStars(item) {
  if (isEmpty(item)) {
    return '**.**'
  }
  let str = String(item);

  let spliter = str.split('.');

  if (spliter.length === 0) {
    return '**.**';
  }

  if (spliter.length === 1) {
    let result = '';
    for (let i = 0; i < spliter[0].length; i++) {
      if ((i + 1) % 3 === 0 && i + 1 !== spliter[0].length) {
        result += '*,';
      } else {
        result += '*';
      }
    }

    return result + '.**';
  }

  let result = '';
  for (let i = 0; i < spliter[0].length; i++) {
    if ((i + 1) % 3 === 0 && i + 1 !== spliter[0].length) {
      result += '*,';
    } else {
      result += '*';
    }
  }

  return result + '.**';
}
