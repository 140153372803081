import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import { Button } from '@mui/material';
import NavbarAuth from '../components/NavbarAuth';
import { toast } from '../lib/toast';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../api/user';
import { Spinner } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ResetPassword() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const passwordRef = useRef('');
  const confirmPasswordRef = useRef('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const otpAuth = queryParams.get('token');

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let data = {
        password: passwordRef.current.value,
        confirmPassword: confirmPasswordRef.current.value,
        authToken: decodeURIComponent(otpAuth)
      };
      console.log('DATA', data);
      const { success, message, errors } = await resetPassword(data);
      if (success) {
        toast({ message, type: 'success' }, dispatch);
        setLoading(false);
        navigate(`/login`);
      } else {
        message && toast({ message, type: 'error' }, dispatch);
        errors && setErrors(errors);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  };
  return (
    <>
      <ScrollToTopOnMount />
      <NavbarAuth />
      <div className="auth_wrapper">
        <div className="container">
          <div className="auth_main">
            <div className="auth_left">
              <form className="auth_form">
                <div className="mb-5">
                  <h2>Password Reset</h2>
                </div>
                <div className="form-group pass-container">
                  <label>Enter a new Password</label>
                  <input
                    ref={passwordRef}
                    className="form-control"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter Password"
                  />
                  <i
                    className={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'} pass-visibler blue_text`}
                    onClick={() => togglePasswordVisibility()}
                  />
                  
                  <p className="text-danger">{errors?.password || ''}</p>
                </div>
                <div className="input-group-append my-auto pass-container">
                  <label>Repeat the Password</label>
                  <input
                    ref={confirmPasswordRef}
                    className="form-control"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Enter Password"
                  />
                  <i
                    className={`bi ${showConfirmPassword ? 'bi-eye-slash' : 'bi-eye'} pass-visibler blue_text`}
                    onClick={() => toggleConfirmPasswordVisibility()}
                  />
                  <p className="text-danger">{errors?.confirmPassword || ''}</p>
                </div>
                <div className="text-center mt-3">
                  <Button onClick={handleSubmit} variant="contained">
                    {loading ? <Spinner size="sm" /> : ' Reset Password'}
                  </Button>
                </div>
                <p className="register_here">
                  Remember your password? <Link to="/login">Sign In</Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
