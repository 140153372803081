import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export default function RiskChart(props) {
  const chartData = {
    series: [22],
    labels: ['Revenue']
  };

  const chartOptions = {
    chart: {
      type: 'radialBar'
    },
    labels: chartData.labels,

    stroke: {
      lineCap: 'round'
    },
    legend: {
      show: false,
      labels: {
        colors: '#fff',
        useSeriesColors: false
      }
    },
    fill: {
      colors: '#65FF99'      
    },
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: false
    },   
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 0,
          size: '75%',
          background: 'transparent',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24
          }
        },
        track: {
          background: '#1E232E',
          strokeWidth: '100%',
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35
          }
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 25,
            show: true,
            color: '#fff',
            fontFamily: 'Outfit',
            fontSize: '18px',
            fontWeight: 500
          },
          value: {
            formatter: () => '22%',
            fontSize: '32px',
            fontFamily: 'Outfit',
            fontWeight: 700,
            color: '#65FF99',
            show: true,
            offsetY: -20
          }
        }
      }
    },
    labels: ['Low Risk'],
    // responsive: [
    //   {
    //     breakpoint: 1366,
    //     options: {
    //       chart: {
    //         width: 180
    //       }
    //     }
    //   },
    //   {
    //     breakpoint: 1279,
    //     options: {
    //       chart: {
    //         width: 200
    //       }
    //     }
    //   }
    // ]
  };

  return (
    <>
      <div>
        <ReactApexChart options={chartOptions} series={chartData.series} type="radialBar" width={300} />
      </div>
    </>
  );
}
