import axios, { handleResponse } from './Axios';

export const createAircraft = async (data) => {
  try {
    let response = await axios({
      url: '/v1/user/aircraft',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getAircrafts = async (params) => {
  try {
    let response = await axios({
      url: '/v1/user/aircraft',
      method: 'GET',
      params
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getSingleAircraft = async (id) => {
  try {
    let response = await axios({
      url: `/v1/user/aircraft/${id}`,
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const deleteAircraft = async (data) => {
  try {
    let response = await axios({
      url: '/v1/user/aircraft',
      method: 'DELETE',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};

export const getAircraftDetail = async (id) => {
  try {
    let response = await axios({
      url: `/v1/user/aircraft-detail/${id}`,
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
};
