import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import $ from 'jquery';
import { getNotificationHistory } from '../api/notification';
import { useSelector, useDispatch } from 'react-redux';
import useSWR from 'swr';
import { isNumber } from '../lib/common';
import { signal } from '@preact/signals';
import { notificationCount } from '../lib/signal';
import config from '../config';
import isEmpty from 'is-empty';

export default function Navbar(props) {
  const account = useSelector((state) => state.account);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $('.app_header');
    $(window).on('scroll load', function (e) {
      var e = $(window).scrollTop();
      30 <= e ? t.addClass('navbar-sticky--moved-up') : t.removeClass('navbar-sticky--moved-up');
      30 <= e ? t.addClass('navbar-sticky--transitioned') : t.removeClass('navbar-sticky--transitioned');
      30 <= e ? t.addClass('navbar-sticky--on') : t.removeClass('navbar-sticky--on');
    });

    // Sidebar Add Class
    $(document).ready(function () {
      $('.topnavbar_right i').click(function () {
        $('.app_sidebar').addClass('show');
        $('body').addClass('menu_overlay');
      });
    });
  }

  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Enter') {
      navigate(`/transactions?search=${search}`);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-dark app_header">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand d-block d-xl-none">
            <img src={logo} alt="Logo" className="img-fluid brand_logo" />
          </Link>
          
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <div className="input-group search_input_div search_input_div_chg d-none d-lg-flex">
                <span className="input-group-text" id="basic-addon1" onClick={() => navigate(`/transactions?search=${search}`)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                    <path
                      d="M10.564 15.8877C11.9502 15.8877 13.2285 15.4395 14.2744 14.6924L18.209 18.627C18.3916 18.8096 18.6323 18.9009 18.8896 18.9009C19.4292 18.9009 19.811 18.4858 19.811 17.9546C19.811 17.7056 19.728 17.4648 19.5454 17.2905L15.6357 13.3726C16.4575 12.2935 16.9473 10.957 16.9473 9.50439C16.9473 5.99316 14.0752 3.12109 10.564 3.12109C7.06104 3.12109 4.18066 5.98486 4.18066 9.50439C4.18066 13.0156 7.05273 15.8877 10.564 15.8877ZM10.564 14.5098C7.82471 14.5098 5.55859 12.2437 5.55859 9.50439C5.55859 6.76514 7.82471 4.49902 10.564 4.49902C13.3032 4.49902 15.5693 6.76514 15.5693 9.50439C15.5693 12.2437 13.3032 14.5098 10.564 14.5098Z"
                      fill="#3C3C43"
                      fillOpacity="0.6"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={search}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <ul className="navbar-nav justify-content-end flex-grow-1">
                <li className="nav-item d-none d-xl-block">
                  <Link className="nav-link notification_link" to="/notifications">
                    {/* <span style={{ padding: '10px' }}>{isNumber(notificationCount.value) ? notificationCount.value : 0}</span> */}
                    {notificationCount.value > 0 && (
                      <span
                        style={{
                          padding: '5px',
                          borderRadius: '50%',
                          backgroundColor: 'red',
                          color: 'white',
                          display: 'inline-block',
                          width: '20px',
                          height: '20px',
                          textAlign: 'center'
                        }}
                      >
                        {/* {isNumber(notificationCount.value) ? Math.round(notificationCount.value) : 0} */}
                      </span>
                    )}

                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                      <path
                        d="M21 9.33331C21 7.4768 20.2625 5.69632 18.9497 4.38357C17.637 3.07081 15.8565 2.33331 14 2.33331C12.1435 2.33331 10.363 3.07081 9.05025 4.38357C7.7375 5.69632 7 7.4768 7 9.33331C7 17.5 3.5 19.8333 3.5 19.8333H24.5C24.5 19.8333 21 17.5 21 9.33331Z"
                        stroke="#808D9E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.0181 24.5C15.813 24.8536 15.5186 25.1471 15.1644 25.3511C14.8102 25.5551 14.4086 25.6625 13.9998 25.6625C13.591 25.6625 13.1894 25.5551 12.8352 25.3511C12.481 25.1471 12.1866 24.8536 11.9814 24.5"
                        stroke="#808D9E"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </li>
                <li className="nav-item dropdown usericon_dropdown d-none d-xl-block">
                  <a href="#" className="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                    <img
                      // src={require('../assets/images/user_img.png')}
                      src={
                        !isEmpty(account?.profileImage)
                          ? `${config.API_URL}/${account.profileImage}`
                          : require('../assets/images/user_placeholder.png')
                      }
                      alt="User"
                      width="48"
                      height="48"
                      className="rounded-circle"
                    />
                  </a>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <li>
                      <Link className="dropdown-item" to="/profile">
                        Account Details
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/terms">
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/legal">
                        Legal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/faq">
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="#">
                        Audit Report
                      </Link>
                    </li>
                  </ul>
                </li>
                {auth.isAuth && (
                  <li className="nav-item d-block d-xl-none">
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link className="nav-link" to="/dashboard">
                        Dashboard
                      </Link>
                    </div>
                  </li>
                )}
                {auth.isAuth && (
                  <li className="nav-item d-block d-xl-none">
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link className="nav-link" to="/transactions">
                        Transactions
                      </Link>
                    </div>
                  </li>
                )}
                {auth.isAuth && (
                  <li className="nav-item d-block d-xl-none">
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link className="nav-link" to="/wallet">
                        Wallet
                      </Link>
                    </div>
                  </li>
                )}
                {auth.isAuth && (
                  <li className="nav-item d-block d-xl-none">
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link className="nav-link" to="/profile">
                        Profile
                      </Link>
                    </div>
                  </li>
                )}
                {auth.isAuth && (
                  <li className="nav-item d-block d-xl-none">
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link className="nav-link" to="/aircraft-sales">
                        Aircraft Sales
                      </Link>
                    </div>
                  </li>
                )}
                {auth.isAuth && (
                  <li className="nav-item d-block d-xl-none">
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link className="nav-link" to="/support">
                        Support
                      </Link>
                    </div>
                  </li>
                )}
                <li className="nav-item d-block d-xl-none">
                  <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link className="nav-link" to="/terms">
                      Terms & Conditions
                    </Link>
                  </div>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link className="nav-link" to="/legal">
                      Legal
                    </Link>
                  </div>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link className="nav-link" to="/faq">
                      FAQs
                    </Link>
                  </div>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <Link className="nav-link" to="#">
                      Audit Report
                    </Link>
                  </div>
                </li>
                {auth.isAuth && (
                  <li className="nav-item d-block d-xl-none">
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link className="nav-link" to="/logout">
                        Logout
                      </Link>
                    </div>
                  </li>
                )}
                {!auth.isAuth && (
                  <li className="nav-item d-block d-xl-none">
                    <div data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link className="nav-link" to="/login">
                        Login
                      </Link>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            
          </div>
          <div className="input-group search_input_div search_input_div_chg d-flex d-lg-none">
                <span className="input-group-text" id="basic-addon1" onClick={() => navigate(`/transactions?search=${search}`)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
                    <path
                      d="M10.564 15.8877C11.9502 15.8877 13.2285 15.4395 14.2744 14.6924L18.209 18.627C18.3916 18.8096 18.6323 18.9009 18.8896 18.9009C19.4292 18.9009 19.811 18.4858 19.811 17.9546C19.811 17.7056 19.728 17.4648 19.5454 17.2905L15.6357 13.3726C16.4575 12.2935 16.9473 10.957 16.9473 9.50439C16.9473 5.99316 14.0752 3.12109 10.564 3.12109C7.06104 3.12109 4.18066 5.98486 4.18066 9.50439C4.18066 13.0156 7.05273 15.8877 10.564 15.8877ZM10.564 14.5098C7.82471 14.5098 5.55859 12.2437 5.55859 9.50439C5.55859 6.76514 7.82471 4.49902 10.564 4.49902C13.3032 4.49902 15.5693 6.76514 15.5693 9.50439C15.5693 12.2437 13.3032 14.5098 10.564 14.5098Z"
                      fill="#3C3C43"
                      fillOpacity="0.6"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={search}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
        </div>
      </nav>
    </>
  );
}
