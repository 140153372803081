import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';

const RouteCondition = ({ type, Component }) => {
  const auth = useSelector((state) => state.auth);
  const tokenExistence = sessionStorage.getItem('transientToken') || localStorage.getItem('transientToken');

  let isAuth = !!(auth && auth.isAuth && tokenExistence);

  if (type === 'public') {
    return <Component />
  } else if (type === 'private' && isAuth) {
    return <Component />;
  } else if (type === 'auth' && !isAuth) {
    return <Component />;
  } else if (type === 'private' && !isAuth) {
    return <Navigate to={'/login'} />;
  } else if (type === 'auth' && isAuth) {
    return <Navigate to={'/dashboard'} />;
  } else {
    alert('Test')
    return <Navigate to={'/login'} />;
  }
};

export default RouteCondition;
