import React, { useCallback, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import findPercent from '../../lib/findPercent';
import RequestPayment from './RequestPayment';
import IntentPopup from '../Payment/IntentPopup';
import isEmpty from 'is-empty';
import TransactionHistory from './TransactionHistory';

export default function TotalEscrowChart({ aircraft }) {
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowPayment = () => setShowPayment(true);
  const handleClosePayment = () => setShowPayment(false);

  const chartData = {
    // series: [50, 22, 20, 8],
    // labels: ['Total Price', 'Total Received', 'Total Withdrawn', 'Total Remaining']
    series: [findPercent(aircraft.received, aircraft.price), findPercent(aircraft.remaining, aircraft.price)],
    labels: ['Total Received', 'Total Remaining']
  };

  const chartOptions = useMemo(
    () => ({
      chart: {
        type: 'donut'
      },
      labels: chartData.labels,
      stroke: {
        width: '0'
      },
      legend: {
        show: false,
        labels: {
          colors: '#fff',
          useSeriesColors: false
        }
      },
      fill: {
        colors: ['#83FAE3', '#FFE484', , '#2B313D']
      },
      tooltip: {
        enabled: false
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: '24px',
                fontFamily: 'Outfit',
                fontWeight: 700,
                color: '#ffffff',
                offsetY: 5
              },
              total: {
                show: true,
                fontSize: '18px',
                fontFamily: 'Outfit',
                fontWeight: 300,
                color: '#ffffff',
                showAlways: true,
                label: 'Total Escrow',
                formatter: () => `$ ${aircraft?.price?.toLocaleString('en-US')}`
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1199,
          options: {
            chart: {
              width: 320
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    value: {
                      fontSize: '18px',
                      offsetY: -3
                    },
                    total: {
                      fontSize: '16px'
                    }
                  }
                }
              }
            }
          }
        },
        {
          breakpoint: 1199,
          options: {
            chart: {
              width: 300
            }
          }
        }
      ]
    }),
    [aircraft, chartData.labels]
  );

  return (
    <>
      <div className="traffic_chart_flex">
        <ReactApexChart options={chartOptions} series={chartData.series} type="donut" width={400} />
        <div className="asset_chart_info escrow_chart_info">
          <ul>
            <li>
              <label>Total Price</label>
              <p>
                <span>${aircraft?.price?.toLocaleString('en-US') || '000,000'}</span>
              </p>
            </li>
            <li>
              <label>Total Received</label>
              <p>
                <span>${aircraft?.received?.toLocaleString('en-US') || '000,000'}</span>
              </p>
            </li>
            <li>
              <label>Total Withdrawn</label>
              <p>
                <span>${aircraft?.withdrawn?.toLocaleString('en-US') || '000,000'}</span>
              </p>
            </li>
            <li>
              <label>Total Remaining</label>
              <p>
                <span>${aircraft?.remaining?.toLocaleString('en-US') || '000,000'}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="asset_btn_grp mt-4">
        <Button className="border_btn" onClick={handleShowPayment}>
          Request Payment
        </Button>
        <Button className="border_btn" onClick={handleShow}>
          Transactions History
        </Button>
      </div>

      {/* Request Payment Modal */}
      {aircraft && <RequestPayment show={showPayment} onHide={handleClosePayment} aircraft={aircraft} />}

      <TransactionHistory handleClose={handleClose} showTransactionHistory={show} aircraftId={aircraft._id} />
      {/* Withdraw Modal */}
      {/* <Modal show={show} size="lg" onHide={handleClose} centered backdrop="static" className="primary_modal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="align-items-center">
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formCoin">
                  <Form.Select defaultValue="Select Coin" className="primary_select">
                    <option>Select Coin</option>
                    <option>INR</option>
                    <option>USD</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formGridAmount" className="mb-3">
                  <Form.Control type="number" placeholder="Amount" className="primary_input" />
                </Form.Group>
                <Form.Group controlId="formGridReceive" className="mb-3">
                  <Form.Control type="number" placeholder="You Receive" className="primary_input" />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formBank">
                  <Form.Select defaultValue="Bank Account" className="primary_select">
                    <option>Bank Account</option>
                    <option>Canara Bank</option>
                    <option>ICICI Bank</option>
                    <option>HDFC Bank</option>
                  </Form.Select>
                </Form.Group>
                <div className="selected_bankdetails">
                  <h5>
                    Bank Account Number <span>0123654789542</span>
                  </h5>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary_btn" variant="contained" onClick={handleClose}>
            Withdraw
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}
