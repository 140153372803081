import SmallCard from "./SmallCard"


const cardContents = [
    {
        icon: require("../../../assets/lander/icon-1.png"),
        title: "Sign-Up",
        text: "Get started in minutes"
    },
    {
        icon: require("../../../assets/lander/icon-2.png"),
        title: "Integration",
        text: "Easy crypto payments"
    },
    {
        icon: require("../../../assets/lander/icon-3.png"),
        title: "Verification",
        text: "KYC, KYT, & AML checks, on us"
    },
    {
        icon: require("../../../assets/lander/icon-4.png"),
        title: "Receive Payments",
        text: "Accept crypto payments worldwide"
    },
    {
        icon: require("../../../assets/lander/icon-5.png"),
        title: "Settlement",
        text: "Instant crypto to fiat settlements"
    }
]

export default function SmallCards() {

    return (
        <div className="home-works-group">
            {cardContents.map((item, index) => <SmallCard key={index} content={item} />)}
        </div>
    )
}