import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Navbar from '../components/Navbar.js';
import Sidebar from '../components/Sidebar.js';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../lib/toast.js';
import { getAllFaqs } from '../api/cms.js';
import isEmpty from 'is-empty';
import NavbarAuth from '../components/NavbarAuth.js';

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function FAQ(props) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFaqs = useCallback(async () => {
    try {
      const { success, message, result } = await getAllFaqs();
      if (success) {
        setFaqs(result);
      } else {
        toast({ message, type: 'error' }, dispatch);
      }
    } catch (error) {
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchFaqs();
  }, [fetchFaqs]);

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          {auth.isAuth ? <Navbar /> : <NavbarAuth />}
          <div className={auth.isAuth ? 'page_wrapper' : 'page_wrapper_nonauth'}>
            {auth.isAuth && <Sidebar />}
            <div className="page_content dashboard_page">
              <div className="container-fluid">
                <div className="row pt-3">
                  <div className="col-lg-12">
                    <div className="dashbox dashbox_dark">
                      <ul class="nav nav-pills nav-fill cms_nav">
                        <li class="nav-item">
                          <Link class="nav-link" to="/legal">
                            Legal
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link class="nav-link" to="/terms">
                            Terms & Conditions
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link class="nav-link" to="/privacy">
                            Privacy Policy
                          </Link>
                        </li>
                        <li class="nav-item">
                          <Link class="nav-link active" to="/faq">
                            FAQs
                          </Link>
                        </li>
                      </ul>
                      <div className="cms_content">
                        {!isEmpty(faqs) ? (
                          <div class="accordion faq_accordion" id="FaqAccordion">
                            <Fragment>
                              {faqs.map((item, index) => {
                                let id = item._id;
                                return (
                                  <div class="accordion-item">
                                    <h2 class="accordion-header" id={`heading${index}`}>
                                      <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#faq_${id}`}
                                        aria-expanded="true"
                                        aria-controls={`faq_${id}`}
                                      >
                                        {item.question}
                                      </button>
                                    </h2>
                                    <div
                                      id={`faq_${id}`}
                                      class={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                      aria-labelledby={`heading${index}`}
                                      data-bs-parent="#FaqAccordion"
                                    >
                                      <div class="accordion-body">
                                        <p>{item.answer}</p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Fragment>
                          </div>
                        ) : (
                          <div className="no_faq_wrapper">
                            <div>
                              <h5 className="no_faq_available">No FAQs Available</h5>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
