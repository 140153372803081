import findPercentInAssets from './findPercentInAssets';

export default function findChartData(assets, currency) {
  let btc = findPercentInAssets('BTC', assets, currency, '');
  let eth = findPercentInAssets('ETH', assets, currency, '');
  let trx = findPercentInAssets('ETH', assets, currency, '');
  let usdcERC20 = findPercentInAssets('USDC', assets, currency, 'ERC20');
  let usdtERC20 = findPercentInAssets('USDT', assets, currency, 'ERC20');
  let usdcTRC20 = findPercentInAssets('USDC', assets, currency, 'TRC20');
  let usdtTRC20 = findPercentInAssets('USDT', assets, currency, 'TRC20');

  console.log([btc, eth, trx, usdcERC20, usdtERC20, usdcTRC20, usdtTRC20])
  return [btc, eth, trx, usdcERC20, usdtERC20, usdcTRC20, usdtTRC20];
}
