import { Button } from '@mui/material';
import React, { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import { toast } from '../../lib/toast';
import { useDispatch, useSelector } from 'react-redux';
import { closeTicket, getAllTickets } from '../../api/support';
import isEmpty from 'is-empty';
import { momentFormat } from '../../lib/moment';
import TicketReply from './TicketReply';
import config from '../../config';

const TicketList = forwardRef((props, ref) => {
  const { tickets, setTickets, fetchAllTickets } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [loaders, setLoaders] = useState([]);
  const account = useSelector((state) => state.account);

  const updateReplies = (id, reply) => {
    setTickets((prevTickets) => {
      let updatedTicks = [];
      for (let item of prevTickets) {
        if (item._id === id) {
          item.reply.push(reply);
        }
        updatedTicks.push(item);
      }
      return updatedTicks;
    });
  };

  const handleCloseTicket = async (ticketId) => {
    try {
      setLoaders((prev) => [...prev, ticketId]);
      const { success, message } = await closeTicket(ticketId);
      toast({ message, type: success ? 'success' : 'error' }, dispatch);
      if (success) {
        await fetchAllTickets();
      }
      setLoaders((prev) => prev.filter((item) => item !== ticketId));
    } catch (error) {
      setLoaders((prev) => prev.filter((item) => item !== ticketId));
      toast({ message: 'Some error occurred', type: 'error' }, dispatch);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      loading,
      setLoading
    }),
    [loading]
  );

  return (
    <Fragment>
      {isEmpty(tickets) ? (
        <div className="d-flex justify-content-center align-items-center mb-4">No Tickets Raised</div>
      ) : (
        <Fragment>
          {tickets.map((ticket) => {
            let id = `selector_${ticket.ticketId}`;
            return (
              <div class="accordion-item">
                <h2 class="accordion-header" id={ticket._id}>
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${id}`}
                    aria-expanded="false"
                    aria-controls={id}
                  >
                    <div className="support_head">
                      <p className="supportissue_type">
                        <span>Sub:</span> {ticket.categoryId.category}
                      </p>
                      <div className="supportissue_details">
                        <p className="supportissue_type">
                          <span>Ticket ID:</span> {ticket.ticketId}
                        </p>
                        <p className="supportissue_type">
                          <span>Status:</span>{' '}
                          {ticket.status === 'open' ? (
                            <label className="red_text">Open</label>
                          ) : (
                            <label className="green_text">Closed</label>
                          )}
                        </p>
                      </div>
                    </div>
                  </button>
                </h2>
                <div id={id} class="accordion-collapse collapse" aria-labelledby={ticket._id} data-bs-parent="#supportaccordion">
                  <div class="accordion-body">
                    <div className="chat_wrapper">
                      {ticket.reply.map((reply) => (
                        <div className={`chat_single ${reply.messageBy === 'admin' ? 'chat_single_admin' : ''}`}>
                          {reply.messageBy === 'user' && (
                            <img
                              src={
                                account?.profileImage
                                  ? `${config.API_URL}${account.profileImage}`
                                  : require('../../assets/images/user_placeholder.png')
                              }
                              alt="User"
                            />
                          )}
                          <div className="chat_msg">
                            <p>{reply.message}</p>
                            {!isEmpty(reply.attachment) && (
                              <a href={`${config.API_URL}${reply.attachment}`} target="_blank" rel="noreferrer">
                                {reply.attachment.split('/')[reply.attachment.split('/').length - 1]}
                                {/* <img className="reply_image mb-2" src={`${config.API_URL}${reply.attachment}`} alt="ticket attachment" /> */}
                              </a>
                            )}
                            <br />
                            <span>{momentFormat(reply.createdAt, 'YYYY-MM-DD HH:mm')}</span>
                          </div>
                          {reply.messageBy === 'admin' && <img src={require('../../assets/images/help-desk.png')} alt="User" />}
                        </div>
                      ))}
                    </div>
                    {ticket.status === 'open' && (
                      <TicketReply ticketId={ticket._id} updateReplies={(reply) => updateReplies(ticket._id, reply)} />
                    )}
                    {ticket.status === 'open' && (
                      <div className="text-end">
                        <Button className="primary_btn" variant="contained" onClick={() => handleCloseTicket(ticket._id)}>
                          {loaders.includes(ticket._id) ? <Spinner size="sm" /> : 'Close Ticket'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
});

export default TicketList;
