import React from 'react';
import { BrowserRouter as Router, useRoutes, Navigate } from 'react-router-dom';

import SocketContextWrapper from './context/SocketContext.js';

import Login from './pages/login.js';
import Register from './pages/register.js';
import ForgotPassword from './pages/forgot-password.js';
import OTP from './pages/otp.js';
import ResetPassword from './pages/reset-password.js';
import Dashboard from './pages/dashboard.js';
import Wallet from './pages/wallet.js';
import Transactions from './pages/transactions.js';
import Profile from './pages/profile.js';
import AircraftSales from './pages/aircraft-sales.js';
import AircraftDetails from './pages/aircraft-details.js';
import config from './config/index.js';
import Toast from './components/Toast.js';
import Verify from './pages/verify.js';
import AddAircraft from './pages/add-aircraft.js';
import Support from './pages/support.js';
import Notifications from './pages/notifications.js';
import RouteHelper from './helper/RouteHelper.js';
import RouteCondition from './helper/RouteCondition.js';
import { CookiesProvider } from 'react-cookie';
import Legal from './pages/legal.js';
import Terms from './pages/terms.js';
import FAQ from './pages/faq.js';
import AuditReport from './pages/audit-report.js';
import AuditReportDetail from './pages/audit-report-detail.js';
import Logout from './pages/logout.js';
import PaymentStatus from './pages/PaymentStatus.js';
import QuoteStatus from './pages/QuoteStatus.js';
import Privacy from './pages/privacy.js';
import Index from './pages/index.js';

const App = () => {
  const routes = useRoutes([
    { path: '/', element: <RouteCondition type={'public'} Component={Index} /> },
    { path: '/*', element: <RouteCondition type={'auth'} Component={() => <Navigate to="/login" />} /> },
    { path: '/login', element: <RouteCondition type={'auth'} Component={Login} /> },
    { path: '/register', element: <RouteCondition type={'auth'} Component={Register} /> },
    { path: '/verify/:token', element: <RouteCondition type={'auth'} Component={Verify} /> },
    { path: '/forgot-password', element: <RouteCondition type={'auth'} Component={ForgotPassword} /> },
    { path: '/otp', element: <OTP /> },
    { path: '/reset-password', element: <ResetPassword /> },
    { path: '/logout', element: <RouteCondition type={'private'} Component={Logout} /> },
    { path: '/dashboard', element: <RouteCondition type={'private'} Component={Dashboard} /> },
    { path: '/wallet', element: <RouteCondition type={'private'} Component={Wallet} /> },
    { path: '/transactions', element: <RouteCondition type={'private'} Component={Transactions} /> },
    { path: '/profile', element: <RouteCondition type={'private'} Component={Profile} /> },
    { path: '/aircraft-sales', element: <RouteCondition type={'private'} Component={AircraftSales} /> },
    { path: '/aircraft-details/:aircraftId', element: <RouteCondition type={'private'} Component={AircraftDetails} /> },
    { path: '/add-aircraft', element: <RouteCondition type={'private'} Component={AddAircraft} /> },
    { path: '/update-aircraft/:aircraftId', element: <RouteCondition type={'private'} Component={AddAircraft} /> },
    { path: '/support', element: <RouteCondition type={'private'} Component={Support} /> },
    { path: '/notifications', element: <RouteCondition type={'private'} Component={Notifications} /> },
    { path: '/audit-report', element: <RouteCondition type={'private'} Component={AuditReport} /> },
    { path: '/audit-report-detail', element: <RouteCondition type={'private'} Component={AuditReportDetail} /> },
    { path: '/payment-status/:paymentIntent', element: <RouteCondition type={'public'} Component={PaymentStatus} /> },
    { path: '/quote-status/:quoteIntent', element: <RouteCondition type={'public'} Component={QuoteStatus} /> },
    { path: '/faq', element: <RouteCondition type={'public'} Component={FAQ} /> },
    { path: '/terms', element: <RouteCondition type={'public'} Component={Terms} /> },
    { path: '/legal', element: <RouteCondition type={'public'} Component={Legal} /> },
    { path: '/privacy', element: <RouteCondition type={'public'} Component={Privacy} /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Router basename={config.BASENAME}>
        <SocketContextWrapper>
          <App />
          <Toast />
          <RouteHelper />
        </SocketContextWrapper>
      </Router>
    </CookiesProvider>
  );
};

export default AppWrapper;
