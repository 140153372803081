import isEmpty from 'is-empty';

export default function findConvertedAsset(symbol, assets, currencies, type = '') {
  if (isEmpty(assets) || isEmpty(currencies)) return 0;

  let asset = isEmpty(type)
    ? assets.find((item) => item.currencySymbol === symbol)
    : assets.find((item) => item.currencySymbol === symbol && item.tokenType === type);
  if (isEmpty(asset)) return 0;
  let coresspondingCurrency = currencies.find((item) => item.symbol === asset.currencySymbol);

  let val = parseFloat((asset.balance * coresspondingCurrency.price).toFixed(2))
  return isNaN(val) ? 0 : val ;
}
