import isEmpty from 'is-empty';

export default function findTotalBalance(wallet, currency) {
  let totalBalance = 0;
  try {
    if (!isEmpty(wallet) && !isEmpty(wallet.assets) && !isEmpty(currency)) {
      for (let item of wallet.assets) {
        totalBalance += item.balance * currency.find((curr) => curr.symbol === item.currencySymbol).price;
      }
    }
    return { totalBalance };
  } catch (error) {
    return { totalBalance };
  }
}
