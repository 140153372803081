import { faFacebook, faInstagram, faLinkedin, faTelegram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footer() {

    return (
        <div className="w-screen bg-[#0D0F14] px-5">
            <div className="footer-main">
                <nav className="flex gap-6 md:gap-[71px]">
                    <a href="/terms">Terms & Condition</a>
                    <a href="/legal">Legal</a>
                    <a href="/privacy">Privacy</a>
                </nav>
                <nav className="flex gap-[17px] !text-[#5589A8]">
                    <a href="https://www.instagram.com/flyaeroverse"><FontAwesomeIcon icon={faInstagram} style={{ color: "#5589A8" }} /></a>
                    <a href="https://www.linkedin.com/company/aeroverse-aero"><FontAwesomeIcon icon={faLinkedin} /></a>
                    <a href="https://t.me/flyaeroverse"><FontAwesomeIcon icon={faTelegram} /></a>
                    <a href="https://x.com/flyaeroverse"><FontAwesomeIcon icon={faTwitter} /></a>
                    <a href="https://www.facebook.com/people/flyaeroverse"><FontAwesomeIcon icon={faFacebook} /></a>
                </nav>
            </div>
            <div className="mx-auto text-center pb-[24px]">
                <p className="text-[#858181]">© 2024 AeroVerse</p>
            </div>
        </div>
    )
}