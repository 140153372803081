import AxiosService from 'axios';
import { refreshToken } from './auth';
import { store } from '../redux/store';
import { resetAccount } from '../redux/slices/account';
import { resetAuth } from '../redux/slices/auth';

let isSession = false;
AxiosService.interceptors.request.use(
  (config) => {
    let session = sessionStorage.getItem('transientToken');
    let local = localStorage.getItem('transientToken');
    if (session && !local) {
      isSession = true;
    }
    let transientToken = session || local;
    if (transientToken && !config.url.endsWith('refresh-token')) {
      config.headers.Authorization = transientToken;
    } else {
      config.headers.Authorization = '';
    }
    return config;
  },
  (error) => {
    throw error;
  }
);

let isRefreshing = false;
let refreshQueue = [];

AxiosService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && error.config.url.endsWith('refresh-token')) {
      store.dispatch(resetAccount());
      store.dispatch(resetAuth());
      localStorage.removeItem('transientToken');
      sessionStorage.removeItem('transientToken');
      return;
    } else if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve) => {
            refreshQueue.push((transientToken) => {
              resolve(transientToken);
            });
          });
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return AxiosService(originalRequest);
        } catch (error) {
          throw error;
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const { accessToken: newToken, logout } = await refreshToken();
      if (isSession) {
        sessionStorage.setItem('transientToken', newToken);
      } else {
        localStorage.setItem('transientToken', newToken);
      }
      if (logout) {
        isRefreshing = false;
        refreshQueue = [];
      }

      isRefreshing = false;
      refreshQueue.forEach((cb) => cb(newToken));
      refreshQueue = [];

      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return AxiosService(originalRequest);
    }
    throw error;
  }
);

export default AxiosService;
